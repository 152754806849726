import { useState, useContext } from "react";
import "./OrderDetail.scss";
import moment from "moment";
import {
  CircularProgress,
} from "@mui/material";
import queryString from "query-string";
import { useLocation, Link } from "react-router-dom";
import { AiOutlineDownload } from "react-icons/ai";
import { useTranslate } from "react-polyglot";
import { CustomizedSnackbars } from "../../../components/shareable/Shareable";
import { LoadingButton } from "@mui/lab";
import { MerchantContext } from "../../../context/MerchantContext";
import { openInNewTab, toS3Link } from "../../../utils/shareable-utils";
import RiderLocationMap from "../../setting-module/detail/maps/RiderLocationMap";
import validator from "../../../utils/validator";

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import { kdsDownloadPDFReceipt } from "../../../graphql/queries";
import { useLoadScript } from "@react-google-maps/api"

function OrderDetail({ orderInfo, orderItems, progress, download, rider }) {
  const translate = useTranslate();
  const location = useLocation();
  const [orderID, setOrderID] = useState(
    queryString.parse(location.search).orderID
  );
  const [loadReceipt, setReceiptIsLoading] = useState(false);
  const merchantInfoContext = useContext(MerchantContext);
  const { isLoaded: googleMapsIsLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });

  const roundN = (num, n) => {
    return parseFloat(
      Math.round(num * Math.pow(10, n)) / Math.pow(10, n)
    ).toFixed(n);
  };

  const downloadReceipt = async () => {
    setReceiptIsLoading(true);
    const viewReceipt = window.open();

    try {
      let params = {
        orderId: orderID,
      };

      const respOrderFulfillment = await API.graphql(
        graphqlOperation(kdsDownloadPDFReceipt, params)
      );
      if (respOrderFulfillment.data.kdsDownloadPDFReceipt.status === "true") {
        setReceiptIsLoading(false);
        viewReceipt.location = respOrderFulfillment.data.kdsDownloadPDFReceipt.message;
      } else {
        setReceiptIsLoading(false);
        setSnackbar({
          snackbarMessage:
            respOrderFulfillment.data.kdsDownloadPDFReceipt.message,
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
      }
    } catch (error) {
      console.log(error);
      setReceiptIsLoading(false);
    }
  };
  
  const handleOpenReceipt = (link) => {
    if(!link) {
      setSnackbar({
        snackbarMessage: translate("order-detail-screen.no-receipt-to-view"),
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    } else {
      openInNewTab(toS3Link(link, false));
    }
  };

  const renderColor = (progress) => {
    if (progress === "Order Fulfilled") {
      if (orderInfo.deliveryStatus === "No Rider Found") {
        return {
          backgroundColor: "#E74C3C",
          color: "#FFFFFF",
        };
      } else {
        return {
          backgroundColor: "#7EC480",
          color: "#FFFFFF",
        };
      }
    } else if (progress === "Waiting Refund") {
      return {
        backgroundColor: "#E74C3C",
        color: "#FFFFFF",
      };
    } else if (progress === "Order Completed") {
      if (orderInfo.deliveryStatus === "No Rider Found") {
        return {
          backgroundColor: "#E74C3C",
          color: "#FFFFFF",
        };
      } else {
        return {
          backgroundColor: "#EBFFEB",
          color: "#69AB6B",
        };
      }
    } else if (progress === "Shipping") {
      if (orderInfo.deliveryStatus === "No Rider Found") {
        return {
          backgroundColor: "#E74C3C",
          color: "#FFFFFF",
        };
      } else {
        return {
          backgroundColor: "#0A2540",
          color: "#FFFFFF",
        };
      }
    } else {
      return {
        backgroundColor: "#0A2540",
        color: "#FFFFFF",
      };
    }
  };

  const renderStatus = (text) => {
    if (text === "Pending Fulfilled") {
      return translate("order-listing-screen.pending-fulfilled-status");
    } else if (text === "New Order") {
      return translate("order-listing-screen.pending-fulfilled-status");
    } else if (text === "Pending Payment") {
      return translate("order-listing-screen.pending-fulfilled-status");
    } else if (text === "Order Fulfilled") {
      if (orderInfo.type === "Delivery") {
        if (orderInfo.deliveryStatus === "No Rider Found") {
          return translate("order-listing-screen.no-rider");
        } else {
          return translate("order-listing-screen.order-fulfilled-status");
        }
      } else {
        return translate("order-listing-screen.order-fulfilled-status");
      }
    } else if (text === "Shipping") {
      if (orderInfo.type === "Delivery") {
        if (orderInfo.deliveryStatus === "No Rider Found") {
          return translate("order-listing-screen.no-rider");
        } else if (orderInfo.deliveryStatus === "Requested for Delivery") {
          return translate("order-listing-screen.order-reqrider-status");
        } else {
          return translate("order-listing-screen.order-shipping-status");
        }
      }
    } else if (text === "Ready for Customer Pickup") {
      return translate("order-listing-screen.order-ready-for-customer-pickup-status");
    } else if (text === "Served to Customer") {
      return translate("order-listing-screen.order-served-to-customer-status");
    }else if (text === "Waiting Refund") {
      return translate("order-listing-screen.order-rejected-status");
    } else if (text === "Refunded") {
      return translate("order-listing-screen.order-refunded-status");
    } else if (text === "Order Completed") {
      return translate("order-listing-screen.order-completed-status");
    } else {
      return text;
    }
  };
  
  const getDeliveryStatusTranslation = (deliveryStatus) => {
    switch(deliveryStatus) {
      case "Rider Picked Up":
        return translate("order-listing-screen.rider-picked-up");
      
      case "Rider Assigned":
        return translate("order-listing-screen.rider-assigned");
        
      case "Requested for Delivery":
        return translate("order-listing-screen.requested-delivery");
        
      case "No Rider Found":
        return translate("order-listing-screen.no-rider-found");
        
      case "2nd Delivery Attempt":
        return translate("order-listing-screen.2nd-delivery-attempt");
        
      case "3rd Delivery Attempt":
        return translate("order-listing-screen.3rd-delivery-attempt");
        
      default:
        return deliveryStatus;
    }
  }

  const getOrderTypeTranslation = (type) => {
    switch(type) {
      case "Delivery":
        return translate("order-listing-screen.delivery");
      
      case "PickUp":
        return translate("order-listing-screen.pickup");
        
      case "DineIn":
        return translate("order-listing-screen.dine-in");
        
      default:
        return type;
    }
  };

  return (
    <div className="order-detail-wrapper">
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      <div className="orderdetail-grid-box">
        <div>
          <h1 className="orderhome-orderinfo-large">
            <span className="orderhome-orderinfo-large">
              {orderInfo.orderNumber}{orderInfo.type === "DineIn" 
                  ? ` (${translate("order-listing-screen.table-no")} ${orderInfo.tableNumber})` 
                  : ""
              }
            </span>
          </h1>
          <h1 className="orderhome-orderinfo-text">
            {translate("order-listing-screen.order-type")}:{" "}
            <span className="orderhome-orderinfo-text-2">{getOrderTypeTranslation(orderInfo.type)}</span>
          </h1>
          <h1 className="orderhome-orderinfo-text">
            {translate("order-listing-screen.customer")}:{" "}
            <span className="orderhome-orderinfo-text-2">
              {orderInfo.customerFirstName + " " + orderInfo.customerLastName}
            </span>
          </h1>
          {orderInfo.type === "Delivery" && (
            <>
              <h1 className="orderhome-orderinfo-text">
                {translate("order-detail-screen.subtotal")}:{" "}
                <span className="orderhome-orderinfo-text-2">
                  {merchantInfoContext.currency} {roundN(!!orderInfo.subtotalWithTax ? orderInfo.subtotalWithTax : orderInfo.subtotal, 2)}
                </span>
              </h1>
              <h1 className="orderhome-orderinfo-text">
                {translate("order-detail-screen.delivery-fees")}:{" "}
                <span className="orderhome-orderinfo-text-2">
                  {merchantInfoContext.currency} {roundN(orderInfo.estimatedDeliveryFee, 2)}
                </span>
              </h1>
            </>
          )}
          {orderInfo.scheduledDateTime && (
            <>
              <h1 className="orderhome-orderinfo-text">
              {translate("order-listing-screen.scheduled-datetime")}:{" "}
              <br />
              <span className="orderhome-orderinfo-text-2">
                {moment(orderInfo.scheduledDateTime).format("DD/MM/YY LT")}
              </span>
              </h1>
            </>
          )}
          {orderInfo.manualPaymentMethodName ? (
            <h1 className="orderhome-orderinfo-text" onClick={() => handleOpenReceipt(orderInfo.manualPaymentReceipt)}>
              {translate("order-listing-screen.total")}:{" "}
              <span className="order-detail-page-link">
                {merchantInfoContext.currency} {roundN(orderInfo.grandTotal, 2)}{" "}
                {translate("order-listing-screen.via")}{" "}
                {orderInfo.manualPaymentMethodName === "TnG"
                  ? translate("payment-method-page.tng")
                  : orderInfo.manualPaymentMethodName
                }
              </span>
            </h1>
          ) : orderInfo.paymentType === "PayAtCounter" ? (
              <h1 className="orderhome-orderinfo-text">
                  {translate("order-listing-screen.total")}:{" "}
                  <span className="orderhome-orderinfo-text-2">
                    {merchantInfoContext.currency} {roundN(orderInfo.grandTotal, 2)}
                  </span>
                  {" "}
                  {translate("order-listing-screen.to-pay-at-counter")}
              </h1>
          ) : (
            <h1 className="orderhome-orderinfo-text">
              {translate("order-listing-screen.total")}:{" "}
              <span className="orderhome-orderinfo-text-2">
                {merchantInfoContext.currency} {roundN(orderInfo.grandTotal, 2)}
              </span>
            </h1>
          )}
        </div>
        <div className="push-right">
          <h1
            className="orderhome-orderinfo-pickup-2"
            style={renderColor(orderInfo.status)}
          >
            {renderStatus(orderInfo.status)}
          </h1>
          <h1 className="orderhome-orderinfo-text">
            {translate("order-listing-screen.order-datetime")}:{" "}
            <br />
            <span className="orderhome-orderinfo-text-2">
              {moment(orderInfo.orderDateTime).format("DD/MM/YY LT")}
            </span>
          </h1>
          <h1 className="orderhome-orderinfo-text">
            {translate("order-detail-screen.contact-information")}:{" "}
            <a
              href={"tel:" + orderInfo.customerMobileNo}
              className="orderhome-orderinfo-text-2"
            >
              {orderInfo.customerMobileNo}
            </a>
          </h1>
          {orderInfo.type === "Delivery" && (
            <h1 className="orderhome-orderinfo-text">
              {translate("order-detail-screen.customer-address")}:{" "}
              <span className="orderhome-orderinfo-text-2">
                {orderInfo.deliveryAddress}
              </span>
            </h1>
          )}
        </div>
      </div>

      {download === "true" ? (
        <div className="orderdetail-download-buttonbox">
          <div></div>
          <LoadingButton
            variant="contained"
            className="orderdetail-download-button"
            color="primary"
            onClick={downloadReceipt}
            loading={loadReceipt}
            disabled={loadReceipt}
          >
            {!loadReceipt ? (
              <>
                <AiOutlineDownload className="orderdetail-download-icon"></AiOutlineDownload>
                {translate("order-listing-screen.downloadreceipt")}
              </>
            ) : (
              <CircularProgress size={20} color={"inherit"} />
            )}
          </LoadingButton>
        </div>
      ) : null}

      <h1 className="orderhome-orderinfo-title">
        {translate("order-detail-screen.order-details")}
      </h1>
      <ol className="order-items-box">
        {orderItems
          ? orderItems.map((data) => {
              return (
                <li className="orderdetail-listing-text" key={data.orderId}>
                  <div className="orderhome-grid-box">
                    <div>
                      <h1 className="orderhome-orderinfo-key">
                        {data.itemTitle}
                      </h1>
                      {data.selectedModifierGroups ? (
                        data.selectedModifierGroups.length > 0 ? (
                          <ul className="orderdetail-flex-box">
                            <li className="orderdetail-orderinfo-small">
                              {translate("order-listing-screen.addon")} :{" "}
                            </li>
                            {data.selectedModifierGroups
                              .filter((data) => data.isSelected === true)
                              .map((data) =>
                                data.modifier
                                  .filter((data) => data.isSelected === true)
                                  .map((data) => {
                                    return (
                                      <div>
                                        <li
                                          key={data.modifierName}
                                          className="orderdetail-orderinfo-small"
                                        >
                                          {data.modifierName},
                                        </li>
                                      </div>
                                    );
                                  })
                              )}
                            {data.selectedModifierGroups.map((data) => {
                              if (data.requestRemark === null) {
                                return null;
                              } else {
                                return (
                                  <div>
                                    <li
                                      key={data.modifierName}
                                      className="orderdetail-orderinfo-small"
                                    >
                                      {data.requestRemark}
                                    </li>
                                  </div>
                                );
                              }
                            })}
                          </ul>
                        ) : null
                      ) : null}
                    </div>
                    <div className="push-right orderhome-orderinfo-quantitybox">
                      <h1 className="orderhome-orderinfo-quantity">
                        x{data.orderedQuantity}
                      </h1>
                    </div>
                  </div>
                </li>
              );
            })
          : null}
      </ol>

      {orderInfo.remarks ? (
        <div className="order-remark-box">
          <h1 className="orderhome-orderinfo-text">
            {translate("order-listing-screen.request")}: {orderInfo.remarks}
          </h1>
        </div>
      ) : null}

      {rider === "true" ? (
        orderInfo.driverName ? (
          <>
            <div className="order-remark-box">
              <h1 className="orderhome-orderinfo-text">
                {translate("order-detail-screen.reference-no")}{": "}
                {orderInfo.deliveryNumber}
              </h1>
              <h1 className="orderhome-orderinfo-text">
                {translate("order-listing-screen.ridername")}{": "}
                {orderInfo.driverName}
              </h1>
              <h1 className="orderhome-orderinfo-text">
                {translate("order-listing-screen.ridercontact")}{": "}
                {orderInfo.driverPhoneNumber}
              </h1>
              <h1 className="orderhome-orderinfo-text">
                {translate("order-listing-screen.vehicleno")}{": "}
                {orderInfo.driverPlateNumber}
              </h1>
              <h1 className="orderdetail-orderremark-text">
                {translate("order-detail-screen.delivery-partner-link")}{": "}
              </h1>
              <label
                onClick={() => openInNewTab(orderInfo.standardDeliveryTrackingUrl)}
                className="order-detail-page-link delivery-partner-link"
              >
                {orderInfo.standardDeliveryTrackingUrl}
              </label>
            </div>
            {googleMapsIsLoaded && !validator.isEmptyString(orderInfo.driverLatitude) && !validator.isEmptyString(orderInfo.driverLongitude) &&
              <RiderLocationMap
                markers={[
                  {
                    name: "rider",
                    position: {lat: orderInfo.driverLatitude, lng: orderInfo.driverLongitude}
                  },
                  {
                    name: "destination",
                    position: {lat: parseFloat(orderInfo.deliveryLatitude), lng: parseFloat(orderInfo.deliveryLongitude)}
                  },
                ]}
              />
            }
          </>
        ) : null
      ) : null}
      
      {!!orderInfo?.deliveryStatusTimeline && orderInfo.deliveryStatusTimeline.length > 0 && (
        <div className="delivery-status-timeline-section">
          <label className="orderhome-orderinfo-title">{translate("order-detail-screen.delivery-status")}</label>
          <div className="timeline-box">
            {
              orderInfo.deliveryStatusTimeline.map((o) => 
                <div className="each-timeline">
                  <div className="status-section">
                    <label className="status-label">{getDeliveryStatusTranslation(o.status)}</label>
                    {o.status === "Rider Assigned" &&
                      <label className="delivery-info-label">{o.deliveryPartner} (Ref. {o.deliveryNumber})</label>
                    }
                  </div>
                  <label>{moment(o.createdAt).format("hh:mma")}</label>
                </div>
              )
            }
          </div>
        </div>
      )}

      <h1 className="orderhome-orderinfo-text-2">
        {translate("order-listing-screen.plsrefresh")}
      </h1>
    </div>
  );
}

export default OrderDetail;
