import { useState, useContext } from 'react';
import {useHistory,Link} from 'react-router-dom';
import Header from '../../components/header/Header';
import {VscError} from 'react-icons/vsc';
import { useTranslate } from "react-polyglot";
import moment from 'moment';

function FailedPayment(props) {
    const history = useHistory();
    const translate = useTranslate();

    return (
        <div className="top-up-page-wrapper">
            <Header/>
            <div>
                  <div className='center-screen'>
                      <div>
                          <h1 style={{textTransform:"none"}} className='ordercomplete-title'>{translate("onboarding-screen.sorrypaymentfailed")}</h1> 
                          <div className='ordercomplete-icon-holder'>
                              <VscError style={{color:"#E74C3C",fontSize:"7rem"}}></VscError>
                          </div>
                         
                          <div className='orderview-button-bottom'>
                              <div className='ordercomplete-button-holder'>
                                  <Link style={{textDecoration:"none"}} to="/kds/package-offer">
                                      <div className='orderview-single-button-box'>
                                          <button className='orderview-confirm-noload-button'>{translate("onboarding-screen.gobacktooffer")}</button>
                                      </div>
                                  </Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
        </div>
    );
}

export default FailedPayment;