import { Box, TextField, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useState, Fragment } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { DeleteOutlined } from "@mui/icons-material";

import "./CategoriesWithAutoComplete.scss";

const CategoriesWithAutoComplete = ({
  categories,
  setCategories,
  txtCategories,
  setTxtCategories,
  description,
  disabled = false,
  handleOnChange,
  setOpenDeleteModal = () => {},
  setCategoryToDelete = () => {},
  setExistingCategoryList = () => {},
  existingCategoryList = null,
  fetchCategoriesIsLoading,
}) => {
  const [open, setOpen] = useState(false);;
  
  // function to trim input categories and perform case insensitive check for duplicate in selected categories and options
  const checkDuplicate = (arr) => {
    let returnArr = [];
    let splitArr = arr.split(",").map((o) => o.trim());
    
    for(let input of splitArr) {
      if(input) {
        // check if there is duplicate in selected categories first
        let categoriesIndex = categories.findIndex((o) => o.localeCompare(input, undefined, { sensitivity: 'accent' }) === 0);
        if(categoriesIndex !== -1) {
          continue;
        } else {
          // check if there is duplicate in options
          let optionsIndex = existingCategoryList.findIndex((o) => o.localeCompare(input, undefined, { sensitivity: 'accent' }) === 0);
          if(optionsIndex !== -1) {
            // if option already exists, select option
            returnArr.push(existingCategoryList[optionsIndex]);
          } else {
            // add the new category if option does not exist
            returnArr.push(input);
          }
        }
      }
    }
    
    return returnArr;
  };
  
  const handleClickExistingCategories = (e, arrayOfCategories) => {
    if (e.keyCode === 13) {
      // Enter button
      let categoryList = [
        ...categories, ...checkDuplicate(txtCategories)
      ];
      setCategories(categoryList);
      setTxtCategories("");
    } else {
      setCategories(arrayOfCategories);
    }
  };

  // on blur to set category function
  const handleOnblurToSetCategories = (event) => {
    let categoryList = [
      ...categories, ...checkDuplicate(event)
    ];
    setCategories(categoryList);
    setTxtCategories("");
  };

  const [blurOnSelect, setBlurOnSelect] = useState(false);

  return (
    <>
      <Autocomplete
        ChipProps={{ className: "category-chip" }}
        className="categories-autocomplete"
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        freeSolo={true}
        multiple
        value={categories}
        autoComplete={blurOnSelect}
        blurOnSelect={blurOnSelect}
        disabled={disabled}
        id="highlights-demo"
        filterSelectedOptions
        options={existingCategoryList}
        renderOption={(option) => {
          return (
            <div className="pop-up-menu-option-container">
              <div 
                onMouseDown={() => setBlurOnSelect(false)} 
                className="category-option"
              >
                {option}
              </div>
              <Button
                onMouseDown={() => setBlurOnSelect(true)}
                onClick={() => {
                  setOpenDeleteModal(true);
                  setCategoryToDelete(option);
                }}
              >
                <DeleteOutlined color='error' />
              </Button>
            </div>
          );
        }}
        onChange={(event, newValue) => {
          handleClickExistingCategories(event, newValue);
          handleOnChange(event);
        }}
        onBlur={(event) => handleOnblurToSetCategories(event.target.value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            onChange={handleOnChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {fetchCategoriesIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
        loading={fetchCategoriesIsLoading}
      />
      <Box className="autocomplete-categories-description">
        {description}
      </Box>
    </>
  );
};

export { CategoriesWithAutoComplete };
