import {Link} from 'react-router-dom';
import {IoArrowBackSharp} from 'react-icons/io5';
import { CustomLoadingButton } from "../../../components/buttons/Buttons";
import {
    CircularProgress
  } from '@mui/material';

import './Layout.scss'

function TopLayout(props) {

    const { 
        hasBackButton = false, 
        redirectTo, 
        samePageRedirect = false,
        handleRedirect,
        children 
    } = props
    
    return (
        <>
        <div className='body body-bg new-body-padding-with-footer'>
            {hasBackButton && !samePageRedirect && (
                <Link to={redirectTo}>
                    <IoArrowBackSharp className='backbutton-styling'></IoArrowBackSharp>
                </Link>
            )}
            {hasBackButton && samePageRedirect && (
                <IoArrowBackSharp className='backbutton-styling' onClick={handleRedirect}></IoArrowBackSharp>
            )}
            {children}
        </div>
        </>
    )

}


function BottomLayout(props) {
    
    const {
        handleRedirect,
        title,
        isLoading = false 
    } = props

    return (
        <div className='body'>
            <div className='layout-button-bottom'>
                <div className='layout-button-bottom-section'>
                        <div className='layout-button-box'>
                            <CustomLoadingButton
                              className="confirm-button"
                              onClick={handleRedirect}
                              loading={isLoading}
                            >
                                {!isLoading ? (
                                 title
                                ) : (
                                  <CircularProgress size={20} color={"inherit"} />
                                )}
                            </CustomLoadingButton>
                        </div>
                </div>
            </div>
        </div>
    )
}
export {
    TopLayout,
    BottomLayout
};