import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";

import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import { IoArrowBackSharp } from "react-icons/io5";

import {
  CustomIconButton,
  OutlinedButton,
} from "../../../components/buttons/Buttons";
import { CustomizedSnackbars } from "../../../components/shareable/Shareable";
import { MerchantContext } from "../../../context/MerchantContext";

import { CheckCircle, ContentCopy } from "@mui/icons-material";
import { useTranslate } from "react-polyglot";
import { useQuery } from "../../../hooks/useQuery";
import {
  copyToClipboard,
  downloadFile,
  openInNewTab,
  toS3Link,
} from "../../../utils/shareable-utils";
import useCheckMultistoreUser from "../compnents/useCheckMultistoreUser";
import "./StoreInformationPage.scss";

//api
import { useLoadScript } from "@react-google-maps/api";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { kdsUpdateStoreInformation } from "../../../graphql/mutations";
import {
  adminGetStore,
  kdsGetStoreInformation,
} from "../../../graphql/queries";
import CustomMapWithInput from "./maps/CustomMapWithInput";

import { Form, Formik } from "formik";
import { validateStoreInfo } from "../../../validation/validateStoreInfo";

function StoreInformationPage(props) {
  const history = useHistory();
  const translate = useTranslate();
  const merchantInfoContext = useContext(MerchantContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const { isLoaded: googleMapsIsLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });
  let query = useQuery();
  const { isMultistore } = useCheckMultistoreUser(false);
  const { storeId } = useContext(MerchantContext);

  // store info to modify obj
  const [storeInfo, setStoreInfo] = useState({});

  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });

  const fetchKDSStoreData = async () => {
    try {
      let { data: storeInfoResp } = await API.graphql(
        graphqlOperation(kdsGetStoreInformation)
      );
      setStoreInfo(storeInfoResp.kdsGetStoreInformation);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetchAdminStoreData = async () => {
    try {
      let { data: storeInfoResp } = await API.graphql(
        graphqlOperation(adminGetStore, {
          storeId: storeId,
        })
      );
      setStoreInfo(storeInfoResp.adminGetStore);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const downloadQrCode = async (event) => {
    event.preventDefault();
    const result = await Storage.get(storeInfo.webQrCode, {
      download: true,
    });
    const blob = result.Body;
    downloadFile(blob, storeInfo.name + "_QRCode.jpg");
  };

  const handleOnSubmit = async (val) => {
    setIsSaving(true);
    //const imageId = uuid();
    //let uploadedImageParams = "";
    //const uploadedImageUrl = `${merchantInfoContext.merchantId}/AboutUs/Banner`;

    //if (typeof val.aboutUsBanner === "object") {
    //  uploadedImageParams = `${uploadedImageUrl}/${imageId}.jpg`;
    //} else {
    //  uploadedImageParams = val.aboutUsBanner;
    //}

    try {
      let storeInfoParams = {
        ...val,
        isOnDemandDeliveryEnabled: true,
        onDemandMaxDistance: 8,
        //aboutUsBanner: uploadedImageParams,
      };
      //if (typeof val.aboutUsBanner === "object") {
      //  await Storage.put(
      //    `${storeInfoParams.aboutUsBanner}`,
      //    val.aboutUsBanner
      //  );
      //}

      const storeInfoResp = await API.graphql(
        graphqlOperation(kdsUpdateStoreInformation, storeInfoParams)
      );

      if (storeInfoResp.data.kdsUpdateStoreInformation.status === "true") {
        setSnackbar({
          snackbarMessage: storeInfoResp.data.kdsUpdateStoreInformation.message,
          snackbarOpen: true,
          snackbarSeverity: "success",
        });
        if (!isSetting) {
          //click Next
          history.push("/kds/settings/paymentmethod");
        }
      } else {
        setSnackbar({
          snackbarMessage: storeInfoResp.data.kdsUpdateStoreInformation.message,
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
      }
      setIsSaving(false);
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    }
  };

  const getErrorTranslation = (error) => {
    switch (error) {
      case "Store image is required.":
        return translate("store-information-page.store-image-required");

      case "Store name is required.":
        return translate("store-information-page.store-name-required");

      case "SMS notification phone number is required.":
        return translate("store-information-page.sms-no-required");

      case "SMS notification phone number is invalid.":
        return translate("store-information-page.sms-no-invalid");

      default:
        return error;
    }
  };

  useEffect(() => {
    if (history.location.state && history.location.state.previous) {
      setIsSetting(true);
    }
  }, [history.location.state]);

  useEffect(() => {
    isMultistore ? fetchAdminStoreData() : fetchKDSStoreData();
  }, []);

  return (
    <>
      <Header page={"setting"} />
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      <div className="body body-bg body-padding">
        <Link
          to={
            !isSetting && merchantInfoContext.role === "AxraiAdmin"
              ? "/kds/link-qr"
              : isSetting
              ? history.location.state.previous
              : "/kds/settings/update-business-detail"
          }
        >
          <IoArrowBackSharp className="backbutton-styling" />
        </Link>
        <h1 className="orderhome-orderinfo-extralarge">
          {translate("settings-home-page.store-information")}
        </h1>
        {isLoading ? (
          <Box className="center-loader" sx={{ display: "flex" }}>
            <CircularProgress centered />
          </Box>
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={storeInfo}
            validationSchema={validateStoreInfo}
            validateOnMount={true}
            onSubmit={(val, { setSubmitting }) => {
              if (
                !["country", "postalCode", "city", "state"].some(
                  (field) => !val[field]
                ) &&
                val.latitude &&
                val.longitude
              ) {
                handleOnSubmit(val);
              } else {
                setSubmitting(false);
                setSnackbar({
                  snackbarOpen: true,
                  snackbarSeverity: "error",
                  snackbarMessage:
                    "Please select a valid address from the dropdown selection.",
                });
              }
            }}
          >
            {(formikProps) => {
              return (
                <Form>
                  <Box
                    sx={{ display: "flex", flexDirection: "column" }}
                    className="store-information-container"
                  >
                    <div className="container-section-top">
                      <h1 className="orderhome-orderinfo-small">
                        {translate("settings-home-page.store-name")}
                      </h1>
                      <TextField
                        disabled={isMultistore}
                        error={true && formikProps.errors.name}
                        type="text"
                        value={formikProps.values.name}
                        onChange={(e) =>
                          formikProps.setFieldValue("name", e.target.value)
                        }
                        variant="outlined"
                        fullWidth
                        className="input-box"
                        name="name"
                        helperText={getErrorTranslation(
                          formikProps.errors.name
                        )}
                      />
                    </div>
                    {!isMultistore && (
                      <div className="container-section-top">
                        <h1 className="orderhome-orderinfo-small">
                          {translate("store-information-page.sms-no")}
                        </h1>
                        <TextField
                          error={true && formikProps.errors.smsMobileNo}
                          type="text"
                          value={formikProps.values.smsMobileNo}
                          onChange={(e) =>
                            formikProps.setFieldValue(
                              "smsMobileNo",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          fullWidth
                          className="input-box"
                          name="smsMobileNo"
                          helperText={getErrorTranslation(
                            formikProps.errors.smsMobileNo
                          )}
                        />
                      </div>
                    )}
                    {/* <div className="container-section">
                      <div
                        className="title-box"
                        style={{ marginBottom: "1rem" }}
                      >
                        <h1 className="orderhome-orderinfo-small">
                          {translate("settings-home-page.store-banner-image")}
                        </h1>
                      </div>
                      <FileUpload
                        label={translate(
                          "order-listing-screen.clickheretoupload"
                        )}
                        setSnackbar={() => {
                          setSnackbar({
                            snackbarMessage:
                              "Image must be at least 2:1 and at most 3:1",
                            snackbarOpen: true,
                            snackbarSeverity: "error",
                          });
                        }}
                        file={formikProps.values.aboutUsBanner}
                        setFile={(file) => {
                          formikProps.setFieldValue("aboutUsBanner", file);
                        }}
                        error={getErrorTranslation(
                          formikProps.errors.aboutUsBanner
                        )}
                      />
                    </div> */}
                    {googleMapsIsLoaded && (
                      <CustomMapWithInput
                        formikProps={formikProps}
                        inputLabel={translate(
                          "settings-home-page.store-address"
                        )}
                        getErrorTranslation={getErrorTranslation}
                        disabled={isMultistore}
                        hideStoreAddressDetail={isMultistore}
                      />
                    )}
                    {!isMultistore && (
                      <div className="container-section">
                        <h1 className="orderhome-orderinfo-small">
                          {translate("settings-home-page.store-url")}
                        </h1>
                        <div className="title-box">
                          <Box width="88%">
                            <Typography
                              onClick={() => openInNewTab(storeInfo.domain)}
                              align="left"
                              variant="body1"
                              className="store-url"
                            >
                              {storeInfo.domain.includes("http")
                                ? storeInfo.domain
                                : "https://" + storeInfo.domain}
                            </Typography>
                          </Box>
                          <Box className="tooltip">
                            {document.queryCommandSupported("copy") && (
                              <CustomIconButton
                                color="primary"
                                onClick={() =>
                                  copyToClipboard(
                                    storeInfo.domain.includes("http")
                                      ? storeInfo.domain
                                      : "https://" + storeInfo.domain,
                                    "address-custom-tooltip"
                                  )
                                }
                              >
                                <ContentCopy />
                              </CustomIconButton>
                            )}
                            <span
                              className="tooltiptext"
                              id="address-custom-tooltip"
                            >
                              <CheckCircle fontSize="small" />
                              {translate("store-information-page.copied")}
                            </span>
                          </Box>
                        </div>
                      </div>
                    )}
                    {!isMultistore && (
                      <div className="container-section">
                        <div
                          className="title-box"
                          style={{ marginBottom: "1rem" }}
                        >
                          <h1 className="orderhome-orderinfo-small">
                            {translate("settings-home-page.store-qr-code")}
                          </h1>
                          <OutlinedButton
                            title={translate("settings-home-page.download")}
                            disableRipple={true}
                            handleAction={downloadQrCode}
                            className="store-info-button"
                          />
                        </div>
                        <div className="store-qr-display">
                          <img
                            className="img-wrapper qr-code"
                            alt="qrcode"
                            src={toS3Link(storeInfo.webQrCode)}
                          />
                        </div>
                      </div>
                    )}

                    {!isMultistore && (
                      <div className="container-section-button">
                        <LoadingButton
                          className="store-info-next-button"
                          type="submit"
                          // onClick={handleSave}
                          loading={isSaving}
                          loadingPosition="start"
                          disabled={isSaving}
                          variant={isSaving ? "outlined" : "contained"}
                        >
                          {isSetting
                            ? translate("settings-home-page.save")
                            : translate("settings-home-page.next")}
                        </LoadingButton>
                      </div>
                    )}
                  </Box>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
      {isSetting && <Footer page={"setting"} />}
    </>
  );
}

export default StoreInformationPage;
