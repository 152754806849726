import { Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import "./UploadDropZone.scss";
import {AiOutlineEyeInvisible} from 'react-icons/ai'
import {MdCancel} from 'react-icons/md';
import {BiError} from 'react-icons/bi';
import { toS3Link, decodeS3Link } from "../../utils/shareable-utils";
import { setPhotoEditorModalState } from "../../states/photoEditorModalState";
import { Storage } from "aws-amplify";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { useTranslate } from "react-polyglot";

export function UploadDropZone(props) {
  const {
    fileFormat,
    setSingleFile,
    setFiles,
    files,
    folderName,
    uploadLimit = null,
    disabled,
    module = "",
    fixedSize = { width: null, height: null },
  } = props;
  const translate = useTranslate();
  var fileType;
  switch (fileFormat) {
    case "csv":
      fileType =
        ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values";
      break;
    case "image":
      fileType = "image/*";
      break;
    case "video":
      fileType = "video/*";
      break;
    default:
      fileType = "";
  }
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    maxFiles: uploadLimit,
    noClick: true,
    noKeyboard: true,
    accept: fileType,
    onDrop: (acceptedFiles) => {
      const checkFileType = acceptedFiles.map((item) => item.type.substring(0, 6) !== fileType);
      if (!checkFileType.includes(true)) {
      } else {
        props.onDrop(acceptedFiles);
      }
    },
  });

  const getImageBlob = async (image, type) => {
    let result = null;
    if (type === "image") {
      result = await Storage.get(image, { download: true, contentType: "image/png" });
    } else if (type === "video") {
      result = await Storage.get(image, { download: true, contentType: "video/mp4" });
    }

    return result.Body;
  };

  const ImageDropzoneHandler = (props) => {
    const { file, i } = props;
    return (
      <div className="flex-column">
        <div key={file.name} className="photo">
          <div
            className="img-wrapper"
            style={
              typeof file !== "string"
                ? {
                    backgroundImage: `url(${file.preview})`,
                  }
                : {
                    backgroundImage: `url(${toS3Link(file)})`,
                  }
            }
          >
            <div className="img-functional-header">
              <MdCancel
                className="icon-MdCancel"
                onClick={() => setFiles(files.filter((_, ii) => i !== ii))}
              />
            </div>
            {/* <div
              onClick={async () => {
                // setImagePreviewLink(file.preview ? file.preview : file);
                // setOpenViewImageModal(true);
                let imageBlob = null;
                if (typeof file === "string") {
                  imageBlob = await getImageBlob(file, "image").then((res) => {
                    return res;
                  });
                }
                setPhotoEditorModalState({
                  activePanel: "crop",
                  activeImage:
                    typeof file !== "string"
                      ? URL.createObjectURL(file)
                      : URL.createObjectURL(imageBlob),
                  originalImage:
                    typeof file !== "string"
                      ? URL.createObjectURL(file)
                      : URL.createObjectURL(imageBlob),
                  imageWidth: null,
                  imageHeight: null,
                  show: true,
                  keyName: "logo",
                  uuid:
                    typeof file !== "string"
                      ? file.name.replace(".png", "")
                      : file.replace(".png", ""),
                  module: module,
                  fixedSize: fixedSize,
                  folderName: folderName,
                  fileState: files,
                  setFileState: setFiles,
                });
              }}
              className="img-functional-footer"
            >
              <AiOutlineEyeInvisible />
              <label className="view">View</label>
            </div> */}
          </div>
        </div>
        {file.undersize && (
          <div className="img-error-msg flex">
            <BiError style={{ fontSize: 20, paddingTop: 5 }} />
            {fileFormat === "video" ? (
              <label>This file is too small. Please upload a video that exceeds 50kb</label>
            ) : (
              <label>This file is too small. Please upload a photo that exceeds 50kb</label>
            )}
          </div>
        )}
      </div>
    );
  };

  const VideoDropzoneHandler = (props) => {
    const { file, i } = props;
    return (
      <div className="flex-column">
        <div key={file.name} className="photo">
          <div className="img-wrapper">
            <video width="200" height="200" control>
              <source src={typeof file !== "string" ? file.preview : toS3Link(file)} />
            </video>
            <div
              className="img-functional-header"
              style={{ position: "absolute", top: "0", right: "0" }}
            >
              <MdCancel
                className="icon-MdCancel"
                onClick={() => setFiles(files.filter((_, ii) => i !== ii))}
              />
            </div>
            <div
              onClick={async () => {
                // setImagePreviewLink(file.preview ? file.preview : file);
                // setOpenViewImageModal(true);
                let imageBlob = null;
                if (typeof file === "string") {
                  imageBlob = await getImageBlob(file, "video").then((res) => {
                    return res;
                  });
                }
                setPhotoEditorModalState({
                  activePanel: "crop",
                  activeImage:
                    typeof file !== "string"
                      ? URL.createObjectURL(file)
                      : URL.createObjectURL(imageBlob),
                  originalImage:
                    typeof file !== "string"
                      ? URL.createObjectURL(file)
                      : URL.createObjectURL(imageBlob),
                  imageWidth: null,
                  imageHeight: null,
                  show: true,
                  keyName: "logo",
                  uuid:
                    typeof file !== "string"
                      ? file.name.replace(".png", "")
                      : file.replace(".png", ""),
                  module: module,
                  fixedSize: fixedSize,
                  folderName: folderName,
                  fileState: files,
                  setFileState: setFiles,
                });
              }}
              className="img-functional-footer"
              style={{ position: "absolute", bottom: "0" }}
            >
              <AiOutlineEyeInvisible />
              <label className="view">{translate("dropzone.view")}</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const csvFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  
  const getTranslation = (word) => {
    if(word === "image") {
      return translate("dropzone.image");
    } else {
      return word;
    }
  };
  
  return (
    <div className="container">
      {fileFormat === "image" || fileFormat === "video" ? (
        <>
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              justifyContent: files?.length > 1 ? "left" : "center",
              height: _.find(files, { undersize: true }) !== undefined ? "16.5rem" : "13.5rem",
            }}
          >
            {files.length >= uploadLimit || disabled ? null : <input {...getInputProps()} />}
            {!files.length ? (
              <div className="button-label-wrapper">
                <div>
                  <Button
                    className="open-file-dialog-btn"
                    variant="outlined"
                    // color="primary"
                    onClick={open}
                    disabled={disabled}
                  >
                    {fileFormat && (fileFormat === "image")
                      ? translate("dropzone.add-image")
                      : `${translate("dropzone.add")} ${getTranslation(fileFormat)}`
                    }
                  </Button>
                </div>
                <p>
                  {fileFormat && (fileFormat === "image")
                    ? translate("dropzone.or-drop-image")
                    : `${translate("dropzone.or-drop")} ${getTranslation(fileFormat)} ${translate("dropzone.to-upload")}`
                  }
                </p>{" "}
              </div>
            ) : (
              <div className="image-preview-container">
                {files.length >= uploadLimit || disabled ? null : (
                  <label className="single-image-uploader">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        setSingleFile(e.target.files[0]);
                      }}
                      className="none"
                    />
                    <span className="plus">+</span>
                    {fileFormat === "video" ? (
                      <span className="label-upload-images">{translate("dropzone.upload-videos")}</span>
                    ) : (
                      <span className="label-upload-images">{translate("dropzone.upload-images")}</span>
                    )}
                  </label>
                )}
                {files.length > 0 &&
                  files.map((file, i) =>
                    typeof file !== "string" ? (
                      file?.type.split("/")[0] === "image" ? (
                        <ImageDropzoneHandler file={file} i={i} />
                      ) : (
                        <VideoDropzoneHandler file={file} i={i} />
                      )
                    ) : file.split("/")[2] !== "video" ? (
                      <ImageDropzoneHandler file={file} i={i} />
                    ) : (
                      <VideoDropzoneHandler file={file} i={i} />
                    )
                  )}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="container">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <div className="button-label-wrapper">
                <div>
                  <Button
                    className="open-file-dialog-btn"
                    variant="outlined"
                    // color="primary"
                    onClick={open}
                  >
                    {translate("dropzone.add")} {translate("dropzone.file")}
                  </Button>
                </div>
                <p>{translate("dropzone.or-drop")} {translate("dropzone.file")} {translate("dropzone.here")}</p>
              </div>
            </div>
          </div>
          <aside>
            {!!csvFiles.length && (
              <>
                <h4>{translate("dropzone.file-capital")}</h4>
                <ul>{csvFiles}</ul>
              </>
            )}
          </aside>
        </>
      )}
    </div>
  );
}