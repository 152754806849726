import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";
import produce from "immer";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { MerchantContext } from "../../../context/MerchantContext";
import { useTranslate } from "react-polyglot";
import { ContainedButton } from "../../../components/buttons/Buttons";
import { CustomizedSnackbars } from "../../../components/shareable/Shareable";
import { Waypoint } from "react-waypoint";
import "./CreditHistoryPage.scss";
import { SHARED_ERROR_MESSAGE } from "../../../constants";
import { IoArrowBackSharp } from "react-icons/io5";

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import { adminGetMerchantShippingCreditBalanceAndTransactions } from "../../../graphql/queries";

function CreditHistoryPage(props) {
  const history = useHistory();

  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });
  const merchantInfoContext = useContext(MerchantContext);
  const translate = useTranslate();
  const [currentBalance, setCurrentBalance] = useState("");

  // listing table usage state
  const [creditHistoryList, setCreditHistoryList] = useState([]);
  const [listTotal, setListTotal] = useState(null);
  const [nextToken, setNextToken] = useState(0);
  const [maxRowPerPage, setMaxRowPerPage] = useState(10);
  const [creditHistoryListingIsLoading, setCreditHistoryListingIsLoading] =
    useState(false);
  const [page, setPage] = useState(0);
  const [sortMethodParams, setSortMethodParams] = useState({
    field: "createdAt",
    direction: "desc",
  });

  // fetching credithistorylisting from elasticsearch
  const fetchCreditHistoryListingData = async (
    limit = maxRowPerPage,
    page = 0,
    sortMethod = sortMethodParams
  ) => {
    setCreditHistoryListingIsLoading(true);
    try {
      let sort = sortMethod;
      let skipRow = parseInt(page) * parseInt(limit);
      let params = {
        limit: limit,
        nextToken: skipRow,
        filter: { merchantId: { eq: merchantInfoContext.merchantId } },
        sort: sort,
      };

      let res = await API.graphql(
        graphqlOperation(
          adminGetMerchantShippingCreditBalanceAndTransactions,
          params
        )
      );

      setListTotal(
        res.data.adminGetMerchantShippingCreditBalanceAndTransactions.total
      );
      setCurrentBalance(
        res.data.adminGetMerchantShippingCreditBalanceAndTransactions
          .shippingCreditBalance
      );
      if (
        res.data.adminGetMerchantShippingCreditBalanceAndTransactions.total > 0
      ) {
        let creditHistoryRows =
          res.data.adminGetMerchantShippingCreditBalanceAndTransactions.items;
        let updatedCreditHistoryList = produce(creditHistoryList, (draft) => {
          for (const row of creditHistoryRows) {
            draft.push(row);
          }
        });

        setCreditHistoryList(updatedCreditHistoryList);

        if (
          res.data.adminGetMerchantShippingCreditBalanceAndTransactions.total >
          skipRow
        ) {
          setNextToken(
            res.data.adminGetMerchantShippingCreditBalanceAndTransactions.items
              .length
          );
          setPage(page + 1);
          setCreditHistoryListingIsLoading(false);
        } else {
          setNextToken(null);
          setCreditHistoryListingIsLoading(false);
        }
      } else {
        setCreditHistoryList([]);
        setNextToken(null);
      }
      setCreditHistoryListingIsLoading(false);
    } catch (error) {
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
      setCreditHistoryListingIsLoading(false);
    }
  };

  const renderTranslate = (text) => {
    if (text.toLowerCase() === "top up shipping credits") {
      return translate("credit-history-page.top-up-shipping-credits");
    } else if (text.toLowerCase() === "on demand usage") {
      return translate("credit-history-page.on-demand-usage");
    } else if (text.toLowerCase() === "on demand refund") {
      return translate("credit-history-page.on-demand-refund");
    } else {
      return text;
    }
  };

  const getTextColor = (text) => {
    if (text === "On Demand usage") {
      return "red";
    } else {
      return "green";
    }
  };

  const CreditHistoryRow = (props) => {
    const { row, i, nextToken, rowsLength, total, fetchData } = props;

    return (
      <React.Fragment>
        <div className="credit-history-listing-holder">
          <div className="credit-history-listing-box">
            <div className="credit-history-entry-flex-box">
              <div className="credit-history-entry-left-section">
                <h1 className="credit-history-entry-large">
                  {renderTranslate(row.actionType)}
                </h1>
                <h1 className="credit-history-entry-small">
                  {row.orderNumber}
                </h1>
              </div>
              <div className="credit-history-entry-right-section">
                <h1
                  className={[
                    "credit-history-entry-large",
                    getTextColor(row.actionType),
                  ].join(" ")}
                >{`${row.amount < 0 ? "-" : ""}${
                  merchantInfoContext.currency
                } ${(row.amount < 0 ? row.amount * -1 : row.amount).toFixed(
                  2
                )}`}</h1>
                <h1 className="credit-history-entry-small">
                  {moment(row.createdAt).format("MMMM DD, hh:mm A")}
                </h1>
              </div>
            </div>
          </div>
        </div>
        {nextToken !== null && i === rowsLength - 2 && total !== rowsLength && (
          <Waypoint onEnter={fetchData} />
        )}
      </React.Fragment>
    );
  };

  const handleFetchMore = (page) => {
    fetchCreditHistoryListingData(maxRowPerPage, page, sortMethodParams);
  };

  useEffect(() => {
    fetchCreditHistoryListingData();
  }, []);

  return (
    <div className="credit-history-page-wrapper">
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      <Header />
      <div className="body body-bg body-padding">
        <div>
          <Link to="/kds/settings">
            <IoArrowBackSharp className="backbutton-styling"></IoArrowBackSharp>
          </Link>
          <h1 className="orderhome-orderinfo-extralarge">
            {translate("credit-history-page.credit-history-title")}
          </h1>
          <h1 className="orderhome-orderinfo-small">
            {translate("credit-history-page.credit-history-desc")}
          </h1>
          <div className="header-section">
            <div className="balance-display">
              <label className="balance-label">
                {translate("credit-history-page.current-balance")}
              </label>
              <label className="balance-value">
                {merchantInfoContext.currency}{" "}
                {currentBalance !== "" ? currentBalance.toFixed(2) : "-"}
              </label>
            </div>
            <ContainedButton
              title={translate("credit-history-page.top-up")}
              handleAction={() => history.push("/kds/settings/topup")}
              startIcon="plus"
            />
          </div>
          <div className="body-section">
            {creditHistoryList.length > 0 &&
              creditHistoryList.map((row, i) => (
                <CreditHistoryRow
                  i={i}
                  row={row}
                  nextToken={nextToken}
                  rowsLength={creditHistoryList.length}
                  total={listTotal}
                  fetchData={() => handleFetchMore(page)}
                />
              ))}
            {creditHistoryListingIsLoading && (
              <Box className="center-loader" sx={{ display: "flex" }}>
                <CircularProgress centered />
              </Box>
            )}
          </div>
        </div>
      </div>
      <Footer page={"setting"} />
    </div>
  );
}

export default CreditHistoryPage;
