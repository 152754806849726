import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./FinishSetup.module.scss";
import Header from "../../../components/header/Header";
import { MerchantContext } from "../../../context/MerchantContext";
import { useTranslate } from "react-polyglot";
import image from "../../../assets/images/finishsetup.png";
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CustomizedSnackbars } from "../../../components/shareable/Shareable";
import { useQuery } from "../../../hooks/useQuery";

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import { updateStoreSetupStatus } from "../../../graphql/mutations";
import { adminGetMerchantGeneralConfig } from "../../../graphql/queries";

function PackageOffer(props) {
  const translate = useTranslate();
  let history = useHistory();
  const merchantInfoContext = useContext(MerchantContext);
  const [checked, setChecked] = useState(false);
  const [dataSaveLoading, setDataSaveLoading] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });
  //store setting
  const [storeConfig, setStoreConfig] = useState();
  const [storeInfo, setStoreInfo] = useState({
    storeName: "",
  });

  const checkMerchantName = async () => {
    try {
      let res = await API.graphql(
        graphqlOperation(adminGetMerchantGeneralConfig)
      );
      let config = res.data.adminGetMerchantGeneralConfig;
      setStoreConfig(config);
      setStoreInfo({ ...config, storeName: config.name });
    } catch (error) {}
  };

  const goToNextPage = async () => {
    if (checked) {
      setDataSaveLoading(true);
      try {
        let params = {
          acknowledgement: true,
        };

        const respOrderFulfillment = await API.graphql(
          graphqlOperation(updateStoreSetupStatus, params)
        );
        if (
          respOrderFulfillment.data.updateStoreSetupStatus.status === "true"
        ) {
          setTimeout(() => {
            history.push("/kds/select-merchant");
          }, 2000);
        } else {
          setSnackbar({
            snackbarMessage:
              respOrderFulfillment.data.updateStoreSetupStatus.message,
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
        }
        setDataSaveLoading(false);
      } catch (error) {
        console.log(error);
        setDataSaveLoading(false);
      }
    } else {
      setSnackbar({
        snackbarMessage: translate("onboarding-screen.check-before-continue"),
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  useEffect(() => {
    if (merchantInfoContext.role === "AxrailAdmin") {
      checkMerchantName();
    }
  }, []);

  const goToSettingsPage = async () => {
    history.push("/kds/settings");
  };

  return (
    <div>
      <Header></Header>
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      <div className="new-body-padding-with-footer">
        <div>
          <h1 className={styles.packageoffertitle}>
            {translate("settings-home-page.on-site-setup")}
          </h1>
          <Typography
            style={{ textAlign: "center" }}
            color="textSecondary"
            gutterBottom
          >
            <small className={styles.packageoffertext}>
              {translate("settings-home-page.on-site-setup-detail1") +
                " " +
                storeInfo.storeName +
                translate("settings-home-page.on-site-setup-detail2")}
            </small>
          </Typography>

          <div className={styles.packageofferdetailbox}>
            <img className="img-fluid" alt="package" src={image}></img>
          </div>

          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChange} />}
              label={translate("settings-home-page.setup-confirm")}
            />
          </FormGroup>

          <div
            className={styles.packageoffersinglebuttonbox}
            style={{ marginTop: "20px" }}
          >
            <LoadingButton
              className="orderview-confirm-button"
              onClick={() => goToNextPage()}
              loading={dataSaveLoading}
              loadingPosition="start"
              disabled={dataSaveLoading}
              variant={dataSaveLoading ? "outlined" : "contained"}
            >
              {translate("settings-home-page.finish")}
            </LoadingButton>
          </div>
          <div
            className={styles.packageoffersinglebuttonbox}
            style={{ marginTop: "0px" }}
          >
            <button
              className={styles.packageofferrejectbutton}
              onClick={() => goToSettingsPage()}
            >
              {translate("settings-home-page.not-done-yet")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageOffer;
