import React, { useState, useEffect, useContext, useMemo } from "react";
import { Tabs, Tab, Box, CircularProgress } from "@mui/material";
import { Redirect } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import { useHistory } from "react-router-dom";
import produce from "immer";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { useTranslate } from "react-polyglot";
import { InputBase, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { Search } from "@mui/icons-material";
import { MerchantContext } from "../../context/MerchantContext";
import { Waypoint } from "react-waypoint";
import _ from "lodash";
import validator from "../../utils/validator";
import { usePageVisibility } from "../../hooks";
import {
  CustomizedSnackbars,
  CustomSwitch,
} from "../../components/shareable/Shareable";
import { SHARED_ERROR_MESSAGE } from "../../constants";
import { CustomButton } from "../../components/buttons/Buttons";
import "./OrderHomepage.scss";
import { validateExpiryDate } from "../../validation/validateExpiryDate";

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import {
  kdsGetOrderList,
  adminGetMerchantGeneralConfig,
} from "../../graphql/queries";
import {
  adminUpdateMerchantGeneralConfig,
  updateModifierItemsAvailability,
} from "../../graphql/mutations";

const OrderHistoryRow = (props) => {
  const translate = useTranslate();
  const truncateText = (text) =>
    text.length > 25 ? text.substr(0, 15) + "..." : text;
  const history = useHistory();

  const renderColor = (progress, deliveryStatus) => {
    if (progress === "Order Fulfilled") {
      if (deliveryStatus === "No Rider Found") {
        return {
          backgroundColor: "#E74C3C",
          color: "#FFFFFF",
        };
      } else {
        return {
          backgroundColor: "#7EC480",
          color: "#FFFFFF",
        };
      }
    } else if (progress === "Waiting Refund") {
      return {
        backgroundColor: "#E74C3C",
        color: "#FFFFFF",
      };
    } else if (progress === "Order Completed") {
      return {
        backgroundColor: "#EBFFEB",
        color: "#69AB6B",
      };
    } else if (progress === "Shipping") {
      if (deliveryStatus === "No Rider Found") {
        return {
          backgroundColor: "#E74C3C",
          color: "#FFFFFF",
        };
      } else {
        return {
          backgroundColor: "#0A2540",
          color: "#FFFFFF",
        };
      }
    } else {
      return {
        backgroundColor: "#0A2540",
        color: "#FFFFFF",
      };
    }
  };

  const renderDate = (date) => {
    if (localStorage.getItem("locale") === "zh") {
      moment.updateLocale(localStorage.getItem("locale"), {
        calendar: {
          lastDay: "[昨天]",
          sameDay: "LT",
          nextDay: "[明天 at] LT",
          lastWeek: "DD/MM/YYYY",
          nextWeek: "dddd [at] LT",
          sameElse: "DD/MM/YYYY",
        },
      });
    } else if (localStorage.getItem("locale") === "bm") {
      moment.updateLocale(localStorage.getItem("locale"), {
        calendar: {
          lastDay: "[Semalam]",
          sameDay: "LT",
          nextDay: "[Esok at] LT",
          lastWeek: "DD/MM/YYYY",
          nextWeek: "dddd [at] LT",
          sameElse: "DD/MM/YYYY",
        },
      });
    } else {
      moment.updateLocale("en", {
        calendar: {
          lastDay: "[Yesterday]",
          sameDay: "LT",
          nextDay: "[Tomorrow at] LT",
          lastWeek: "DD/MM/YYYY",
          nextWeek: "dddd [at] LT",
          sameElse: "DD/MM/YYYY",
        },
      });
    }
    return moment(date).calendar();
  };

  const renderStatus = (text, type, deliveryStatus) => {
    if (text === "Pending Fulfilled") {
      return translate("order-listing-screen.pending-fulfilled-status");
    } else if (text === "New Order") {
      return translate("order-listing-screen.pending-fulfilled-status");
    } else if (text === "Pending Payment") {
      return translate("order-listing-screen.pending-fulfilled-status");
    } else if (text === "Order Fulfilled") {
      if (type === "Delivery") {
        if (deliveryStatus === "No Rider Found") {
          return translate("order-listing-screen.no-rider");
        } else {
          return translate("order-listing-screen.order-fulfilled-status");
        }
      } else {
        return translate("order-listing-screen.order-fulfilled-status");
      }
    } else if (text === "Shipping") {
      if (type === "Delivery") {
        if (deliveryStatus === "No Rider Found") {
          return translate("order-listing-screen.no-rider");
        } else if (deliveryStatus === "Requested for Delivery") {
          return translate("order-listing-screen.order-reqrider-status");
        } else {
          return translate("order-listing-screen.order-shipping-status");
        }
      }
    } else if (text.toLowerCase() === "ready for customer pickup") {
      return translate(
        "order-listing-screen.order-ready-for-customer-pickup-status"
      );
    } else if (text === "Served to Customer") {
      return translate("order-listing-screen.order-served-to-customer-status");
    } else if (text === "Order Completed") {
      return translate("order-listing-screen.order-completed-status");
    } else {
      return text;
    }
  };

  const {
    row,
    i,
    nextToken,
    rowsLength,
    total,
    fetchData,
    fetchDataError,
    isLoading,
  } = props;

  return (
    <React.Fragment>
      <div
        key={row.orderId}
        className="orderhome-orderinfo-pickup"
        onClick={() => history.push("/kds/vieworder?orderID=" + row.orderId)}
      >
        <div className="orderhome-order-box">
          <div className="orderhome-flex-box">
            <div>
              <h1 className="orderhome-orderinfo-large">
                {row.orderNumber}
                {row.type === "DineIn"
                  ? ` (${translate("order-listing-screen.table-no")} ${
                      row.tableNumber
                    })`
                  : ""}
              </h1>
              <h1 className="orderhome-orderinfo-small-2">
                {row.totalOrderItems > 1
                  ? row.totalOrderItems +
                    " " +
                    translate("order-listing-screen.items-for") +
                    " " +
                    truncateText(
                      row.customerFirstName + "  " + row.customerLastName
                    )
                  : row.totalOrderItems +
                    " " +
                    translate("order-listing-screen.item-for") +
                    " " +
                    truncateText(
                      row.customerFirstName + "  " + row.customerLastName
                    )}
              </h1>
            </div>
            <div className="push-right">
              <h1
                className="orderhome-orderinfo-time"
                style={{ textTransform: "none" }}
              >
                {renderDate(row.orderDateTime)}
              </h1>
              <h1
                className="orderhome-orderinfo-mainpickup"
                style={renderColor(row.status, row.deliveryStatus)}
              >
                {renderStatus(row.status, row.type, row.deliveryStatus)}
              </h1>
            </div>
          </div>
          {row.isAdvancedOrder ? (
            <h1
              style={{ fontWeight: "bold", textTransform: "none" }}
              className="orderhome-orderinfo-small-2"
            >
              {translate("order-listing-screen.complete-at") +
                " " +
                moment(row.scheduledDateTime).format("DD/MM LT")}
            </h1>
          ) : null}
        </div>
      </div>
      {nextToken !== null && i === rowsLength - 2 && total !== rowsLength && (
        <Waypoint onEnter={fetchData} />
      )}
    </React.Fragment>
  );
};

function OrderHomepage(props) {
  const translate = useTranslate();
  const truncateText = (text) =>
    text.length > 25 ? text.substr(0, 15) + "..." : text;
  const history = useHistory();
  const theme = createTheme({
    palette: {
      primary: {
        main: "#008CDC",
      },
      secondary: {
        main: "#008CDC",
      },
    },
  });
  const merchantInfoContext = useContext(MerchantContext);
  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });
  const [orderType, setOrderType] = useState(
    localStorage.getItem("orderType") === null
      ? "Delivery"
      : localStorage.getItem("orderType")
  );
  // listing table usage state
  const [orderList, setOrderList] = useState([]);
  const [storeData, setStoreData] = useState("");
  const [listTotal, setListTotal] = useState(null);
  const [nextToken, setNextToken] = useState(0);
  const [maxRowPerPage, setMaxRowPerPage] = useState(30);
  const [storeIsLoading, setStoreIsLoading] = useState(false);
  const [orderListingIsLoading, setOrderListingIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");
  const [fetchDataError, setFetchDataError] = useState(false);
  const [sortMethodParams, setSortMethodParams] = useState({
    field: "orderDateTime",
    direction: "desc",
  });
  //useeffect dependency to call fetch listing api after delete
  const [refreshListing, setRefreshListing] = useState(0);
  const [newDeliveryOrderCount, setNewDeliveryOrderCount] = useState(0);
  const [newPickUpOrderCount, setNewPickUpOrderCount] = useState(0);
  const [newDineInOrderCount, setNewDineInOrderCount] = useState(0);
  const [newAdvancedOrderCount, setNewAdvancedOrderCount] = useState(0);

  const defaultTabFilter = [
    {
      or: [
        { status: { eq: "Pending Fulfilled" } },
        { status: { eq: "Order Fulfilled" } },
        { status: { eq: "Shipping" } },
        { status: { eq: "Pending Payment" } },
        { status: { eq: "Ready for Customer Pickup" } },
        { status: { eq: "Served to Customer" } },
      ],
    },
  ];

  const defaultAllOrderFilter = [
    {
      or: [
        { type: { eq: "DineIn" } },
        { type: { eq: "Delivery" } },
        { type: { eq: "PickUp" } },
      ],
    },
  ];

  // fetching orderlisting from elasticsearch
  const fetchOrderListingData = async (
    limit = maxRowPerPage,
    page = 0,
    sortMethod = sortMethodParams
  ) => {
    setOrderListingIsLoading(true);
    try {
      let sort = sortMethod;
      let skipRow = parseInt(page) * parseInt(limit);
      let params = {
        limit: limit,
        nextToken: skipRow,
        filter: {
          and: [
            { merchantId: { eq: merchantInfoContext.merchantId } },
            ...defaultTabFilter,
            { type: { eq: orderType } },
          ],
        },
        merchantId: merchantInfoContext.merchantId,
        sort: sort,
        advancedOrderTab: false,
      };

      if (orderType === "isAdvanced") {
        params = {
         limit: limit,
         nextToken: skipRow,
         filter: {
           and: [
             ...defaultTabFilter,
            ...defaultAllOrderFilter,
           ],
         },
         sort: sort,
         advancedOrderTab: true,
        }
      }

      if (!validator.isEmpty(searchFilter)) {
        params = {
          ...params,
          filter: {
            and: [...params.filter.and, searchFilter],
          },
        };
      }

      let res = await API.graphql(graphqlOperation(kdsGetOrderList, params));
      setListTotal(res.data.kdsGetOrderList.total);
      if (res.data.kdsGetOrderList.total > 0) {
        let orderRows = res.data.kdsGetOrderList.orderList;
        let updatedOrderList = produce(orderList, (draft) => {
          for (const [key, value] of Object.entries(orderRows)) {
            draft.push(
              Object.assign(value, {
                isChecked: false,
                index: parseInt(key) + parseInt(skipRow),
              })
            );
          }
        });

        setOrderList(updatedOrderList);

        if (res.data.kdsGetOrderList.total > skipRow) {
          setNextToken(res.data.kdsGetOrderList.orderList.length);
          setPage(page + 1);
          setOrderListingIsLoading(false);
        } else {
          setNextToken(null);
          setOrderListingIsLoading(false);
        }
      } else {
        setOrderList([]);
        setNextToken(null);
      }
      setNewDeliveryOrderCount(res.data.kdsGetOrderList.newDeliveryOrderCount);
      setNewPickUpOrderCount(res.data.kdsGetOrderList.newPickUpOrderCount);
      setNewDineInOrderCount(res.data.kdsGetOrderList.newDineInOrderCount);
      setNewAdvancedOrderCount(res.data.kdsGetOrderList.newAdvancedOrderCount);

      setOrderListingIsLoading(false);
    } catch (error) {
      console.log("fetch order listing error", error);
      setOrderListingIsLoading(false);
      setFetchDataError(true);
    }
  };

  const fetchStoreData = async () => {
    setOrderListingIsLoading(true);
    try {
      let res = await API.graphql(
        graphqlOperation(adminGetMerchantGeneralConfig)
      );

      setStoreData(res.data.adminGetMerchantGeneralConfig);
      setStoreIsLoading(false);
    } catch (error) {
      console.log("fetch store data error", error);
      setStoreIsLoading(false);
      setFetchDataError(true);
    }
  };

  const handleOnSearch = (e) => {
    let currentSearchFilter = {};

    if (e.target.value.length > 0 && e.target.value) {
      let searchKeyword = e.target.value.toLowerCase();

      currentSearchFilter = { or: [] };
      currentSearchFilter["or"].push({
        orderNumber: { regexp: ".*" + searchKeyword + ".*" },
      });
      currentSearchFilter["or"].push({
        customerFirstName: { regexp: ".*" + searchKeyword + ".*" },
      });
      currentSearchFilter["or"].push({
        customerLastName: { regexp: ".*" + searchKeyword + ".*" },
      });
    }

    setOrderList([]);
    setSearchFilter(currentSearchFilter);
  };

  const debouncedHandleOnSearch = useMemo(() => {
    return _.debounce(handleOnSearch, 800);
  }, []);

  const handleFetchMore = (page) => {
    fetchOrderListingData(maxRowPerPage, page, sortMethodParams);
  };

  const isPageVisible = usePageVisibility();
  let [refreshIntervalId, setRefreshIntervalId] = useState();
  const refreshOrderList = () => {
    setOrderList([]);
    setRefreshListing((prev) => prev + 1);
    clearInterval(refreshIntervalId);
    let newIntervalId = setTimeout(refreshOrderList, 60000);
    setRefreshIntervalId(newIntervalId);
  };

  useEffect(() => {
    fetchOrderListingData();
  }, [orderType, searchFilter, refreshListing]);

  useMemo(() => {
    if (isPageVisible) {
      refreshOrderList();
    }
  }, [isPageVisible]);

  useEffect(() => {
    fetchStoreData();
  }, []);

  
  useEffect(() =>
    validateExpiryDate(merchantInfoContext.subscriptionExpiryDate)
  )
  
  const handleChange = (event, newValue) => {
    setOrderList([]);
    setOrderType(newValue);
    localStorage.setItem("orderType", newValue);
  };

  const handleClickStoreStatusSwitch = async (e) => {
    if (storeData) {
      setStoreData({
        ...storeData,
        warungStoreStatus: e.target.checked ? "open" : "closed",
      });
      try {
        let params = {
          kycBusinessAddress: storeData.kycBusinessAddress,
          kycNRIC: storeData.kycNRIC,
          kycNRICName: storeData.kycNRICName,
          address: storeData.address,
          currency: storeData.currency,
          merchantRegistrationNumber: storeData.merchantRegistrationNumber,
          name: storeData.name,
          notificationEmail: storeData.notificationEmail,
          ownerEmail: storeData.ownerEmail,
          ownerPhone: storeData.ownerPhone,
          senderEmail: storeData.senderEmail,
          orderOption: storeData.orderOption,
          tax: parseInt(storeData.tax),
          warungStoreStatus: e.target.checked ? "open" : "closed",
          whatsappNo: storeData.whatsappNo,
        };

        let res = await API.graphql(
          graphqlOperation(adminUpdateMerchantGeneralConfig, params)
        );

        setSnackbar({
          snackbarMessage:
            res.data.adminUpdateMerchantGeneralConfig.message ===
            "General Settings updated successfully."
              ? e.target.checked
                ? translate("order-listing-screen.store-opened")
                : translate("order-listing-screen.store-closed")
              : res.data.adminUpdateMerchantGeneralConfig.message,
          snackbarOpen: true,
          snackbarSeverity:
            res.data.adminUpdateMerchantGeneralConfig.status === "true"
              ? "success"
              : "error",
        });
        if (e.target.checked) {
          setOpenConfirmResetDialog(true);
        }
      } catch (error) {
        setSnackbar({
          snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
      }
    } else {
      setSnackbar({
        snackbarMessage: translate("error-handler.unknown-error"),
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  // confirm reset add ons dialog usage
  const [openConfirmResetDialog, setOpenConfirmResetDialog] = useState(false);
  // call api to change add on status
  const handleResetAllAddOns = async () => {
    try {
      let params = {
        availableStatus: "",
        modifierId: "",
        resetAll: true,
        storeId: merchantInfoContext.storeId,
      };
      let res = await API.graphql(
        graphqlOperation(updateModifierItemsAvailability, params)
      );

      setSnackbar({
        snackbarMessage: getChangeAddOnStatusResponse(
          res.data.updateModifierItemsAvailability.message
        ),
        snackbarOpen: true,
        snackbarSeverity: res.data.updateModifierItemsAvailability.status
          ? "success"
          : "error",
      });
      if (res.data.updateModifierItemsAvailability.status) {
        setOpenConfirmResetDialog(false);
      }
    } catch (error) {
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  const getChangeAddOnStatusResponse = (message) => {
    switch (message) {
      case "Status updated successfully":
        return translate("product-listing-screen.change-add-on-status-success");

      default:
        return message;
    }
  };

  const renderSearch = () => {
    return (
      <div className="search-bar">
        <InputBase
          className="search-bar-input"
          onChange={debouncedHandleOnSearch}
          placeholder={translate("order-listing-screen.search")}
        />
        <label className="search-bar-logo">
          <Search className="logo" />
        </label>
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Box style={{ marginBottom: "10px" }} sx={{ width: "100%" }}>
            {storeData ? (
              <Tabs
                value={orderType}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="Order Status Tabs"
                centered
                className="orderhome-tabs-text"
              >
                {storeData && storeData.orderOption
                  ? storeData.orderOption.map((status) => {
                      return (
                        <Tab
                          key={status}
                          value={status}
                          label={
                            <div className="tab-label-wrapper">
                              <span>{renderTabsText(status)}</span>
                              {!!getNewOrderCount(status) && (
                                <div className="tab-number-display">
                                  {getNewOrderCount(status)}
                                </div>
                              )}
                            </div>
                          }
                          className="each-tab"
                        />
                      );
                    })
                  : null}
                {storeData.advancedOrderEnabled ? (
                  <Tab
                    key={"isAdvanced"}
                    value={"isAdvanced"}
                    label={
                      <div className="tab-label-wrapper">
                        <span>{renderTabsText("Advanced")}</span>
                        {!!getNewOrderCount("Advanced") && (
                          <div className="tab-number-display">
                            {getNewOrderCount("Advanced")}
                          </div>
                        )}
                      </div>
                    }
                    className="each-tab"
                  />
                ) : null}
              </Tabs>
            ) : null}
          </Box>
        </ThemeProvider>
        {storeIsLoading && (
          <Box className="center-loader" sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        )}
      </div>
    );
  };

  const renderTabsText = (text) => {
    if (text === "PickUp") {
      return translate("order-listing-screen.predefined-tab-pickup");
    } else if (text === "Delivery") {
      return translate("order-listing-screen.predefined-tab-delivery");
    } else if (text === "DineIn") {
      return translate("order-listing-screen.predefined-tab-dinein");
    } else if (text === "Advanced") {
      return translate("order-listing-screen.predefined-tab-advanced");
    } else {
      return text;
    }
  };

  const getNewOrderCount = (type) => {
    if (type === "PickUp") {
      return newPickUpOrderCount;
    } else if (type === "Delivery") {
      return newDeliveryOrderCount;
    } else if (type === "DineIn") {
      return newDineInOrderCount;
    } else if (type === "Advanced") {
      return newAdvancedOrderCount;
    }
  };

  return (
    <div>
      <Header page={"order"}></Header>
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      <div className="body body-bg body-padding-with-footer">
        <div className="orderhome-title-section">
          <h1 className="main-title">
            {translate("order-listing-screen.orders")}
          </h1>
          <div className="store-status-switch-section">
            <label className="store-status-label">
              {storeData?.warungStoreStatus === "open"
                ? translate("order-listing-screen.open")
                : translate("order-listing-screen.closed")}
            </label>
            <CustomSwitch
              defaultChecked={false}
              checked={storeData?.warungStoreStatus === "open"}
              onClick={handleClickStoreStatusSwitch}
            />
          </div>
        </div>
        <div>
          {renderTabs()}
          {renderSearch()}
        </div>
        <div className="orderhome-body-section">
          {orderList && orderList.length ? (
            orderList.map((row, i) => (
              <OrderHistoryRow
                key={i}
                i={i}
                row={row}
                nextToken={nextToken}
                rowsLength={orderList.length}
                total={listTotal}
                fetchData={() => handleFetchMore(page)}
                fetchDataError={fetchDataError}
                isLoading={orderListingIsLoading}
              />
            ))
          ) : orderListingIsLoading ? null : (
            <h1
              style={{ textAlign: "center" }}
              className="orderhome-orderinfo-small"
            >
              {translate("order-listing-screen.noorder")}
            </h1>
          )}
          {orderListingIsLoading && (
            <Box className="center-loader" sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          )}
        </div>
        <Dialog
          open={openConfirmResetDialog}
          onClose={() => setOpenConfirmResetDialog(false)}
          className="confirm-delete-dialog"
        >
          <DialogTitle id="alert-dialog-title">
            {translate("product-listing-screen.confirm-reset-all")}
          </DialogTitle>
          <DialogActions>
            <CustomButton
              onClick={() => setOpenConfirmResetDialog(false)}
              autoFocus
              variant="contained"
              color="error"
            >
              {translate("order-listing-screen.no")}
            </CustomButton>
            <CustomButton
              onClick={() => handleResetAllAddOns()}
              variant="outlined"
            >
              {translate("order-listing-screen.yes")}
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>
      <Footer page={"orders"}></Footer>
    </div>
  );
}

export default OrderHomepage;
