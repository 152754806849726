import React from 'react';
import {AiOutlineHome,AiOutlineSetting} from 'react-icons/ai';
import {CgMenuBoxed} from 'react-icons/cg';
import {BiHistory} from 'react-icons/bi';
import {Link} from 'react-router-dom';
import { useTranslate } from "react-polyglot";

function Footer({page}) {
    const translate = useTranslate();
    const fromMobileApp = localStorage.getItem('fromMobileApp')
    const hideFooter = fromMobileApp && fromMobileApp !== "IS_MOBILE_APP" ? true : false
    
    return (
        <>
        {hideFooter ? (
            <>
            </>
        ) : (
        <div className='orderview-button-bottom'>
            <div className='ordercomplete-button-holder'>
                <div className='ewarung-footer'>
                    <Link style={{textDecoration:"none"}} to="/kds/orders">
                        <div className="footer-icon-container">
                            <AiOutlineHome className={`footer-icon-styling ${page === "orders" ? "active" : ""}`}></AiOutlineHome>
                        </div>

                        <p className={`footer-label ${page === "orders" ? "active" : ""}`}>{translate("footer.orders")}</p>
                    </Link>
               
                    <Link style={{textDecoration:"none"}} to="/kds/viewmenu">
                        <div className="footer-icon-container">
                            <CgMenuBoxed className={`footer-icon-styling ${page === "menu" ? "active" : ""}`}></CgMenuBoxed>
                        </div>

                        <p className={`footer-label ${page === "menu" ? "active" : ""}`}>{translate("footer.menu")}</p>
                    </Link>
                    <div>
                        <Link style={{textDecoration:"none"}} to="/kds/history">
                            <div className="footer-icon-container">
                                <BiHistory className={`footer-icon-styling ${page === "history" ? "active" : ""}`}></BiHistory>
                            </div>

                            <p className={`footer-label ${page === "history" ? "active" : ""}`}>{translate("footer.history")}</p>
                        </Link>
                    </div>
                    <div>
                        <Link style={{textDecoration:"none"}} to="/kds/settings">
                            <div className="footer-icon-container">
                                <AiOutlineSetting className={`footer-icon-styling ${page === "setting" ? "active" : ""}`}></AiOutlineSetting>
                            </div>

                            <p className={`footer-label ${page === "setting" ? "active" : ""}`}>{translate("footer.settings")}</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        )}
        </>
    );
}

export default Footer;