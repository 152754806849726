import React from 'react';
import {
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Typography,
  InputBase,
  Button,
  Paper,
  CircularProgress,
  TextField,
} from '@mui/material';
import { useTranslate } from "react-polyglot";

export default function AuthFields(props) {
  const {
    email,
    userCountry,
    setUserCountry,
    mobileNo,
    setMobileNo,
    loginWithMobile,
    setLoginWithMobile,
    password,
    confirmPassword,
    setConfirmPassword,
    setPassword,
    setEmail,
    isForgot,
    setForgot,
    handleForgot,
    loginValidation,
    setLoginValidation,
    mustResetPassword,
    code,
    setCode,
    isSend,
    setSend,
    errors,
    message,
    handleKeyPress,
    handleLogin,
    isLoading,
  } = props;
  
  const translate = useTranslate();
  const countries = [
    {
      validation: "(1)[0-46-9]-*[0-9]{7,8}|[0-9]{8,10}",
      placeholder: "12345 678 or 321234567",
      code: "+60",
      emoji: "🇲🇾",
      id: 0,
    },
    {
      validation: "[0-9]{8,8}|[0-9]{8,8}",
      placeholder: "12345678",
      code: "+65",
      emoji: "🇸🇬",
      id: 1,
    },
  ];

  return (
      <div>
      {isSend && (
        <Typography style={{textAlign:"center"}} color="textSecondary" gutterBottom>
          <small>
            {loginWithMobile?  translate("login-handler.enter-mobile-number") :  translate("login-handler.enter-email")}
          </small>
        </Typography>
      )}
      
      {isForgot === true ? (
        <div className="password-requirement">
          <h4>{translate("login-handler.password-requirement")}</h4>
        </div>
      ) : null}

      {loginWithMobile ? (
      <TextField
        type="tel"
        sx={{ width: "100%", backgroundColor: "white", mt: 0.5 }}
        size="medium"
        required
        name="mobileNo"
        value={mobileNo}
        onChange={(e) => setMobileNo(e.target.value.trim())}
        className="sign-in-input-main-theme"
        label={translate("login-handler.mobile-no")}
        inputProps={{
          pattern: countries[userCountry].validation,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Select
                disableUnderline
                value={userCountry}
                variant="standard"
                style={{fontSize:"0.6rem"}}
                onChange={(e) =>
                  setUserCountry(parseInt(e.target.value))
                }
              >
                {countries.map(({ id, emoji, code }) => (
                  <MenuItem value={id} key={id}>
                    <Stack direction={"row"} spacing={2}>
                      <Typography>{emoji}</Typography>
                      <Typography>{code}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </InputAdornment>
          ),
        }}
      />
      ) : (   
      <TextField
        label={translate("login-handler.email")}
        type="email"
        value={email}
        disabled={isForgot}
        onChange={(e) => setEmail(e.target.value)}
        variant="outlined"
        className="sign-in-input-main-theme"
        inputProps={{ pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$' }}
        name="primaryEmail"
      />
      )}     
      {isForgot === true ? (
        <TextField
          label={translate("login-handler.verification-code")}
          type="number"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          variant="outlined"
          className="sign-in-input-main-theme"
          name="password"
        />
      ) : null}

      {isSend === true || mustResetPassword ? null : (
        <TextField
          label={isForgot === false ? translate("login-handler.password") : translate("login-handler.new-password")}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
          variant="outlined"
          className="sign-in-input-main-theme"
          name="password"
        />
      )}
      {isForgot === false ? null : (
        <TextField
          label={translate("login-handler.password-asterisk")}
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onKeyPress={handleKeyPress}
          variant="outlined"
          className="sign-in-input-main-theme"
          name="password"
        />
      )}

      <div className="forgot-password-box">
        {isForgot === true ? (
          <button onClick={handleForgot} className="forgot-password">
            {translate("login-handler.resend")}
          </button>
        ) : isSend === false && !mustResetPassword ? (
          <button
            className="forgot-password"
            onClick={() => {
              setSend(true);
              setLoginValidation(false);
              setEmail('');
            }}
          >
            {translate("login-handler.forgot-password")}
          </button>
        ) : null}
      </div>
      <div className="forgot-password-box">
      {
        /*
        {loginWithMobile ? (
          <button
          className="forgot-password"
          onClick={() => {
            setMobileNo('')
            setLoginWithMobile(false)
          }}
        >
          {translate("login-handler.login-with-email")}
        </button>
        ) : (
        
        <button
          className="forgot-password"
          onClick={() => {
            setEmail('')
            setLoginWithMobile(true)
          }}
        >
          {translate("login-handler.login-with-mobile")}
        </button>
        )}
        */
      }
      </div>
      <div className="flex">
        <Button
          variant="contained"
          className="orderview-confirm-noload-button"
          style={
            isSend === true || isForgot === true ? { marginBottom:"10px" } : null
          }
          color="primary"
          disabled={isLoading}
          onClick={isSend === false ? handleLogin : handleForgot}
        >
          {!isLoading ? (
            isSend === true || isForgot === true ? (
              translate("login-handler.submit")
            ) : (
              translate("login-handler.sign-in")
            )
          ) : (
            <CircularProgress size={20} color={'inherit'} />
          )}
        </Button>
        {isSend === true || isForgot === true ? (
          <Button
            variant="outlined"
            className="orderview-confirm-button"
            disabled={isLoading}
            onClick={() => {
              setSend(false);
              setEmail('');
              setMobileNo('');
              setPassword('');
              setCode('');
              setLoginValidation(false);
              setForgot(false);
            }}
          >
            {isForgot === false ? translate("login-handler.back-to-login") : translate("login-handler.cancel")}
          </Button>
        ) : null}
        
      </div>
    </div>
  );
}
