import { useState, useRef, useEffect } from 'react';

export class GlobalState {
    state = {};
    listeners = [];

    constructor(reducer, initialState = {}) {
        this.state = initialState;
        this.reducer = reducer;
        this.devTools =
            typeof window !== 'undefined' &&
            window?.__REDUX_DEVTOOLS_EXTENSION__?.connect();
    }

    listen(listener) {
        this.listeners.push(listener);
    }

    unlisten(listener) {
        this.listeners = this.listeners.filter(l => l !== listener);
    }

    dispatch = (actionName, payload) => {
        const nextState = this.reducer(this.state, actionName, payload);

        if (nextState !== this.state) {
            this.state = nextState;
            this.listeners.forEach(l => l(nextState));
            this.devTools && this.devTools.send(actionName, payload);
        }
    };
}

export function useGlobalState(globalState, stateGetter) {
    const [state, setState] = useState(stateGetter(globalState.state));

    // We don't want to re-create the listener as we want to unlisten on unmount
    // of the component which uses this hook, so we "tunnel" the state in.
    const stateRef = useRef(state);
    stateRef.current = state;

    const listener = useRef(nextState => {
        const stateUpdate = stateGetter(nextState);
        if (stateRef.current !== stateUpdate) {
            setState(stateUpdate);
        }
    });

    useEffect(() => {
        globalState.listen(listener.current);
        return () => globalState.unlisten(listener.current);
    }, [globalState]);

    return [state, globalState.dispatch];
}
