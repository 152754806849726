import React from 'react';
import {useDropzone} from 'react-dropzone';
import "./SimplerUploadDropZone.scss";
import { useTranslate } from "react-polyglot";
import { toS3Link } from "../../utils/shareable-utils";
import {MdCancel} from 'react-icons/md';

export function SimplerUploadDropZone(props) {
  const translate = useTranslate();    
    
  const {
    onDrop,
    uploadLimit,
    files,
    setFiles,
    disabled,
  } = props;    
    
  var fileType = "image/*";
        
  const {
    acceptedFiles,
    getRootProps,
    getInputProps
  } = useDropzone({
    // Disable keydown behavior
    maxFiles: uploadLimit,
    noKeyboard: true,
    //only accepts jpeg and png
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    onDrop: (acceptedFiles) => {
      const checkFileType = acceptedFiles.map((item) => item.type.substring(0, 6) !== fileType);
      if (!checkFileType.includes(true)) {
      } else {
        onDrop(acceptedFiles);
      }
    },
  });

  const ImageDropzoneHandler = (props) => {
    const { file, i } = props;
    return (
      <div className="image-drop-zone-handler">
        <div key={file.name} className="photo">
          <div
            className="img-wrapper"
            style={
              typeof file !== "string"
                ? {
                    backgroundImage: `url(${file.preview})`,
                  }
                : {
                    backgroundImage: `url(${toS3Link(file)})`,
                  }
            }
          >
            <div className="img-functional-header">
              <MdCancel
                className="icon-MdCancel"
                onClick={() => setFiles(files.filter((_, ii) => i !== ii))}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="simpler-dropzone-container">
      <div {...getRootProps({ className: 'simpler-dropzone' })}>
        {files.length >= uploadLimit || disabled
            ? null
            : <input {...getInputProps()} />
        }
        {!files.length ? (
            <label className="simpler-dropzone-label">{translate("dropzone.instruction")}</label>
        ) : (
            <div className="simpler-image-preview-container">
                {files.length > 0 &&
                  files.map((file, i) =>
                    typeof file !== "string" ? (
                      file?.type.split("/")[0] === "image" ? (
                        <ImageDropzoneHandler file={file} i={i} key={i}/>
                      ) : (
                        null
                      )
                    ) : file.split("/")[2] !== "video" ? (
                      <ImageDropzoneHandler file={file} i={i} key={i}/>
                    ) : (
                      null
                    )
                  )}
            </div>
        )}
      </div>
    </section>
  );
}