/* eslint-disable */

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:68ab8d51-bad6-4532-a1f8-2a71084464a3",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_R0R0FMAaX",
    "aws_user_pools_web_client_id": "3j4oohn5ea6haqritblk00v2ma",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://nq6jzvh6xba23ktugk7ftc6xh4.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-bkheunlxd5bgnltkaujymho3g4",
    "aws_user_files_s3_bucket": "ecom-webassets-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};

export default awsmobile;
