import { LoadingButton } from "@mui/lab";
import { Checkbox, CircularProgress } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { IoArrowBackSharp } from "react-icons/io5";
import { useTranslate } from "react-polyglot";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { CustomizedSnackbars } from "../../../components/shareable/Shareable";
import { SHARED_ERROR_MESSAGE } from "../../../constants";
import { MerchantContext } from "../../../context/MerchantContext";
import "./StoreTypePage.scss";

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import { kdsUpdateStoreOrderOption } from "../../../graphql/mutations";
import { adminGetMerchantGeneralConfig } from "../../../graphql/queries";
import { useQuery } from "../../../hooks/useQuery";
import useCheckMultistoreUser from "../compnents/useCheckMultistoreUser";

function StoreTypePage(props) {
  const history = useHistory();

  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });
  const merchantInfoContext = useContext(MerchantContext);
  const { isMultistore } = useCheckMultistoreUser();
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [isApiProcessing, setIsApiProcessing] = useState(false);
  const [storeType, setStoreType] = useState([]);
  const storeTypeList = useMemo(
    () =>
      !isMultistore ? ["PickUp", "Delivery", "DineIn"] : ["PickUp", "Delivery"],
    [isMultistore]
  );
  const [isSetting, setIsSetting] = useState(false);
  let query = useQuery();

  const checkifStoreTypeEmpty = () => {
    if (storeType.length < 1) {
      setSnackbar({
        snackbarMessage: translate("store-type-page.store-type-required"),
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    } else {
      handleSaveStoreType();
    }
  };

  const handleSaveStoreType = async () => {
    setIsApiProcessing(true);

    try {
      let params = {
        orderOption: storeType,
      };
      let res = await API.graphql(
        graphqlOperation(kdsUpdateStoreOrderOption, params)
      );
      if (!isSetting) {
        //click Next
        history.push("/kds/settings/storeopening");
      }

      setSnackbar({
        snackbarMessage: res.data.kdsUpdateStoreOrderOption.message,
        snackbarOpen: true,
        snackbarSeverity: res.data.kdsUpdateStoreOrderOption.status
          ? "success"
          : "error",
      });
      setIsApiProcessing(false);
    } catch (error) {
      setIsApiProcessing(false);
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  const fetchStoreType = async () => {
    setIsLoading(true);
    try {
      let res = await API.graphql(
        graphqlOperation(adminGetMerchantGeneralConfig, {
          merchantId: merchantInfoContext.merchantId,
        })
      );

      if (res.data.adminGetMerchantGeneralConfig.status === "true") {
        setStoreType(
          !!res.data.adminGetMerchantGeneralConfig.orderOption &&
            res.data.adminGetMerchantGeneralConfig.orderOption.length > 0
            ? res.data.adminGetMerchantGeneralConfig.orderOption
            : []
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  const handleClickStoreType = (type) => {
    if (isMultistore) return;
    let tmpStoreType = [...storeType];
    if (tmpStoreType.indexOf(type) !== -1) {
      tmpStoreType.splice(tmpStoreType.indexOf(type), 1);
    } else {
      tmpStoreType.push(type);
    }
    setStoreType(tmpStoreType);
  };

  useEffect(() => {
    fetchStoreType();
  }, []);

  useEffect(() => {
    if (history.location.state && history.location.state.previous) {
      setIsSetting(true);
    }
  }, [history.location.state]);

  return (
    <>
      <Header />
      <div className="new-body-padding-with-footer">
        {isLoading ? (
          <div className="loading-symbol-wrapper">
            <CircularProgress />
          </div>
        ) : (
          <div className="store-type-page-wrapper">
            <CustomizedSnackbars
              message={snackbar.snackbarMessage}
              snackbarOpen={snackbar.snackbarOpen}
              snackbarClose={() =>
                setSnackbar({
                  ...snackbar,
                  snackbarOpen: false,
                  snackbarSeverity: snackbar.snackbarSeverity,
                })
              }
              severity={snackbar.snackbarSeverity}
            />
            <div className="header-section">
              <Link
                to={
                  isSetting
                    ? "/kds/settings"
                    : "/kds/settings/paymentmethod?merchantId=" +
                      query.get("merchantId")
                }
              >
                <IoArrowBackSharp className="backbutton-styling"></IoArrowBackSharp>
              </Link>
              <label className="orderhome-orderinfo-extralarge">
                {translate("store-type-page.title")}
              </label>
            </div>
            <div className="body-section">
              <label className="store-type-selection-label">
                {translate("store-type-page.select-type-label")}
              </label>
              <div className="store-type-button-section">
                {storeTypeList.map((o) => (
                  <div
                    className={`each-store-type${
                      storeType.includes(o) ? " selected" : ""
                    }`}
                    onClick={() => handleClickStoreType(o)}
                  >
                    <Checkbox
                      checked={storeType.includes(o)}
                      disabled={isMultistore}
                    />
                    <label>
                      {translate(
                        o === "PickUp"
                          ? "store-type-page.pick-up"
                          : o === "Delivery"
                          ? "store-type-page.delivery"
                          : o === "DineIn"
                          ? "store-type-page.dine-in"
                          : ""
                      )}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {!isMultistore && (
              <div className="footer-section">
                <LoadingButton
                  className="save-button"
                  onClick={() => checkifStoreTypeEmpty()}
                  loading={isApiProcessing}
                  loadingPosition="start"
                  variant={isApiProcessing ? "outlined" : "contained"}
                >
                  {isSetting
                    ? translate("settings-home-page.save")
                    : translate("settings-home-page.next")}
                </LoadingButton>
              </div>
            )}
          </div>
        )}
      </div>
      {isSetting && <Footer page={"setting"}></Footer>}
    </>
  );
}

export default StoreTypePage;
