import { useContext, useMemo } from "react";
import { MerchantContext } from "../../../context/MerchantContext";

const useCheckMultistoreUser = (debugFlipValue = false) => {
  const { storeType } = useContext(MerchantContext);
  const isMultistore = useMemo(() => {
    if (debugFlipValue) {
      return storeType !== "multiOffline";
    } else {
      return storeType === "multiOffline";
    }
  }, [storeType, debugFlipValue]);

  return { isMultistore };
};

export default useCheckMultistoreUser;
