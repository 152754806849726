import * as Yup from "yup";

Yup.addMethod(Yup.array, "unique", function(message, mapper = (a) => a) {
  return this.test("unique", message, function(list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

/*Frontend validation schema for product detail screen */
export const validateProductDetailSchema = Yup.object().shape({
  title: Yup.string().required("Product title is required"),
  deliveryPrice: Yup.number().moreThan(0, "Product price must be more than 0").typeError("Product price must be a number").required("Product price is required"),
  deliveryPriceWithTax: 
    Yup.number().typeError("Product price must be a number").required("Product price is required").when("productUOMs", {
      is: (productUOMs) => productUOMs[0].isProductTaxable,
      then: Yup.number().moreThan(0, "Product price must be more than 0")
    }),
});
