import React, { useState, useContext } from 'react';
import logo from '../../assets/images/ewarung.png';
import { Link } from 'react-router-dom';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LanguageRounded from "@mui/icons-material/LanguageRounded";
import { useTranslate } from "react-polyglot";
import { setLocale } from "../../localization/localization";
import { ACTIONS, appState } from "../../states/appState";
import { useGlobalState } from "../../states/globalState";
import { LANGUAGE_OPTION } from "../../constants/languageOption";
import { useHistory} from 'react-router-dom';
import { MerchantContext } from '../../context/MerchantContext';

function Header({page}) {
    const ITEM_HEIGHT = 48;
    const translate = useTranslate();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const merchantInfoContext = useContext(MerchantContext);
    const fromMobileApp = localStorage.getItem('fromMobileApp')
    const hideHeader = fromMobileApp && fromMobileApp !== "IS_MOBILE_APP" ? true : false
    const open = anchorEl;
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const [lang, dispatch] = useGlobalState(appState, (state) => state.locale);
      const handleClose = (language) => {
        if(language){ 
          window.location.reload();
          setLocale(language);
          dispatch(ACTIONS.onLanguageChange, language);
        }
        else {
            setAnchorEl(null);
        }
      };
    

    if(page==="login") {
         return (
            <div className='ewarung-header'>
                <img className='ewarung-logo' src={logo} />
                <div className='push-right'>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}        
                  >
                    <LanguageRounded sx={{color: "#e5e5e5"}}/>
                  </IconButton>
                  <Menu
                    onClose={() => setAnchorEl(null)}
                    anchorEl={anchorEl}
                    id="long-menu"
                    open={open}
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    <MenuItem
                        onClick={() => handleClose("en")}
                        selected={localStorage.getItem("locale")==="en"}
                      >
                        English
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleClose("bm")}
                        selected={localStorage.getItem("locale")==="bm"}
                      >
                        Bahasa Melayu
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleClose("zh")}
                        selected={localStorage.getItem("locale")==="zh"}
                      >
                        中文
                    </MenuItem>
                  </Menu>
                </div>
            </div>
        );
    } else if (hideHeader) {
      return (
        <></>
      )
    } else {
        return (
            <div className='ewarung-header'>
                <img className='ewarung-logo' src={logo} onClick={()=>{
                  if (merchantInfoContext.storeSetupStatus !== "pendingMerchantSetup"){
                    history.push("/kds/orders")
                  }
                  }} />
            </div>
        ); 
    }
}

export default Header;