import * as Yup from "yup";
import moment from "moment";

export const validateStoreOperatingHour = Yup.array().of(
  Yup.object().shape({
    open: Yup.string()
      .test("not empty", "Start time cant be empty", function (value) {
        return !!value;
      }),
      // .test("open", "Start time must be before end time", function (value) {
      //   const { close } = this.parent;
      //   return isSameOrBefore(value, close);
      // }),
    close: Yup.string(),
  })
);

const isSameOrBefore = (startTime, endTime) => {
  if(startTime==="Open Full Day"||startTime==="Closed Full Day") {
     return moment("12:00 am", "hh:mm A").isSameOrBefore(
        moment("11:59 pm", "hh:mm A")
      );
  } else {
    return moment(startTime, "hh:mm A").isSameOrBefore(
      moment(endTime, "hh:mm A")
    );
  }
};
