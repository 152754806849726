import { useState, useEffect, useContext } from "react";
import styles from "./RegisterOnboard.module.scss";
import { useHistory } from "react-router-dom";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { validator } from "../../utils";
import { CustomizedSnackbars } from "../../components/shareable/Shareable";
import { IoArrowBackSharp } from "react-icons/io5";
import Header from "../../components/header/Header";
import { LoadingButton } from "@mui/lab";
import { MerchantContext } from "../../context/MerchantContext";
import { useTranslate } from "react-polyglot";
import {
  Typography,
  InputBase,
  Button,
  Paper,
  CircularProgress,
  TextField,
  Box,
} from "@mui/material";
import { updateMerchantKYC } from "../../graphql/mutations";
import { adminGetMerchantGeneralConfig } from "../../graphql/queries";
import { useQuery } from "../../hooks";
import useCheckMultistoreUser from "../setting-module/compnents/useCheckMultistoreUser";

function RegisterOnboard(props) {
  const translate = useTranslate();
  const merchantInfoContext = useContext(MerchantContext);
  let history = useHistory();
  let query = useQuery();
  const [onBoardUserData, setOnBoardUserData] = useState({
    name: "",
    ic: "",
    businessAddress: "",
    email: "",
  });
  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [dataSaveLoading, setDataSaveLoading] = useState(false);
  const { isMultistore } = useCheckMultistoreUser();

  const handleChange = (e) => {
    setOnBoardUserData({
      ...onBoardUserData,
      [e.target.name]: e.target.value,
    });
  };

  const getMerchantOnboardData = async () => {
    setIsLoading(true);
    try {
      const merchantOnboardDataResp = await API.graphql(
        graphqlOperation(adminGetMerchantGeneralConfig)
      );

      const merchantOnboardData =
        merchantOnboardDataResp.data.adminGetMerchantGeneralConfig;

      setOnBoardUserData({
        ...onBoardUserData,
        name: merchantOnboardData.kycNRICName,
        ic: "",
        businessAddress: merchantOnboardData.kycBusinessAddress,
        email:
          merchantOnboardData.ownerEmail !== "default@email.com"
            ? merchantOnboardData.ownerEmail
            : "",
      });
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setIsLoading(false);
    }
  };

  const saveOnBoardUserData = async () => {
    setDataSaveLoading(true);
    if (
      onBoardUserData.name === "" &&
      onBoardUserData.ic === "" &&
      onBoardUserData.businessAddress === ""
    ) {
      setDataSaveLoading(false);
      setSnackbar({
        snackbarMessage: "Please fill in all required fields.",
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    } else {
      try {
        let params = {
          businessAddress: onBoardUserData.businessAddress,
          email: onBoardUserData.email,
          ic: onBoardUserData.ic,
          name: onBoardUserData.name,
        };

        const respOrderFulfillment = await API.graphql(
          graphqlOperation(updateMerchantKYC, params)
        );
        if (respOrderFulfillment.data.updateMerchantKYC.status === "true") {
          setDataSaveLoading(false);
          if (!isMultistore) {
            if (
              merchantInfoContext.storeSetupStatus === "pendingMerchantSetup"
            ) {
              history.replace(
                "/kds/settings/selectlanguage?merchantId=" +
                  query.get("merchantId")
              );
            } else if (
              merchantInfoContext.storeSetupStatus ===
              "pendingMerchantAcknowledge"
            ) {
              history.replace("/kds/professional-acknowledge");
            } else if (
              merchantInfoContext.storeSetupStatus === "pendingAxrailSetup"
            ) {
              history.replace("/kds/settings");
            } else {
              history.replace("/kds/orders");
            }
          } else {
            history.replace("/kds/orders");
          }
          setSnackbar({
            snackbarMessage:
              respOrderFulfillment.data.updateMerchantKYC.message,
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
        }
        setDataSaveLoading(false);
      } catch (error) {
        console.log(error);
        setDataSaveLoading(false);
      }
    }
  };

  useEffect(() => {
    // window.history.forward();
    getMerchantOnboardData();
  }, []);

  return (
    <div>
      <Header></Header>
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      <div className={styles.centeronboardscreen}>
        {isLoading ? (
          <Box className="center-loader" sx={{ display: "flex" }}>
            <CircularProgress centered />
          </Box>
        ) : (
          <div className={styles.containerwrapper}>
            <h1 className={styles.onboardtitle}>
              {translate("onboarding-screen.register-title")}
            </h1>
            <Typography
              style={{ textAlign: "center" }}
              color="textSecondary"
              gutterBottom
            >
              <small>{translate("onboarding-screen.register-text")}</small>
            </Typography>
            <TextField
              label={translate("onboarding-screen.register-name")}
              type="text"
              value={onBoardUserData.name}
              onChange={(e) => handleChange(e)}
              variant="outlined"
              className="sign-in-input-main-theme"
              name="name"
            />
            <TextField
              label={translate("onboarding-screen.register-ic")}
              type="number"
              value={onBoardUserData.ic}
              onChange={(e) => handleChange(e)}
              variant="outlined"
              className="sign-in-input-main-theme"
              name="ic"
            />
            <TextField
              label={translate("onboarding-screen.register-business-address")}
              type="text"
              value={onBoardUserData.businessAddress}
              onChange={(e) => handleChange(e)}
              variant="outlined"
              className="sign-in-input-main-theme"
              name="businessAddress"
            />
            <TextField
              label={translate("onboarding-screen.register-email")}
              type="email"
              value={onBoardUserData.email}
              onChange={(e) => handleChange(e)}
              variant="outlined"
              className="sign-in-input-main-theme"
              inputProps={{ pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$" }}
              name="email"
            />

            <label className={styles.onboardingrequiredfield}>
              {translate("onboarding-screen.register-required-field")}
            </label>
            <div
              className="orderview-single-button-box"
              style={{ marginTop: "20px" }}
            >
              <LoadingButton
                className="orderview-confirm-button"
                onClick={() => saveOnBoardUserData()}
                loading={dataSaveLoading}
                loadingPosition="start"
                disabled={dataSaveLoading}
              >
                {translate("onboarding-screen.register-save")}
              </LoadingButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RegisterOnboard;
