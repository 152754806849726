import { GoogleMap, Marker } from "@react-google-maps/api";
import style from "./RiderLocationMap.module.scss";
import bikePNG from "../../../../assets/icons/motorbike.png";
import storePNG from "../../../../assets/icons/store.png";

const RiderLocationMap = ({
  markers = []
}) => {

const mapStyles = [
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];

const handleOnLoad = (map) => {
  const bounds = new window.google.maps.LatLngBounds();
  markers.forEach(({ position }) => bounds.extend(position));
  map.fitBounds(bounds);
};

return (
    <div className={style["google-map-section"]}>
      <GoogleMap
        onLoad={handleOnLoad}
        mapContainerClassName={style["google-map"]}
        center={markers.find((o) => o.name === "rider").position}
        options={{
          disableDefaultUI: true,
          gestureHandling: "greedy",
          styles: mapStyles,
        }}
      >
        {
          markers.map(({name, position}, i) => 
            <Marker
              key={i}
              position={position}
              icon={
                name === "rider"
                ? bikePNG
                : storePNG
              }
            />
          )
        }
      </GoogleMap>
    </div>
)};

export default RiderLocationMap;