import { useMemo } from 'react';
import { Snackbar, Alert, InputBase, Switch } from '@mui/material';
import { Search } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { AiOutlineLoading, AiOutlineInfoCircle } from 'react-icons/ai'
import _ from "lodash";
import "./Shareable.scss";

const LoadingIcon = () => {
  return (
      <AiOutlineLoading className='loading-icon'/>
  )
}

export const CustomizedSnackbars = ({
  message,
  snackbarOpen,
  snackbarClose,
  autoHideDuration = 6000,
  severity = "success",
  verticalInput = "top",
  horizontalInput = "center",
  isDownload = false,
}) => {
  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    snackbarClose(false);
  };

  const snackbarProps = {
    // disables auto hide for download orders
    ...(!isDownload && { autoHideDuration: autoHideDuration})
  }

  return (
    <Snackbar
      open={snackbarOpen}
      {...snackbarProps}
      onClose={handleClose}
      anchorOrigin={{ vertical: verticalInput, horizontal: horizontalInput }}
    >
      <Alert 
        iconMapping={{info: isDownload ? <LoadingIcon /> : <AiOutlineInfoCircle />}} 
        elevation={6} 
        variant="filled" 
        onClose={handleClose} 
        severity={severity}
        sx={{
          backgroundColor: severity === "success"
                            ? "#2E7D32 !important"
                            : severity === "error"
                              ? "#D32F2F !important"
                              : severity === "info"
                                ? "#0288D1 !important"
                                : severity === "warning"
                                  && "#ED6C02 !important",
          color: severity === "warning" ? "#000 !important" : "#FFF !important"
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export const SearchBar = ({
  onChange,
  placeholder
}) => {
  const debouncedOnChange = useMemo(() => {
    return _.debounce(onChange, 800);
  }, []);
  
  return (
    <div className="search-bar">
      <InputBase
        className="search-bar-input"
        onChange={debouncedOnChange}
        placeholder={placeholder}
      />
      <label className="search-bar-logo">
        <Search className="logo" />
      </label>
    </div>
  );
};

export const CustomSwitch = styled((props) => (
  <Switch disableRipple focusVisibleClassName=".Mui-focusVisible" {...props} />
))(({ theme }) => ({
  width: "50px",
  height: "24px",
  padding: "0px",
  "& .MuiSwitch-switchBase": {
    color: "#818181",
    padding: "1px",
    "&.Mui-disabled": {
      "& + $track": {
        backgroundColor: "#B84131",
      },
    },
    "&.Mui-checked": {
      color: "#23bf58 !important",
      transform: "translateX(26px) !important",
      "& + .MuiSwitch-track": {
        backgroundColor: "#7EC480",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    width: "20px",
    height: "20px",
    margin: "1px",
  },
  "& .MuiSwitch-track": {
    borderRadius: "20px",
    backgroundColor: "#B84131",
    opacity: "1 !important",
  },
}));
  