import React, { useEffect, useState, useContext } from "react";
import Header from "../../components/header/Header";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslate } from "react-polyglot";
import { CustomizedSnackbars } from "../../components/shareable/Shareable";
import { useHistory } from "react-router-dom";
// AWS apis
import { API, graphqlOperation, Auth } from "aws-amplify";
import { getPendingProfessionalServicesMerchantList } from "../../graphql/queries";
import { updateOnSiteAdminMerchantId } from "../../graphql/mutations";

import { MerchantContext } from "../../context/MerchantContext";

function SelectMerchant(props) {
  const translate = useTranslate();
  const [merchantData, setMerchantData] = useState([]);
  const history = useHistory();
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const merchantInfoContext = useContext(MerchantContext);
  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });

  const fetchMerchantData = async () => {
    setDataIsLoading(true);
    try {
      let res = await API.graphql(
        graphqlOperation(getPendingProfessionalServicesMerchantList)
      );
      setMerchantData(
        res.data.getPendingProfessionalServicesMerchantList.merchantList
      );
      setDataIsLoading(false);
    } catch (error) {
      setDataIsLoading(false);
    }
  };

  const goNextPage = async (merchantObj) => {
    try {
      let { data } = await API.graphql(
        graphqlOperation(updateOnSiteAdminMerchantId, {
          merchantId: merchantObj.merchantId,
        })
      );
      if (data?.updateOnSiteAdminMerchantId.status === "true") {
        merchantInfoContext.setMerchantId(merchantObj.merchantId);
        merchantInfoContext.setTax(merchantObj.tax);
        history.push(`/kds/settings/update-business-detail`);
        setDataIsLoading(false);
      } else {
        setSnackbar({
          snackbarMessage: data?.updateOnSiteAdminMerchantId.message,
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
        setDataIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setDataIsLoading(false);
    }
  };

  const [openLogOutModal, setOpenLogOutModal] = useState(false);
  const handleSignout = async () => {
    try {
      await Auth.signOut();
      localStorage.clear();
      history.push("/login");
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenLogOutModal = () => {
    setOpenLogOutModal(true);
  };

  const handleCloseLogOutModal = () => {
    setOpenLogOutModal(false);
  };

  const renderLogOutModal = () => {
    return (
      <div>
        <Dialog open={openLogOutModal} onClose={handleCloseLogOutModal}>
          <DialogTitle
            className="orderhome-orderinfo-large"
            id="alert-dialog-title"
          >
            {translate("settings-home-page.areyousuretologout")}
          </DialogTitle>
          <DialogActions>
            <Button
              className="orderhome-orderinfo-no"
              onClick={handleCloseLogOutModal}
            >
              {translate("settings-home-page.disagree")}
            </Button>
            <Button
              className="orderhome-orderinfo-yes"
              onClick={() => handleSignout()}
              autoFocus
            >
              {translate("settings-home-page.agree")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  useEffect(() => {
    fetchMerchantData();
  }, []);

  const renderMerchantOptions = () => {
    if (dataIsLoading) {
      return (
        <Box className="center-loader" sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      );
    } else {
      return merchantData.map((merchantObj) => {
        return (
          <React.Fragment>
            <div
              key={merchantObj.merchantId}
              className="orderhome-orderinfo-pickup"
              onClick={() => goNextPage(merchantObj)}
            >
              <div className="orderhome-order-box">
                <div>
                  <h1 className="orderhome-orderinfo-large">
                    {merchantObj.name}
                  </h1>
                  <h1 className="orderhome-orderinfo-small-2">
                    {merchantObj.ownerName +
                      " " +
                      "(" +
                      merchantObj.ownerPhone +
                      ")"}
                  </h1>
                  <h1 className="orderhome-orderinfo-small-2">
                    {merchantObj.address}
                  </h1>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      });
    }
  };

  return (
    <div>
      {renderLogOutModal()}
      <Header page={"setting"}></Header>
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      <div className="body body-bg body-padding">
        <div className="current-store-page-wrapper">
          <h1 className="orderhome-orderinfo-extralarge">
            {translate("onboarding-screen.merchant-list")}
          </h1>
          <h1 className="orderhome-orderinfo-small">
            {translate("onboarding-screen.choose-merchant-set-up")}
          </h1>
          {renderMerchantOptions()}
          <div style={{ marginTop: "16px" }}>
            <LoadingButton
              className="orderview-confirm-button"
              onClick={() => handleOpenLogOutModal()}
              loading={dataIsLoading}
              loadingPosition="start"
              disabled={dataIsLoading}
              variant={dataIsLoading ? "outlined" : "contained"}
            >
              Log Out
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectMerchant;
