import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import aws_exports from "./aws-exports";
import Amplify, { Auth } from "aws-amplify";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (!window.location.hostname.includes("localhost") && !window.location.hostname.includes(".vfs.cloud9.ap-southeast-1.amazonaws.com")) {
  aws_exports.API = {
    graphql_endpoint: 'https://ecomapi.ewarung.shop/graphql',
    graphql_headers: async () => {
      let session = await Auth.currentSession()
      return {
        'X-Frame-Options': 'SAMEORIGIN',
        'Content-Security-Policy': 'default-src self',
        Authorization: session.accessToken.jwtToken
      }
    }
  }
}

Amplify.configure(aws_exports);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: "production",
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
