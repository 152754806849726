import { getLocale } from "../localization/localization";
import { GlobalState } from "./globalState";

export const ACTIONS = Object.freeze({
  onLanguageChange: "onLanguageChange",
  onOrderTypeChange: "onOrderTypeChange"
});

const locale = getLocale();

function reducer(state, actionName, payload) {
  switch (actionName) {
    case ACTIONS.onLanguageChange:
      return {
        ...state,
        locale: payload || getLocale(),
      };
    case ACTIONS.onOrderTypeChange:
      return {
        ...state,
        ordertype: payload || getLocale(),
      };
    default:
      throw new Error(`Action does not exist: ${actionName}`);
  }
}

export const appState = new GlobalState(reducer, { locale });
