import { useState,useContext,useEffect } from "react";
import {useHistory} from 'react-router-dom';
import styles from "./PackageOffer.module.scss";
import Header from '../../components/header/Header';
import { MerchantContext } from "../../context/MerchantContext";
import { useTranslate } from "react-polyglot";
import image from '../../assets/images/professionalimg.png';
import {
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  CheckLabel,
  Checkbox
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { CustomizedSnackbars } from '../../components/shareable/Shareable';

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import {
    checkMerchantSetupStatus,
} from "../../graphql/queries";
import { updateStoreSetupStatus } from '../../graphql/mutations';

function PackageOffer(props) {
    const translate = useTranslate();
    let history = useHistory();
    const [storeIsLoading,setStoreIsLoading] = useState(false);
    const merchantInfoContext = useContext(MerchantContext);
    const [storeData, setStoreData] = useState("");
    const [checked1,setChecked1] = useState(false);
    const [checked2,setChecked2] = useState(false);
    const [checked3,setChecked3] = useState(false);
    const [checked4,setChecked4] = useState(false);
    const [checked5,setChecked5] = useState(false);
    const [checked6,setChecked6] = useState(false);
    const [checked7,setChecked7] = useState(false);
    const [checked8,setChecked8] = useState(false);
    const [dataSaveLoading,setDataSaveLoading] = useState(false)
    const handleChange1 = (event) => {
        setChecked1(event.target.checked);
      };
    const handleChange2 = (event) => {
        setChecked2(event.target.checked);
      };
    const handleChange3 = (event) => {
        setChecked3(event.target.checked);
      };
    const handleChange4 = (event) => {
        setChecked4(event.target.checked);
      };
    const handleChange5 = (event) => {
        setChecked5(event.target.checked);
      };
    const handleChange6 = (event) => {
        setChecked6(event.target.checked);
      };
    const handleChange7 = (event) => {
        setChecked7(event.target.checked);
      };
    const handleChange8 = (event) => {
        setChecked8(event.target.checked);
      };
    
    const [snackbar, setSnackbar] = useState({
      snackbarMessage: "",
      snackbarOpen: false,
      snackbarSeverity: "info",
    });
    
    const goToNextPage = async() => {
        if(checked1&&checked2&&checked3&&checked4&&checked5&&checked6&&checked7&&checked8){
            try {
                let params = {
                   acknowledgement: true
                };
                
                const respOrderFulfillment = await API.graphql(
                  graphqlOperation(updateStoreSetupStatus, params)
                );
                if (respOrderFulfillment.data.updateStoreSetupStatus.status === "true") {
                    setDataSaveLoading(false);
                    history.replace("/kds/settings")
                } else {
                  setSnackbar({
                    snackbarMessage: respOrderFulfillment.data.updateStoreSetupStatus.message,
                    snackbarOpen: true,
                    snackbarSeverity: "error",
                  });
                }
                setDataSaveLoading(false);
              } catch (error) {
                console.log(error)
                setDataSaveLoading(false);
              }
            
        } else {
            setSnackbar({
                snackbarMessage: translate("onboarding-screen.check-before-continue"),
                snackbarOpen: true,
                snackbarSeverity: "error",
              });
        }
    }
    
    const goToSettingsPage = async() => {
            try {
                let params = {
                   acknowledgement: false
                };
                
                const respOrderFulfillment = await API.graphql(
                  graphqlOperation(updateStoreSetupStatus, params)
                );
                if (respOrderFulfillment.data.updateStoreSetupStatus.status === "true") {
                    setDataSaveLoading(false);
                    history.replace("/kds/settings")
                } else {
                  setSnackbar({
                    snackbarMessage: respOrderFulfillment.data.updateStoreSetupStatus.message,
                    snackbarOpen: true,
                    snackbarSeverity: "error",
                  });
                }
                setDataSaveLoading(false);
              } catch (error) {
                console.log(error)
                setDataSaveLoading(false);
              }
    }
    
    
    
    const redirectToMerchantDomain = () => {
        const url = "https://" + merchantInfoContext.merchantDomain;
        const a = document.createElement("a");
        a.href = url;
        a.setAttribute("target", "_blank");
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener("click", clickHandler);
          }, 150);
        };
        a.addEventListener("click", clickHandler, false);
        a.click();
        return a;
    }

    
    return (
        <div>
            <Header></Header>
            <CustomizedSnackbars
              message={snackbar.snackbarMessage}
              snackbarOpen={snackbar.snackbarOpen}
              snackbarClose={() =>
                  setSnackbar({
                  ...snackbar,
                  snackbarOpen: false,
                  snackbarSeverity: snackbar.snackbarSeverity,
                  })
              }
              severity={snackbar.snackbarSeverity}
          />
            <div className="new-body-padding-with-footer">
                <div>
                    <h1 className={styles.packageoffertitle}>{translate("onboarding-screen.professional-title")}</h1>
                    <Typography style={{textAlign:"center"}} color="textSecondary" gutterBottom>
                        <small className={styles.packageoffertext}>
                          {translate("onboarding-screen.professional-detail")}
                        </small>
                    </Typography>
                    
                    <FormGroup style={{marginTop:"15px"}}>
                        <FormControlLabel 
                            control={
                                <Checkbox  
                                    checked={checked1} 
                                    onChange={handleChange1}/>} 
                                    label={translate("onboarding-screen.check1")} />
                        <FormControlLabel 
                            control={
                                <Checkbox  
                                    checked={checked2} 
                                    onChange={handleChange2}/>} 
                                    label={translate("onboarding-screen.check2")} />
                        <FormControlLabel 
                            control={
                                <Checkbox  
                                    checked={checked3} 
                                    onChange={handleChange3}/>} 
                                    label={translate("onboarding-screen.check3")} />
                        <FormControlLabel 
                            control={
                                <Checkbox  
                                    checked={checked4} 
                                    onChange={handleChange4}/>} 
                                    label={translate("onboarding-screen.check4")} />
                        <FormControlLabel 
                            control={
                                <Checkbox  
                                    checked={checked5} 
                                    onChange={handleChange5}/>} 
                                    label={translate("onboarding-screen.check5")} />
                        <FormControlLabel 
                            control={
                                <Checkbox  
                                    checked={checked6} 
                                    onChange={handleChange6}/>} 
                                    label={translate("onboarding-screen.check6")} />
                        <FormControlLabel 
                            control={
                                <Checkbox  
                                    checked={checked7} 
                                    onChange={handleChange7}/>} 
                                    label={translate("onboarding-screen.check7")} />
                        <FormControlLabel 
                            control={
                                <Checkbox  
                                    checked={checked8} 
                                    onChange={handleChange8}/>} 
                                    label={translate("onboarding-screen.iackonwledge")} />
                    </FormGroup>
                    
                    <p onClick={()=>redirectToMerchantDomain()} style={{textAlign:"center",marginBottom:"5px",marginTop:"15px"}} className="ordercomplete-link">{translate("onboarding-screen.check-out-my-new-store")}</p>
                    
                    <div className={styles.packageoffersinglebuttonbox} style={{marginTop:"20px"}}>
                        <button
                            className={styles.packageofferconfirmbutton}
                            onClick={()=>goToNextPage()}
                          >
                        {translate("onboarding-screen.proceed")}
                      </button>
                    </div>
                    <div className={styles.packageoffersinglebuttonbox} style={{marginTop:"0px"}}>
                        <button
                            className={styles.packageofferrejectbutton}
                            onClick={()=>goToSettingsPage()}
                          >
                        {translate("onboarding-screen.setup-not-done-yet")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PackageOffer;