import { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import "./LinkQR.scss";
import Header from '../../components/header/Header';
import { useTranslate } from "react-polyglot";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  CircularProgress
} from '@mui/material';
import { CustomizedSnackbars, SearchBar } from '../../components/shareable/Shareable';
import {IoArrowBackSharp} from 'react-icons/io5';
import { BottomLayout } from '../common/layout/Layout';
import { CustomButton } from "../../components/buttons/Buttons";
import { SHARED_ERROR_MESSAGE } from "../../constants";

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import { 
  qrCodeShowOnSite
} from '../../graphql/queries';
import { 
  updateMerchantDomainOnSite
} from '../../graphql/mutations';


function LinkQR(props) {
    const translate = useTranslate();
    const history = useHistory();
    
    const [snackbar, setSnackbar] = useState({
      snackbarMessage: "",
      snackbarOpen: false,
      snackbarSeverity: "info",
    });
    
    const renderStyle = (qr) => {
      if(qr===selectedQR?.printNumber) {
        return {
				  backgroundColor: "#ddebf8",
          border: "2px solid #5cb4e7",
          color: "#47abe5"
			  };
      } else {
        return {
				  backgroundColor: "#fff",
          border: "1px solid #a0aec0",
          color: "#a0aec0"
			  };
      }
    }
    
    const [QRList, setQRList] = useState([]);
    // confirm selected qr code dialog usage
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedQR, setSelectedQR] = useState();
    const handleCloseConfirmDialog = () => {
      setSelectedQR("");
      setOpenConfirmDialog(false);
    };
    const [isApiProcessing, setIsApiProcessing] = useState(false);
    const handleLinkQR = async () => {
      let params = {
        printedQrCodeId: selectedQR.printedQrCodeId,
        webQrCode: selectedQR.webQrCode,
      }
      try {
        setIsApiProcessing(true);
        let res = await API.graphql(graphqlOperation(updateMerchantDomainOnSite, params));
        setSnackbar({
          snackbarMessage: 
            res.data.updateMerchantDomainOnSite.message === "Successfully update merchant domain!"
            ? translate("onboarding-screen.update-merchant-domain-success")
            : res.data.updateMerchantDomainOnSite.message === "Merchant already been assigned QR!"
            ? translate("onboarding-screen.merchant-already-assigned-qr")
            : res.data.updateMerchantDomainOnSite.message,
          snackbarOpen: true,
          snackbarSeverity: res.data.updateMerchantDomainOnSite.status === "true" ? "success" : "error",
        });
        
        setIsApiProcessing(false);
        handleCloseConfirmDialog();
        if(res.data.updateMerchantDomainOnSite.status === "true") {
          history.push("/kds/settings/store-information");
        };
      } catch (error) {
        setIsApiProcessing(false);
        setSnackbar({
          snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
      }
    };
    const handleConfirmSelectedQR = () => {
      if(!selectedQR?.printNumber) {
        history.push("/kds/settings/store-information");
      } else {
        setOpenConfirmDialog(true);
      }
    };
    
    const handleOnSearch = (e) => {
      if (e.target.value.length > 0 && e.target.value) {
        let searchKeyword = e.target.value.toLowerCase();
        fetchQRList(searchKeyword);
      }
    };
    
    const [isLoading, setIsLoading] = useState(false);
    const fetchQRList = async (keyword) => {
      try {
        setIsLoading(true);
        let params = {
          filterPrintNumber: keyword,
        };
        let res = await API.graphql(graphqlOperation(qrCodeShowOnSite, params));
        
        if(res.data.qrCodeShowOnSite.status) {
          setQRList(res.data.qrCodeShowOnSite.qrCodeList ? res.data.qrCodeShowOnSite.qrCodeList : []);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setSnackbar({
          snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
      }
    };
    
    return (
        <>
            <Header />
            <CustomizedSnackbars
              message={snackbar.snackbarMessage}
              snackbarOpen={snackbar.snackbarOpen}
              snackbarClose={() =>
                  setSnackbar({
                  ...snackbar,
                  snackbarOpen: false,
                  snackbarSeverity: snackbar.snackbarSeverity,
                  })
              }
              severity={snackbar.snackbarSeverity}
            />
            <div className="body body-bg body-padding link-qr-screen-wrapper">
              <Link to={"/kds/settings/update-business-detail"}>
                  <IoArrowBackSharp className='backbutton-styling'></IoArrowBackSharp>
              </Link>
              <h1 className="orderhome-orderinfo-extralarge">
                {translate("onboarding-screen.link-qr-title")}
              </h1>
              <label className="link-qr-description-label">
                {translate("onboarding-screen.link-qr-description")}
              </label>
              <SearchBar
                onChange={handleOnSearch}
                placeholder={translate("onboarding-screen.search-record-number")}
              />
              
              {isLoading ? 
                <div className="loading-symbol-wrapper">
                  <CircularProgress />
                </div>
                : QRList && QRList.length > 0 && 
                  <div className="qr-list">
                    {QRList.map((code, i) => 
                      <div key={i} className="qr-option" style={renderStyle(code.printNumber)} onClick={() => setSelectedQR(code)}>
                        <label className="number-label">
                          {code.printNumber}
                        </label>
                        <label className="domain-label">
                          {code.webQrCode}
                        </label>
                      </div>
                    )}
                  </div>
              }
              
              <Dialog
                open={openConfirmDialog}
                onClose={handleCloseConfirmDialog}
                className="confirm-dialog"
              >
                <DialogTitle id="alert-dialog-title">
                  {translate("onboarding-screen.confirm-selected-qr")}<b>{`${selectedQR?.printNumber}.`}</b>
                  <br />
                  <br />
                  {translate("onboarding-screen.click-confirm-to-proceed")}
                </DialogTitle>
                <DialogActions>
                  <CustomButton 
                      onClick={handleCloseConfirmDialog}
                      autoFocus
                      variant="contained"
                      color="error"
                  >
                    {translate("confirm-delete-dialog.cancel")}
                  </CustomButton>
                  <CustomButton 
                      onClick={handleLinkQR} 
                      variant="outlined"
                  >
                    {translate("confirm-delete-dialog.confirm")}
                  </CustomButton>
                </DialogActions>
              </Dialog>
            </div>
            <BottomLayout 
              title={translate("order-listing-screen.next")}
              handleRedirect={handleConfirmSelectedQR}
              isLoading={isApiProcessing}
            />
        </>
    );
}

export default LinkQR;
