import { InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import "./CustomInputBase.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "DM Sans",
  },
}));

const CustomInputBase = (props) => {
  const {
    className,
    borderAttached = false,
    ...rest
  } = props;

  const classes = useStyles();

  return (
      <div className="textinput-wrapper">
        <div
          className={`grow-1-w-100 ${borderAttached ? "textinput-border" : ""} ${className ? className : ""}`}
        >
          <InputBase
            className={`textinput`}
            classes={classes}
            // InputProps={{
            //   disableUnderline: true,
            // }}
            {...rest}
          />
        </div>
        </div>
  );
};

export {
  CustomInputBase,
};