import { createContext } from "react";

export const MerchantContext = createContext({
  merchantId: "",
  setMerchantId: () => {},
  merchantDomain: "",
  setMerchantDomain: () => {},
  currency: "",
  tax: "",
  setTax: () => {},
  storeSetupStatus: "",
  collectKYCInfo: "",
  packageAddon: null,
  role: "",
  storeId: "",
  setStoreId: () => {},
  referralPartner: "",
  preferredLanguage: "en",
  isFreeTrial: "",
  subscriptionExpiryDate: "",
  setStoreType: "",
});
