import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { IoArrowBackSharp } from "react-icons/io5";
import { useTranslate } from "react-polyglot";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { CustomLoadingButton } from "../../../components/buttons/Buttons";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { CustomizedSnackbars } from "../../../components/shareable/Shareable";
import { MerchantContext } from "../../../context/MerchantContext";

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import {
  kdsUpdateStoreHours,
  updateStoreSetupStatus
} from "../../../graphql/mutations";
import {
  adminGetMerchantGeneralConfig,
  adminGetStore
} from "../../../graphql/queries";

import {
  TIME_CLOSE_OPTION, TIME_OPEN_OPTION
} from "../../../constants/timeOption";

import { Grid } from "@mui/material";

import { Form, Formik } from "formik";
import moment from "moment/moment";
import { useQuery } from "../../../hooks/useQuery";
import { validateStoreOperatingHour } from "../../../validation/validateStoreOperatingHour";
import useCheckMultistoreUser from "../compnents/useCheckMultistoreUser";
import styles from "./StoreOperatingHours.module.scss";

const DateTimeInput = ({
  handleOnChange,
  index,
  storeIsLoading,
  daysOperatingHour,
  errorMessage,
  disabled = false,
  formikProps,
}) => {
  const translate = useTranslate();
  const getDay = (day) => {
    switch (day) {
      case "Sunday":
        return translate("store-operating-hours-page.sunday");

      case "Monday":
        return translate("store-operating-hours-page.monday");

      case "Tuesday":
        return translate("store-operating-hours-page.tuesday");

      case "Wednesday":
        return translate("store-operating-hours-page.wednesday");

      case "Thursday":
        return translate("store-operating-hours-page.thursday");

      case "Friday":
        return translate("store-operating-hours-page.friday");

      case "Saturday":
        return translate("store-operating-hours-page.saturday");
    }
  };

  const timeFormat = "h:mm a";

  const getValidatedOpenTime = (openTime, closeTime) => {
    const momentClose = moment(closeTime, timeFormat);
    const momentOpen = moment(openTime, timeFormat);

    if (
      moment(closeTime, "h:mm a").isBetween(
        moment("12:00 am", timeFormat),
        moment("12:00 pm", timeFormat)
      )
    ) {
      if (
        moment(closeTime, "h:mm a").isBetween(
          moment("12:00 am", timeFormat),
          momentOpen
        )
      ) {
        // if close morning (next day)

        return TIME_OPEN_OPTION.filter(
          (time) =>
            moment(time.value, timeFormat).isAfter(momentClose) ||
            time.value === "Closed Full Day"
        );
      } else {
        // if close morning (same day)
        return TIME_OPEN_OPTION;
      }
    } else if (closeTime === "12:00 am") {
      // if close on 12:00
      return TIME_OPEN_OPTION.filter(
        (time) =>
          moment(time.value, timeFormat).isAfter(momentClose) ||
          time.value === "Closed Full Day"
      );
    } else {
      return TIME_OPEN_OPTION;
    }
  };

  const getValidatedCloseTime = (openTime) => {
    return TIME_CLOSE_OPTION.filter((time) =>
      moment(time.value, timeFormat).isAfter(openTime)
    );
  };

  const getNextDayCloseTime = (nextOpenTimeMoment, nextOpenTime) => {
    return TIME_CLOSE_OPTION.filter((time) =>
      moment(time.value, timeFormat).isBefore(
        nextOpenTime === "Closed Full Day"
          ? moment("9:30 am", timeFormat).add(0, "day")
          : moment(nextOpenTimeMoment, timeFormat).add(0, "day")
      )
    );
  };

  const selectStyle = {
    menuList: (styles) => ({
      ...styles,
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingRight: "2px",
      paddingLeft: "2px",
    }),
    group: (styles) => {
      return {
        ...styles,
        paddingTop: "px",
        paddingBottom: "1px",
      };
    },
    option: (styles) => {
      return {
        ...styles,
        marginTop: "1px",
        marginBottom: "1px",
        borderRadius: "3px",
      };
    },
  };

  return (
    <div key={index}>
      <div className="store-opening-grid">
        <Grid item xs={12} className="storeopening-box">
          <h1
            className="storeopening-textlabel"
            id="outlined-basic"
            label=""
            variant="outlined"
          >
            {getDay(daysOperatingHour.day)}
          </h1>
        </Grid>
        <Grid item xs={12} className="storeopening-box">
          <Select
            options={getValidatedOpenTime(
              formikProps.values.at(index - 1).open,
              formikProps.values.at(index - 1).close
            )}
            value={{
              value: daysOperatingHour.open,
              label: daysOperatingHour.open,
            }}
            isDisabled={disabled}
            styles={selectStyle}
            type="select"
            name={`${daysOperatingHour.open}`}
            isSearchable={false}
            isLoading={storeIsLoading}
            onChange={({ value }) =>
              value === "Open Full Day" || value === "Closed Full Day"
                ? formikProps.setFieldValue(index, {
                    ...formikProps.values[index],
                    close: "",
                    open: value,
                  })
                : formikProps.setFieldValue(index, {
                    ...formikProps.values[index],
                    open: value,
                  })
            }
          />
        </Grid>
        <Grid item xs={12} className="storeopening-box">
          <Select
            options={[
              {
                label: "",
                options: getValidatedCloseTime(
                  moment(formikProps.values.at(index).open, timeFormat)
                ),
              },
              {
                label: "Next Day",
                options: getNextDayCloseTime(
                  moment(
                    formikProps.values.at(
                      index + 1 === formikProps.values.length ? 0 : index + 1
                    ).open,
                    timeFormat
                  ),
                  formikProps.values.at(
                    index + 1 === formikProps.values.length ? 0 : index + 1
                  ).open
                ),
              },
            ]}
            value={{
              value: daysOperatingHour.close,
              label: daysOperatingHour.close,
            }}
            styles={selectStyle}
            type="select"
            name={`${daysOperatingHour.close}`}
            isSearchable={false}
            isLoading={storeIsLoading}
            isDisabled={
              formikProps.values[index].open === "Open Full Day" ||
              formikProps.values[index].open === "Closed Full Day" ||
              disabled
            }
            onChange={({ value }) =>
              formikProps.setFieldValue(index, {
                ...formikProps.values[index],
                close: value,
              })
            }
          />
        </Grid>
      </div>
      {index in errorMessage &&
        errorMessage[index].open !== "Start time cant be empty" && (
          <label className={styles["error-message"]}>
            {errorMessage[index].open}
          </label>
        )}
    </div>
  );
};

function StoreOperatingHours(props) {
  const translate = useTranslate();
  const history = useHistory();
  const merchantInfoContext = useContext(MerchantContext);
  const [storeIsLoading, setStoreIsLoading] = useState(false);
  const [saveIsLoading, setShowLoading] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  let query = useQuery();
  const { isMultistore } = useCheckMultistoreUser();
  const { storeId } = useContext(MerchantContext);

  const DAY_OPTIONS = [
    { value: 0, label: translate("store-operating-hours-page.0-day") },
    { value: 1, label: translate("store-operating-hours-page.1-day") },
    { value: 2, label: translate("store-operating-hours-page.2-day") },
  ];

  const [storeOperatingHoursData, setStoreOperatingHoursData] = useState([
    { close: "", day: "Sunday", open: "" },
    { close: "", day: "Monday", open: "" },
    { close: "", day: "Tuesday", open: "" },
    { close: "", day: "Wednesday", open: "" },
    { close: "", day: "Thursday", open: "" },
    { close: "", day: "Friday", open: "" },
    { close: "", day: "Saturday", open: "" },
  ]);

  const [daysForAdvancedOrder, setDaysForAdvancedOrder] = useState(0);
  const [daysForAdvancedOrderLabel, setDaysForAdvancedOrderLabel] =
    useState("");

  const [checkedForAdvancedOrder, setCheckedForAdvancedOrder] = useState(false);

  const handleChange = (event) => {
    setCheckedForAdvancedOrder(event.target.checked);
  };

  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });

  const updateStoreOperatingHours = async (storeOperatingHoursData) => {
    setShowLoading(true);

    if (
      storeOperatingHoursData
        .map((e) => {
          if (e.open !== "Open Full Day" && e.open !== "Closed Full Day") {
            return e.close === "";
          }
        })
        .includes(true)
    ) {
      setShowLoading(false);
      setSnackbar({
        snackbarMessage: "Store closing time required if opening time is set",
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    } else {
      try {
        let params = {
          listOfStoreOpenCloseHours: storeOperatingHoursData.map((e) => {
            return {
              close: e.close,
              day: e.day,
              open: e.open,
            };
          }),
          maxAdvancedOrderDay: parseInt(daysForAdvancedOrder),
          advancedOrderEnabled: checkedForAdvancedOrder,
        };
        const respOrderFulfillment = await API.graphql(
          graphqlOperation(kdsUpdateStoreHours, params)
        );
        if (respOrderFulfillment.data.kdsUpdateStoreHours.status === "true") {
          setSnackbar({
            snackbarMessage:
              respOrderFulfillment.data.kdsUpdateStoreHours.message ===
              "Successfully updated store hours."
                ? respOrderFulfillment.data.kdsUpdateStoreHours.message
                : translate("store-operating-hours-page.update-success"),
            snackbarOpen: true,
            snackbarSeverity: "success",
          });
          if (!isSetting) {
            //click Next
            merchantFinishSetup();
          }
        } else {
          setSnackbar({
            snackbarMessage:
              respOrderFulfillment.data.kdsUpdateStoreHours.message,
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
        }
        setShowLoading(false);
      } catch (error) {
        console.log(error);
        setShowLoading(false);
      }
    }
  };

  const handleOnSubmit = (val) => {
    updateStoreOperatingHours(val);
  };

  const fetchStoreData = useCallback(async () => {
    setStoreIsLoading(true);
    try {
      let params = {
        merchantId: merchantInfoContext.merchantId,
      };

      let res = await API.graphql(
        graphqlOperation(adminGetMerchantGeneralConfig, params)
      );
      if (res.data.adminGetMerchantGeneralConfig.storeOpenCloseHours) {
        setStoreOperatingHoursData(
          res.data.adminGetMerchantGeneralConfig.storeOpenCloseHours
        );
      }
      if (res.data.adminGetMerchantGeneralConfig) {
        setDaysForAdvancedOrder(
          res.data.adminGetMerchantGeneralConfig.maxAdvancedOrderDay
        );
        setCheckedForAdvancedOrder(
          res.data.adminGetMerchantGeneralConfig.advancedOrderEnabled
        );
        res.data.adminGetMerchantGeneralConfig.maxAdvancedOrderDay === 0 ||
        res.data.adminGetMerchantGeneralConfig.maxAdvancedOrderDay == null
          ? setDaysForAdvancedOrderLabel(
              translate("store-operating-hours-page.0-day")
            )
          : res.data.adminGetMerchantGeneralConfig.maxAdvancedOrderDay === 1
          ? setDaysForAdvancedOrderLabel(
              translate("store-operating-hours-page.1-day")
            )
          : setDaysForAdvancedOrderLabel(
              translate("store-operating-hours-page.2-day")
            );
      }
      setStoreIsLoading(false);
    } catch (error) {
      setStoreIsLoading(false);
    }
  }, [merchantInfoContext.merchantId]);

  const merchantFinishSetup = async () => {
    setStoreIsLoading(true);
    try {
      let params = {
        acknowledgement: false,
      };

      const merchantSetupStatusResp = await API.graphql(
        graphqlOperation(updateStoreSetupStatus, params)
      );

      if (
        merchantSetupStatusResp.data.updateStoreSetupStatus.status === "true"
      ) {
        history.push("/kds/settings");
        window.location.reload();
      } else {
        setSnackbar({
          snackbarMessage:
            merchantSetupStatusResp.data.updateStoreSetupStatus.message,
          snackbarOpen: true,
          snackbarSeverity: "success",
        });
      }
      setStoreIsLoading(false);
    } catch (error) {
      console.log(error);
      setStoreIsLoading(false);
    }
  };

  const fetchAdminStoreData = async () => {
    setStoreIsLoading(true);
    try {
      let { data: storeInfoResp } = await API.graphql(
        graphqlOperation(adminGetStore, {
          storeId: storeId,
        })
      );

      if (storeInfoResp?.adminGetStore?.storeOperatingHourList) {
        setStoreOperatingHoursData(
          storeInfoResp.adminGetStore.storeOperatingHourList
        );
      }

      //need to make sure backend sends back the the days not more than 2 or UI will look wierd
      if (storeInfoResp?.adminGetStore) {
        setDaysForAdvancedOrder(
          Number(storeInfoResp.adminGetStore.maxAdvancedOrderDay)
        );
        setCheckedForAdvancedOrder(
          Boolean(storeInfoResp.adminGetStore.advancedOrderEnabled)
        );
        storeInfoResp.adminGetStore.maxAdvancedOrderDay === 0 ||
        storeInfoResp.adminGetStore.maxAdvancedOrderDay == null
          ? setDaysForAdvancedOrderLabel(
              translate("store-operating-hours-page.0-day")
            )
          : storeInfoResp.adminGetStore.maxAdvancedOrderDay === 1
          ? setDaysForAdvancedOrderLabel(
              translate("store-operating-hours-page.1-day")
            )
          : setDaysForAdvancedOrderLabel(
              translate("store-operating-hours-page.2-day")
            );
      }
      setStoreIsLoading(false);
    } catch (error) {
      console.log(error);
      setStoreIsLoading(false);
    }
  };

  useEffect(() => {
    isMultistore ? fetchAdminStoreData() : fetchStoreData();
  }, []);

  useEffect(() => {
    if (history.location.state && history.location.state.previous) {
      setIsSetting(true);
    }
  }, [history.location.state]);

  return (
    <div>
      <Header page={"setting"} />
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      <div className="body body-bg body-padding">
        <Link
          to={
            isSetting
              ? "/kds/settings"
              : "/kds/settings/store-type?merchantId=" + query.get("merchantId")
          }
        >
          <IoArrowBackSharp className="backbutton-styling"></IoArrowBackSharp>
        </Link>
        <h1 className="orderhome-orderinfo-extralarge">
          {translate("order-listing-screen.storeopening")}
        </h1>
        <div className="store-opening-grid">
          <h1 className="orderhome-orderinfo-small">
            {translate("order-listing-screen.day")}
          </h1>
          <h1 className="orderhome-orderinfo-small">
            {translate("order-listing-screen.openat")}
          </h1>
          <h1 className="orderhome-orderinfo-small">
            {translate("order-listing-screen.closeat")}
          </h1>
        </div>

        <Formik
          initialTouched={{
            field: true,
          }}
          enableReinitialize={true}
          initialValues={storeOperatingHoursData}
          validationSchema={validateStoreOperatingHour}
          validateOnMount={true}
          onSubmit={(val) => {
            handleOnSubmit(val);
          }}
        >
          {(formikProps) => {
            return (
              <Form>
                {formikProps.values.map((days, index) => (
                  <DateTimeInput
                    key={index}
                    index={index}
                    daysOperatingHour={days}
                    storeIsLoading={storeIsLoading}
                    errorMessage={formikProps.errors}
                    disabled={isMultistore}
                    formikProps={formikProps}
                  />
                ))}

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedForAdvancedOrder}
                        onChange={handleChange}
                        disabled={isMultistore}
                      />
                    }
                    label={translate(
                      "store-operating-hours-page.store-accepts"
                    )}
                  />
                </FormGroup>

                {checkedForAdvancedOrder ? (
                  <Box className="advancebox-row">
                    <div>
                      <h1
                        className="storeopening-textlabel"
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        style={{ paddingRight: "8px" }}
                      >
                        {translate(
                          "store-operating-hours-page.days-in-advance"
                        )}
                      </h1>
                    </div>
                    <div className="storeopening-days-select">
                      <Select
                        options={DAY_OPTIONS}
                        value={{
                          value: daysForAdvancedOrder,
                          label: daysForAdvancedOrderLabel,
                        }}
                        isDisabled={isMultistore}
                        type="select"
                        name={`${daysForAdvancedOrder}`}
                        isSearchable={false}
                        isLoading={storeIsLoading}
                        onChange={({ value, label }) => {
                          setDaysForAdvancedOrder(value);
                          setDaysForAdvancedOrderLabel(label);
                        }}
                      />
                    </div>
                  </Box>
                ) : null}

                {!isMultistore && (
                  <div
                    className="orderview-single-button-box"
                    style={{ marginTop: "20px" }}
                  >
                    <CustomLoadingButton
                      className="orderview-confirm-button"
                      loading={saveIsLoading}
                      type="submit"
                      loadingPosition="start"
                      variant={
                        !formikProps.isValid || saveIsLoading
                          ? "outlined"
                          : "contained"
                      }
                    >
                      {isSetting
                        ? translate("settings-home-page.save")
                        : translate("settings-home-page.next")}
                    </CustomLoadingButton>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>

      {isSetting && <Footer page={"setting"}></Footer>}
    </div>
  );
}

export default StoreOperatingHours;
