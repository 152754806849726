import Moment from "moment";

export default class validator {
  static formatTimeToMoment = (time) => {
    try {
      let hour = parseInt(time.split(":")[0]);
      let minute = parseInt(time.split(":")[1]);
      let datetime = Moment().hours(hour).minute(minute).second(0);
      return datetime;
    } catch {
      return time;
    }
  };
  static isEmptyString = (str) => {
    if (
      str === null ||
      str === undefined ||
      (str && str.toString().match(/^ *$/) !== null) ||
      str.toString().replace(/[\u200E\u200F\uFEFF\u00A0 ]/g, "") === ""
    ) {
      return true;
    } else {
      return str.length < 1;
    }
  };
  static isBetweenTwoDates = (start, end, date) => {
    if (date === undefined) {
      const isOpen = Moment().isBetween(Moment(start), Moment(end), "hour", []);
      return isOpen;
    } else return Moment(date).isBetween(Moment(start), Moment(end));
  };
  static isString = (str) => {
    return /^[a-zA-Z0-9\u200E\u200F\uFEFF\u00A0~!@#$%^&*()_+{}|:" <>?`=[\];',\\.//\n/-]*$/.test(
      str
    );
  };

  static isAlphaNumeric = (str) => {
    return /^([a-zA-Z0-9 _-]+)$/.test(str)
  }

  static isDecimal = (str) => {
    return /^(?!-0(\.0+)?$)-?(0|[1-9]\d*)(\.\d+)?$/.test(str);
  };

  static isPrice = (str) => {
    return /^\d{0,7}[.]?\d{1,2}$/.test(str)
  }

  static isDigit = (str) => {
    return /^\d+$/.test(str);
  };

  static isEmail = (str) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      str
    );
  };
  
  static isPhoneNumber = (str) => {
    return /^(\+?6?01)[0|1|2|3|4|6|7|8|9]-*[0-9]{7,8}$/.test(str);
  }

  static isValidCognitoPhoneNumber = (str) => {
    return /^(\+601)[0|1|2|3|4|6|7|8|9]-*[0-9]{7,8}$/.test(str);
  }
  
  static isMoreGeneralPhoneNumber = (str) => {
    return /^(\+?6?[05])\d{6,}$/.test(str);
  }

  static isSpecialChar = (str) => {
    return /^[\w&.,!'()&/-\s]*$/.test(str);
  };

  static isValidCognitoPassword = (str) => {
    let specialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    let alphaNumeric = /[a-zA-Z0-9]/
    return str.length >= 8 && specialChar.test(str) && alphaNumeric.test(str)
  }
  
  static isUrl = (str) => {
    const regExQ =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
    if (str.match(regExQ)) {
      return true;
    } else {
      return false;
    }
  };

  static isEmpty = (obj) => {
    var hasOwnProperty = Object.prototype.hasOwnProperty;
    if (obj == null) return true;
    if (obj === undefined) return true;
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;
    if (typeof obj !== "object") return true;
    for (var key in obj) {
      if (hasOwnProperty.call(obj, key)) return false;
    }
    return true;
  };
}
