import { useState, useEffect, useContext, useMemo, useRef } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import produce from "immer";
import { useTranslate } from "react-polyglot";
import { MerchantContext } from "../../context/MerchantContext";
import "./ViewMenu.scss";
import { ScrollLoadList } from "../../components/scroll-load-list/ScrollLoadList";
import {
  InputBase,
  Box,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Search, AddBox, CatchingPokemonSharp } from "@mui/icons-material";
import _ from "lodash";
import validator from "../../utils/validator";
import { CustomButton } from "../../components/buttons/Buttons";
import { CustomizedSnackbars } from "../../components/shareable/Shareable";
import { SHARED_ERROR_MESSAGE } from "../../constants";
import { ConfirmDeleteDialog } from "../../components/confirm-delete-dialog/ConfirmDeleteDialog";
import { validateExpiryDate } from "../../validation/validateExpiryDate";

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import { searchProducts, kdsListModifierItems } from "../../graphql/queries";
import {
  kdsChangeProductStatus,
  adminDeleteProduct,
  updateModifierItemsAvailability,
  adminDeleteModifierItems,
} from "../../graphql/mutations";

function ViewMenu(props) {
  const truncateText = (text) =>
    text.length > 18 ? text.substr(0, 22) + "..." : text;
  const history = useHistory();
  const translate = useTranslate();
  const merchantInfoContext = useContext(MerchantContext);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#008CDC",
      },
      secondary: {
        main: "#008CDC",
      },
    },
  });
  // listing table type
  const [menuType, setMenuType] = useState(
    localStorage.getItem("menuType") === null
      ? "product"
      : localStorage.getItem("menuType")
  );

  const handleChangeMenuType = (event, newValue) => {
    setProductList([]);
    setAddOnList([]);
    setListTotal(null);
    setNextToken(0);
    setPage(0);
    setSearchFilter("");
    searchBarRef.current.value = "";

    setMenuType(newValue);
    localStorage.setItem("menuType", newValue);
  };
  const menuTypeList = ["product", "addon"];
  const renderTabsText = (text) => {
    if (text === "product") {
      return translate("product-listing-screen.predefined-tab-product");
    } else if (text === "addon") {
      return translate("product-listing-screen.predefined-tab-addon");
    }
  };
  
  useEffect(() =>
    validateExpiryDate(merchantInfoContext.subscriptionExpiryDate)
  )

  // listing table usage state
  const [productList, setProductList] = useState([]);
  const [addOnList, setAddOnList] = useState([]);
  const [listTotal, setListTotal] = useState(null);
  const [nextToken, setNextToken] = useState(0);
  const [maxRowPerPage, setMaxRowPerPage] = useState(20);
  const [listingIsLoading, setListingIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState();
  const [sortMethodParams, setSortMethodParams] = useState({
    field: "updatedAt",
    direction: "desc",
  });
  const [fetchDataError, setFetchDataError] = useState(false);
  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });

  // fetching productlisting from elasticsearch
  const fetchProductListingData = async (
    limit = maxRowPerPage,
    page = 0,
    sortMethod = sortMethodParams
  ) => {
    setListingIsLoading(true);
    try {
      let sort = sortMethod;
      let skipRow = parseInt(page) * parseInt(limit);
      let params = {
        limit: limit,
        nextToken: skipRow,
        filter: {
          and: [
            {
              merchantId: {},
            },
          ],
        },
        sort: sort,
      };

      if (!validator.isEmpty(searchFilter)) {
        params = {
          ...params,
          filter: {
            and: [...params.filter.and, searchFilter],
          },
        };
      }

      let res = await API.graphql(graphqlOperation(searchProducts, params));

      setListTotal(res.data.searchProducts.total);
      if (res.data.searchProducts.total > 0) {
        let productRows = res.data.searchProducts.items;
        let updatedProductList = produce(productList, (draft) => {
          for (let row of productRows) {
            draft.push(row);
          }
        });

        setProductList(updatedProductList);

        if (res.data.searchProducts.total > skipRow) {
          setNextToken(res.data.searchProducts.items.length);
          setPage(page + 1);
          setListingIsLoading(false);
        } else {
          setNextToken(null);
          setListingIsLoading(false);
        }
      } else {
        setProductList([]);
        setNextToken(null);
      }
      setListingIsLoading(false);
    } catch (error) {
      console.log("fetch product listing error", error);
      setListingIsLoading(false);
      setFetchDataError(true);
    }
  };

  // fetching addon listing
  const fetchAddOnListingData = async (
    limit = 100, //temp fix
    page = 0,
    sortMethod = sortMethodParams
  ) => {
    setListingIsLoading(true);
    try {
      let sort = sortMethod;
      let skipRow = parseInt(page) * parseInt(limit);
      let params = {
        limit: limit,
        nextToken: skipRow,
        filter: { },
        sort: sort,
      };

      if (!validator.isEmpty(searchFilter)) {
        params = {
          ...params,
          filter: {
            and: [...params.filter.and, searchFilter],
          },
        };
      }

      let res = await API.graphql(
        graphqlOperation(kdsListModifierItems, params)
      );

      setListTotal(res.data.kdsListModifierItems.total);
      if (res.data.kdsListModifierItems.total > 0) {
        let addOnRows = res.data.kdsListModifierItems.modifierItemList;
        let updatedAddOnList = produce(addOnList, (draft) => {
          for (let row of addOnRows) {
            draft.push(row);
          }
        });

        setAddOnList(updatedAddOnList);

        if (res.data.kdsListModifierItems.total > skipRow) {
          setNextToken(res.data.kdsListModifierItems.modifierItemList.length);
          setPage(page + 1);
          setListingIsLoading(false);
        } else {
          setNextToken(null);
          setListingIsLoading(false);
        }
      } else {
        setAddOnList([]);
        setNextToken(null);
      }
      setListingIsLoading(false);
    } catch (error) {
      console.log("fetch add on listing error", error);
      setListingIsLoading(false);
      setFetchDataError(true);
    }
  };

  // search bar ref
  const searchBarRef = useRef("");
  // search function on elasticsearch
  const handleOnSearch = (e, type) => {
    let currentSearchFilter = {};

    if (e.target.value.length > 0 && e.target.value) {
      let searchKeyword = e.target.value.toLowerCase();

      currentSearchFilter = { or: [] };
      if (type === "product") {
        currentSearchFilter["or"].push({
          title: { matchPhrasePrefix: searchKeyword },
        });
      } else {
        currentSearchFilter["or"].push({
          modifierName: { matchPhrasePrefix: searchKeyword },
        });
      }
    }

    setProductList([]);
    setAddOnList([]);
    setSearchFilter(currentSearchFilter);
  };

  const debouncedHandleOnSearch = useMemo(() => {
    return _.debounce(handleOnSearch, 300);
  }, []);

  // call api to change product status
  const handleChangeProductStatus = async (productId) => {
    try {
      let res = await API.graphql(
        graphqlOperation(kdsChangeProductStatus, { productId: productId })
      );

      setSnackbar({
        snackbarMessage: getChangeProductStatusResponse(
          res.data.kdsChangeProductStatus.message
        ),
        snackbarOpen: true,
        snackbarSeverity:
          res.data.kdsChangeProductStatus.status === "false"
            ? "error"
            : "success",
      });
    } catch (error) {
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  // call api to change add on status
  const handleChangeAddOnStatus = async (
    modifierId,
    availableStatus,
    resetAll = false
  ) => {
    try {
      let params = {
        availableStatus: resetAll
          ? ""
          : availableStatus === "available"
            ? "oos"
            : "available",
        modifierId: resetAll ? "" : modifierId,
        resetAll: resetAll,
        storeId: merchantInfoContext.storeId,
      };

      let res = await API.graphql(
        graphqlOperation(updateModifierItemsAvailability, params)
      );

      setSnackbar({
        snackbarMessage: getChangeAddOnStatusResponse(
          res.data.updateModifierItemsAvailability.message
        ),
        snackbarOpen: true,
        snackbarSeverity: res.data.updateModifierItemsAvailability.status
          ? "success"
          : "error",
      });
      if (resetAll && res.data.updateModifierItemsAvailability.status) {
        setOpenConfirmResetDialog(false);
        setListingIsLoading(true);
        setProductList([]);
        setAddOnList([]);
        setTimeout(() => setRefreshListing((prev) => prev + 1), 1000);
      }
    } catch (error) {
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  const getChangeProductStatusResponse = (message) => {
    switch (message) {
      case "Succesfully changed Product status!":
        return translate(
          "product-listing-screen.change-product-status-success"
        );

      default:
        return message;
    }
  };

  const getChangeAddOnStatusResponse = (message) => {
    switch (message) {
      case "Status updated successfully":
        return translate("product-listing-screen.change-add-on-status-success");

      default:
        return message;
    }
  };

  // confirm delete product dialog usage
  const [openConfirmDeleteProductDialog, setOpenConfirmDeleteProductDialog] =
    useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState("");
  const handleCloseConfirmDeleteProductDialog = () => {
    setProductIdToDelete("");
    setOpenConfirmDeleteProductDialog(false);
  };
  //useeffect dependency to call fetch listing api after delete
  const [refreshListing, setRefreshListing] = useState(0);
  // call api to delete product
  const handleDeleteProduct = async () => {
    try {
      let params = {
        productIdList: [productIdToDelete],
      };

      let res = await API.graphql(graphqlOperation(adminDeleteProduct, params));

      setSnackbar({
        snackbarMessage: getDeleteProductResponse(
          res.data.adminDeleteProduct.message
        ),
        snackbarOpen: true,
        snackbarSeverity:
          res.data.adminDeleteProduct.status === "true" ? "success" : "error",
      });

      if (res.data.adminDeleteProduct.status === "true") {
        setListingIsLoading(true);
        setProductList([]);
        setTimeout(() => setRefreshListing((prev) => prev + 1), 1000);
      }
      setOpenConfirmDeleteProductDialog(false);
    } catch (error) {
      setOpenConfirmDeleteProductDialog(false);
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  const getDeleteProductResponse = (message) => {
    switch (message) {
      case "Success":
        return translate("product-listing-screen.success");

      default:
        return message;
    }
  };

  // handling function for when delete button is clicked to prompt confirm delete
  const handleConfirmDelete = (productId) => {
    setProductIdToDelete(productId);
    setOpenConfirmDeleteProductDialog(true);
  };

  const [openConfirmDeleteAddOnsDialog, setOpenConfirmDeleteAddOnsDialog] =
    useState(false);
  const [addOnIdToDelete, setAddOnIdToDelete] = useState("");

  const closeAddOnConfirmModal = () => {
    setAddOnIdToDelete("");
    setOpenConfirmDeleteAddOnsDialog(false);
  };

  const handleAddOnDelete = (modifierItemId) => {
    setAddOnIdToDelete(modifierItemId);
    setOpenConfirmDeleteAddOnsDialog(true);
  };

  const handleConfirmAddOnsDelete = async (modifierItemId) => {
    try {
      let {
        data: { adminDeleteModifierItems: res },
      } = await API.graphql(
        graphqlOperation(adminDeleteModifierItems, { modifierItemId })
      );
      const isSuccess = res?.status;
      setSnackbar({
        snackbarMessage: res?.message,
        snackbarSeverity: isSuccess ? "success" : "error",
        snackbarOpen: true,
      });

      if (isSuccess) {
        setOpenConfirmResetDialog(false);
        setListingIsLoading(true);
        setProductList([]);
        setAddOnList([]);
        setTimeout(() => setRefreshListing((prev) => prev + 1), 1000);
      }
      setOpenConfirmDeleteAddOnsDialog(false);
    } catch (error) {
      setOpenConfirmDeleteAddOnsDialog(false);
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarSeverity: "error",
        snackbarOpen: true,
      });
    }
  };

  // confirm reset add ons dialog usage
  const [openConfirmResetDialog, setOpenConfirmResetDialog] = useState(false);

  useEffect(() => {
    if (menuType === "product") {
      fetchProductListingData();
    } else {
      fetchAddOnListingData();
    }
  }, [searchFilter, refreshListing, menuType]);

  return (
    <div>
      <Header page={"menu"} />
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      <div className="new-body-padding-with-footer product-listing-page-wrapper">
        <div className="header-section">
          <div className="title-section">
            <h1 className="main-title">
              {menuType === "product"
                ? translate("product-listing-screen.menu")
                : translate("product-listing-screen.predefined-tab-addon")}{" "}
              ({listTotal ? listTotal : 0})
            </h1>
            <CustomButton
              variant="contained"
              disableRipple
              disableElevation
              startIcon={menuType === "product" ? <AddBox /> : null}
              onClick={
                menuType === "product"
                  ? () => history.push("/kds/addproduct")
                  : () => setOpenConfirmResetDialog(true)
              }
              className="header-button"
            >
              {menuType === "product"
                ? translate("product-listing-screen.add-new-product")
                : translate("product-listing-screen.reset-all")}
            </CustomButton>
          </div>
          <div>
            <ThemeProvider theme={theme}>
              <Box style={{ marginBottom: "10px" }} sx={{ width: "100%" }}>
                <Tabs
                  value={menuType}
                  onChange={handleChangeMenuType}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="Menu Type Tabs"
                  centered
                  className="orderhome-tabs-text"
                >
                  {menuTypeList.map((type) => {
                    return (
                      <Tab
                        key={type}
                        value={type}
                        label={
                          <span
                            style={{
                              textTransform: "capitalize",
                              fontFamily: "DM Sans",
                              fontSize: "14px",
                            }}
                          >
                            {renderTabsText(type)}
                          </span>
                        }
                      />
                    );
                  })}
                </Tabs>
              </Box>
            </ThemeProvider>
          </div>
          <div className="search-bar">
            <InputBase
              className="search-bar-input"
              onChange={(e) => debouncedHandleOnSearch(e, menuType)}
              placeholder={ menuType === "product"
                            ? translate("product-listing-screen.search-product")
                            : translate("product-listing-screen.search-addon")
                          }
              inputRef={searchBarRef}
            />
            <label className="search-bar-logo">
              <Search className="logo" />
            </label>
          </div>
        </div>
        <div className="body-section">
          <ScrollLoadList
            isLoading={listingIsLoading}
            rows={menuType === "product" ? productList : addOnList}
            type={menuType}
            nextToken={nextToken}
            total={listTotal}
            fetchData={
              menuType === "product"
                ? fetchProductListingData
                : fetchAddOnListingData
            }
            maxRow={maxRowPerPage}
            page={page}
            sortMethod={sortMethodParams}
            fetchDataError={fetchDataError}
            switchFunction={
              menuType === "product"
                ? handleChangeProductStatus
                : handleChangeAddOnStatus
            }
            deleteFunction={
              menuType === "product" ? handleConfirmDelete : handleAddOnDelete
            }
          />
        </div>
        <ConfirmDeleteDialog
          openDialog={openConfirmDeleteProductDialog}
          subject={translate("product-listing-screen.product-singular")}
          handleCloseDialog={handleCloseConfirmDeleteProductDialog}
          deleteFunction={() => handleDeleteProduct(productIdToDelete)}
        />
        <ConfirmDeleteDialog
          subject={translate("product-listing-screen.predefined-tab-addon")}
          deleteFunction={() => handleConfirmAddOnsDelete(addOnIdToDelete)}
          handleCloseDialog={closeAddOnConfirmModal}
          openDialog={openConfirmDeleteAddOnsDialog}
        />
        <Dialog
          open={openConfirmResetDialog}
          onClose={() => setOpenConfirmResetDialog(false)}
          className="confirm-delete-dialog"
        >
          <DialogTitle id="alert-dialog-title">
            {translate("product-listing-screen.confirm-reset-all")}
          </DialogTitle>
          <DialogActions>
            <CustomButton
              onClick={() => setOpenConfirmResetDialog(false)}
              autoFocus
              variant="contained"
              color="error"
            >
              {translate("order-listing-screen.no")}
            </CustomButton>
            <CustomButton
              onClick={() => handleChangeAddOnStatus(null, null, true)}
              variant="outlined"
            >
              {translate("order-listing-screen.yes")}
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>
      <Footer page={"menu"} />
    </div>
  );
}

export default ViewMenu;
