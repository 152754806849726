import { useState, useContext } from 'react';
import {useHistory,Link} from 'react-router-dom';
import Header from '../../components/header/Header';
import {BsCheckCircle} from 'react-icons/bs';
import { useTranslate } from "react-polyglot";
import moment from 'moment';
import { MerchantContext } from "../../context/MerchantContext";

function SuccessfulPayment(props) {
    const history = useHistory();
    const translate = useTranslate();
    
    const merchantInfoContext = useContext(MerchantContext);

    return (
        <div className="top-up-page-wrapper">
            <Header/>
            <div>
                  <div className='center-screen'>
                      <div>
                          <h1 style={{textTransform:"none"}} className='ordercomplete-title'>{translate("onboarding-screen.thankyouforpayment")}</h1> 
                          <div className='ordercomplete-icon-holder'>
                              <BsCheckCircle style={{color:"#7EC480",fontSize:"7rem"}}></BsCheckCircle>
                          </div>
                          <div>
                              <h1 style={{textTransform:"none"}} className='ordercomplete-text'>{merchantInfoContext.isFreeTrial?translate("onboarding-screen.thank-you-free-trial"):translate("onboarding-screen.thankyoufortext")}</h1>
                          </div>
                          
                          <div>
                              <h1 style={{textTransform:"none"}} className='ordercomplete-text'>{merchantInfoContext.isFreeTrial?translate("onboarding-screen.start-selling-now"):null}</h1>
                          </div>
                          
                          <div className='orderview-button-bottom'>
                              <div className='ordercomplete-button-holder'>
                                  <Link style={{textDecoration:"none"}} to="/kds/settings">
                                      <div className='orderview-single-button-box'>
                                          <button className='orderview-confirm-noload-button'>{translate("onboarding-screen.back-to-settings")}</button>
                                      </div>
                                  </Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
        </div>
    );
}

export default SuccessfulPayment;