import { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { MerchantContext } from "../../../context/MerchantContext";
import { useTranslate } from "react-polyglot";
import { CustomizedSnackbars } from "../../../components/shareable/Shareable";
import "./SubscriptionHistoryPage.scss";
import { CircularProgress } from "@mui/material";
import { IoArrowBackSharp } from "react-icons/io5";
import { SHARED_ERROR_MESSAGE } from "../../../constants";
import moment from "moment";
import {
  CustomButton,
  CustomIconButton,
} from "../../../components/buttons/Buttons";
import { FileDownloadOutlined } from "@mui/icons-material";

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import {
  getSubscriptionHistoryList,
  adminDownloadMerchantOrderPDFReceipt,
} from "../../../graphql/queries";

function SubscriptionHistoryPage(props) {
  const history = useHistory();

  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });
  const merchantInfoContext = useContext(MerchantContext);
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);

  const [subsInfo, setSubsInfo] = useState({
    subsType: "",
    activationDate: "",
    nextBillingDate: "",
  });
  const [subsHistoryList, setSubsHistoryList] = useState([]);
  const [showSubscriptionButton, setShowSubscriptionButton] = useState(false);

  const fetchSubscription = async () => {
    try {
      setIsLoading(true);
      let res = await API.graphql(graphqlOperation(getSubscriptionHistoryList));
      setSubsInfo({
        subsType: res.data.getSubscriptionHistoryList.subscriptionType,
        activationDate:
          res.data.getSubscriptionHistoryList.subscriptionStartDate,
        nextBillingDate:
          res.data.getSubscriptionHistoryList.subscriptionEndDate,
      });
      setSubsHistoryList(
        !!res.data.getSubscriptionHistoryList.merchantOrderList
          ? res.data.getSubscriptionHistoryList.merchantOrderList
          : []
      );

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  const handleDownloadReceipt = async (merchantOrderId) => {
    try {
      let res = await API.graphql(
        graphqlOperation(adminDownloadMerchantOrderPDFReceipt, {
          merchantOrderId: merchantOrderId,
        })
      );

      if (res.data.adminDownloadMerchantOrderPDFReceipt.status === "true") {
        const url = res.data.adminDownloadMerchantOrderPDFReceipt.message;
        setTimeout(() => {
          window.open(url, "_blank");
        });
      } else {
        setSnackbar({
          snackbarMessage: translate("subscription-history-page.receipt-error"),
          snackbarOpen: true,
          snackbarSeverity: "error",
        });

        return "";
      }
    } catch (error) {
      setSnackbar({
        snackbarMessage: translate("subscription-history-page.receipt-error"),
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
      return "";
    }
  };

  const handleCheckRenewButton = () => {
    let expiryDate = moment(subsInfo.nextBillingDate).utc(false).format();

    if (subsInfo.subsType === "1 Year Subscription") {
      if (
        moment().diff(expiryDate, "days") >= -30 &&
        moment().diff(expiryDate, "days") <= 0
      ) {
        setShowSubscriptionButton(true);
      } else {
        setShowSubscriptionButton(false);
      }
    } else {
      setShowSubscriptionButton(true);
    }
  };

  useEffect(() => {
    handleCheckRenewButton();
  }, [subsInfo]);

  useEffect(() => {
    fetchSubscription();
  }, []);

  return (
    <>
      <Header />
      <div className="new-body-padding-with-footer">
        {isLoading ? (
          <div className="loading-symbol-wrapper">
            <CircularProgress />
          </div>
        ) : (
          <div className="subscription-history-page-wrapper">
            <CustomizedSnackbars
              message={snackbar.snackbarMessage}
              snackbarOpen={snackbar.snackbarOpen}
              snackbarClose={() =>
                setSnackbar({
                  ...snackbar,
                  snackbarOpen: false,
                  snackbarSeverity: snackbar.snackbarSeverity,
                })
              }
              severity={snackbar.snackbarSeverity}
            />
            <div className="header-section">
              <Link to={"/kds/settings"}>
                <IoArrowBackSharp className="backbutton-styling"></IoArrowBackSharp>
              </Link>
              <label className="title">
                {translate("subscription-history-page.subs-history")}
              </label>
            </div>
            <div className="body-section">
              <div className="subscription-info">
                <div className="subscription-info-header">
                  <label className="subscription-info-title">
                    {translate("subscription-history-page.my-subs")}
                  </label>
                  {showSubscriptionButton && (
                    <CustomButton
                      variant="contained"
                      disableRipple
                      disableElevation
                      onClick={() => {
                        if (
                          subsInfo?.subsType?.toLowerCase() === "free trial"
                        ) {
                          history.push({
                            pathname: "/kds/continue-subscription",
                            search: "from=subscription-history&type=free-trial",
                          });
                        } else {
                          history.push({
                            pathname: "/kds/continue-subscription",
                            search: "from=subscription-history",
                          });
                        }
                      }}
                      className="renew-button"
                      disabled={false}
                      style={{
                        lineHeight: 1.2,
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        margin: 0,
                      }}
                    >
                      {subsInfo?.subsType?.toLowerCase() === "free trial"
                        ? translate("subscription-history-page.subscribe-now")
                        : translate("subscription-history-page.renew")}
                    </CustomButton>
                  )}
                </div>
                <div className="subscription-info-body">
                  <div className="each-detail">
                    <label>
                      {translate("subscription-history-page.subs-type")}:
                    </label>
                    <label className="each-detail-data">
                      {subsInfo.subsType}
                    </label>
                  </div>
                  <div className="each-detail">
                    <label>
                      {translate("subscription-history-page.activation-date")}:
                    </label>
                    <label className="each-detail-data">
                      {!!subsInfo.activationDate
                        ? moment(subsInfo.activationDate)
                            .utc(false)
                            .format("DD MMMM YYYY")
                        : ""}
                    </label>
                  </div>
                  <div className="each-detail">
                    <label>
                      {translate("subscription-history-page.next-billing-date")}
                      :
                    </label>
                    <label className="each-detail-data">
                      {!!subsInfo.nextBillingDate
                        ? moment(subsInfo.nextBillingDate)
                            .utc(false)
                            .format("DD MMMM YYYY")
                        : ""}
                    </label>
                  </div>
                </div>
                <div className="subscription-history">
                  <label className="subscription-history-title">
                    {translate("subscription-history-page.transaction-records")}
                  </label>
                  {subsHistoryList.map((o) => (
                    <div className="each-transaction">
                      <div className="text-section">
                        <div className="top-section">
                          <label className="title">{o.title}</label>
                          <label className="amount">
                            {merchantInfoContext.currency} {o.amount}
                          </label>
                        </div>
                        <div className="bottom-section">
                          <label className="order-number">
                            {o.orderNumber}
                          </label>
                          <label className="date">
                            {moment(o.paymentDateTime)
                              .utc(false)
                              .format("DD MMMM, hh:mm A")}
                          </label>
                        </div>
                      </div>
                      <CustomIconButton
                        color="primary"
                        onClick={() => handleDownloadReceipt(o.merchantOrderId)}
                      >
                        {o.title !== "Free Trial" && <FileDownloadOutlined />}
                      </CustomIconButton>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer page={"setting"}></Footer>
    </>
  );
}

export default SubscriptionHistoryPage;
