import { useTranslate } from "react-polyglot";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle
  } from '@mui/material';
import { CustomButton } from "../../components/buttons/Buttons";
import "./ConfirmDeleteDialog.scss";

export const ConfirmDeleteDialog = ({
    openDialog,
    subject,
    handleCloseDialog,
    deleteFunction,
}) => {
    const translate = useTranslate();
    
    return (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          className="confirm-delete-dialog"
        >
          <DialogTitle id="alert-dialog-title">
            {translate("confirm-delete-dialog.delete-selected")} {subject}
          </DialogTitle>
          <DialogActions>
            <CustomButton 
                onClick={handleCloseDialog}
                variant="outlined"
            >
                {translate("confirm-delete-dialog.cancel")}
            </CustomButton>
            <CustomButton 
                onClick={deleteFunction} 
                autoFocus
                variant="contained"
                color="error"
            >
             {translate("confirm-delete-dialog.delete")}
            </CustomButton>
          </DialogActions>
        </Dialog>
    )
}