import React, { useContext, useState } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  Switch,
  Stack,
} from "@mui/material";
import {
  CloudOff,
  AssignmentOutlined,
  DeleteOutlined,
} from "@mui/icons-material";
import "./ScrollLoadList.scss";
import { useTranslate } from "react-polyglot";
import { Waypoint } from "react-waypoint";
import { toS3Link } from "../../utils/shareable-utils";
import { useHistory } from "react-router-dom";
import { MerchantContext } from "../../context/MerchantContext";
import { withStyles } from "@mui/styles";
import { CustomIconButton } from "../buttons/Buttons";
import { CustomSwitch } from "../shareable/Shareable";

const ProductRow = (props) => {
  const {
    row,
    i,
    nextToken,
    rowsLength,
    total,
    fetchData,
    switchFunction,
    deleteFunction,
  } = props;
  const truncateText = (text) =>
    text.length > 18 ? text.substr(0, 16) + "..." : text;
  const history = useHistory();
  const merchantInfoContext = useContext(MerchantContext);

  return (
    <React.Fragment>
      <div key={row?.productId}>
        <div className="product-listing-row-wrapper">
          <div>
            <img
              className="product-image"
              src={
                row?.thumbnailCover
                  ? row.thumbnailCover.includes("http")
                    ? row.thumbnailCover
                    : toS3Link(row.thumbnailCover)
                  : !row?.cover
                  ? ""
                  : row.cover.includes("http")
                  ? row.cover
                  : toS3Link(row.cover)
              }
              alt="product"
            ></img>
          </div>
          <div
            className="product-detail-display"
            onClick={() =>
              history.push("/kds/viewproductdetail?productId=" + row?.productId)
            }
          >
            <h1 className="product-detail-large">
              {truncateText(row?.title ? row.title : "")}
            </h1>
            <h1 className="product-detail-large">
              {merchantInfoContext.currency}{" "}
              {(row?.deliveryPriceWithTax
                ? row.deliveryPriceWithTax
                : row?.deliveryPrice
                ? row.deliveryPrice
                : 0
              ).toFixed(2)}
            </h1>
          </div>
          <div className="product-detail-button-section">
            <CustomSwitch
              defaultChecked={!row.isDisabled}
              onClick={() => switchFunction(row.productId)}
            />
            <CustomIconButton
              color="error"
              onClick={() => deleteFunction(row.productId)}
            >
              <DeleteOutlined />
            </CustomIconButton>
          </div>
        </div>
      </div>
      {nextToken !== null && i === rowsLength - 2 && total !== rowsLength && (
        <Waypoint onEnter={fetchData} />
      )}
    </React.Fragment>
  );
};

const AddOnRow = (props) => {
  const {
    row,
    i,
    nextToken,
    rowsLength,
    total,
    fetchData,
    switchFunction,
    deleteFunction,
  } = props;
  const truncateText = (text) =>
    text.length > 18 ? text.substr(0, 16) + "..." : text;

  return (
    <React.Fragment>
      <div key={row?.modifierId}>
        <div className="add-on-listing-row-wrapper">
          <label className="add-on-name">
            {truncateText(row?.modifierName ? row.modifierName : "")}
          </label>
          <Stack spacing={2} direction="row">
            <CustomSwitch
              defaultChecked={row.availableStatus === "available"}
              onClick={() =>
                switchFunction(row.modifierId, row.availableStatus)
              }
            />
            <CustomIconButton
              onClick={() => deleteFunction(row.modifierId)}
              color="error"
            >
              <DeleteOutlined />
            </CustomIconButton>
          </Stack>
        </div>
      </div>
      {nextToken !== null && i === rowsLength - 2 && total !== rowsLength && (
        <Waypoint onEnter={fetchData} />
      )}
    </React.Fragment>
  );
};
export const ScrollLoadList = ({
  isLoading = false,
  rows = [],
  rowStructure,
  nextToken,
  total,
  fetchData,
  maxRow = 10,
  page,
  sortMethod,
  fetchDataError,
  type,
  switchFunction,
  deleteFunction,
}) => {
  const translate = useTranslate();

  const handleFetchMore = (page) => {
    if (fetchData) {
      fetchData(maxRow, page, sortMethod);
    }
  };

  return (
    <div className="scroll-load-list-wrapper">
      {rows.map((row, i) =>
        type === "product" ? (
          <ProductRow
            row={row}
            i={i}
            key={i}
            nextToken={nextToken}
            rowsLength={rows.length}
            total={total}
            fetchData={() => handleFetchMore(page)}
            switchFunction={switchFunction}
            deleteFunction={deleteFunction}
          />
        ) : type === "addon" ? (
          <AddOnRow
            row={row}
            i={i}
            key={i}
            nextToken={nextToken}
            rowsLength={rows.length}
            total={total}
            deleteFunction={deleteFunction}
            fetchData={() => handleFetchMore(page)}
            switchFunction={switchFunction}
          />
        ) : null
      )}
      {isLoading && (
        <div className="loading-symbol-wrapper">
          <CircularProgress />
        </div>
      )}
      {fetchDataError && isLoading === false ? (
        <div className="error-handler">
          <CloudOff fontSize="large" className="blue-icon" />
          <Typography align="center" variant="h5" className="blue-icon">
            {translate("error-handler.oops")}
          </Typography>
          <Typography align="center" variant="h6" gutterBottom={true}>
            {translate("error-handler.server-error")}
          </Typography>
          <Button
            variant="contained"
            className="refresh-button"
            onClick={() => window.location.reload()}
          >
            {translate("error-handler.try-again")}
          </Button>
        </div>
      ) : rows.length === 0 && isLoading === false ? (
        <div className="error-handler">
          <AssignmentOutlined fontSize="large" color="disabled" />
          <Typography align="center" variant="h6">
            {translate("error-handler.no-record")}
          </Typography>
        </div>
      ) : null}
    </div>
  );
};
