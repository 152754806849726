import React,{useEffect,useState,useContext} from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import {Link,useHistory} from 'react-router-dom';
import {IoArrowBackSharp} from 'react-icons/io5';
import { setLocale } from "../../../localization/localization";
import { ACTIONS, appState } from "../../../states/appState";
import { useGlobalState } from "../../../states/globalState";
import { useTranslate } from "react-polyglot";
import msflag from "../../../assets/images/msflag.png";
import usflag from "../../../assets/images/usflag.png";
import zhflag from "../../../assets/images/chinaflag.png";
import moment from 'moment';
import { LoadingButton } from "@mui/lab";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { MerchantContext } from "../../../context/MerchantContext";
import { CustomizedSnackbars } from '../../../components/shareable/Shareable';
// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import { updatePreferredLanguage, updateStoreSetupStatus } from '../../../graphql/mutations';
import {
    adminGetMerchantGeneralConfig,
} from "../../../graphql/queries";
import { useQuery } from "../../../hooks/useQuery";

function SelectLanguage(props) {
    const translate = useTranslate();
    const [lang, dispatch] = useGlobalState(appState, (state) => state.locale);
    const onLanguageChange = (language) => {
        // window.location.reload();
        setLanguage(language)
        setLocale(language);
        moment.locale(language);
        dispatch(ACTIONS.onLanguageChange, language);
        window.location.reload();
      };
    const merchantInfoContext = useContext(MerchantContext);
    const [storeIsLoading, setStoreIsLoading] = useState(false);
    const [languageIsLoading, setLanguageIsLoading] = useState(false);
    const [storeData, setStoreData] = useState("");
    const history = useHistory();
    const [isSetting, setIsSetting] = useState(false)
    let query = useQuery();
    
    const [snackbar, setSnackbar] = useState({
      snackbarMessage: "",
      snackbarOpen: false,
      snackbarSeverity: "info",
    });
    
    const fetchStoreData = async (
    ) => {
        setStoreIsLoading(true);
        try {
            
            let res = await API.graphql(graphqlOperation(adminGetMerchantGeneralConfig));
            let data = res.data.adminGetMerchantGeneralConfig
            setStoreData(data);
            if (data){
                if (data.preferredLanguage===null){
                    localStorage.setItem("locale","en")
                } else {
                    localStorage.setItem("locale", data.preferredLanguage.toLowerCase())
                }
            }
            setStoreIsLoading(false);
        } catch (error) {
            setStoreIsLoading(false);
        }
    };
    
    const setLanguage = async (language) => {
      setLanguageIsLoading(true);

      try {
        let params = {
          preferredLanguage : language,
        };

        const respOrderFulfillment = await API.graphql(
          graphqlOperation(updatePreferredLanguage, params)
        );

        if (respOrderFulfillment.data.updatePreferredLanguage.status === "200") {
            if (!isSetting){
              //click Next
              history.push('/kds/settings')
            }
            
        } else {
          setSnackbar({
            snackbarMessage: respOrderFulfillment.data.updatePreferredLanguage.message,
            snackbarOpen: true,
            snackbarSeverity: "success",
          });
        }
        setLanguageIsLoading(false);
      } catch (error) {
        console.log(error)
        setLanguageIsLoading(false);
      }
    }

    const getPreviousLink = () => {
      if (!isSetting && merchantInfoContext.role === "AxrailAdmin") {
        return "/kds/select-merchant";
      } else if (isSetting) {
        return history.location.state.previous;
      } else {
        return "/kds/package-offer";
      }
    }

    useEffect(() => {
      if (history.location.state && history.location.state.previous){
          setIsSetting(true)
      }
    }, [history.location.state])

    useEffect(()=> {
        fetchStoreData()
    },[])

    const renderStyle = text => {
        if(text===localStorage.getItem("locale")) {
            return {
				backgroundColor: "#ddebf8",
                border: "2px solid #5cb4e7",
                color: "#47abe5"
			};
        } else {
            return {
				backgroundColor: "#fff",
                border: "1px solid #a0aec0",
                color: "#a0aec0"
			};
        }
    }

    const renderPage = () => {
        if(storeIsLoading || languageIsLoading) {
            return (
                <Box className='center-loader' sx={{ display: 'flex' }}>
                  <CircularProgress/>
                </Box>
            )
        } else {
            return (
                <div>
                    <div className="selectlanguage-select-button" style={renderStyle("en")} onClick={(e) => onLanguageChange("en")}> 
                        <div className='flag-container'>
                            <img className='flag-image-container' src={usflag}></img>
                        </div>
                        <p> English </p>
                    </div>
                    <div className="selectlanguage-select-button" style={renderStyle("bm")} onClick={(e) => onLanguageChange("bm")}> 
                        <div className='flag-container'>
                            <img className='flag-image-container' src={msflag}></img>
                        </div>
                        <p> Bahasa Melayu </p>
                    </div>
                    <div className="selectlanguage-select-button" style={renderStyle("zh")} onClick={(e) => onLanguageChange("zh")}> 
                        <div className='flag-container'>
                            <img className='flag-image-container' src={zhflag}></img>
                        </div>
                        <p> 中文 </p>
                    </div>
                    
                    {
                    !isSetting || merchantInfoContext.role==="AxrailAdmin" ?
                        <div className='orderview-single-button-box' style={{marginTop:"20px"}}>
                          <LoadingButton
                            className="orderview-confirm-button"
                            onClick={() => {
                                if (merchantInfoContext.role==="AxrailAdmin"){
                                    history.push("/kds/settings")
                                } else {
                                    history.push("/kds/package-offer")
                                }
                                
                            }}
                            >
                                {!isSetting || merchantInfoContext.role==="AxrailAdmin" ? translate("settings-home-page.next") : translate("settings-home-page.save")}
                            </LoadingButton>
                        </div>
                    : null
                    }
                </div>
            )
        }
    }

    return (
        <div>
            <Header page={"setting"}></Header>
            <CustomizedSnackbars
              message={snackbar.snackbarMessage}
              snackbarOpen={snackbar.snackbarOpen}
              snackbarClose={() =>
                  setSnackbar({
                  ...snackbar,
                  snackbarOpen: false,
                  snackbarSeverity: snackbar.snackbarSeverity,
                  })
              }
              severity={snackbar.snackbarSeverity}
          />
                <div className='body body-bg body-padding'>
                    { (isSetting || merchantInfoContext.role === "AxrailAdmin") &&
                      <Link to={getPreviousLink}>
                          <IoArrowBackSharp className='backbutton-styling'></IoArrowBackSharp>
                      </Link>
                    }
                    <h1 className='orderhome-orderinfo-extralarge'>{translate("settings-home-page.languages")}</h1> 
                    <h1 className='orderhome-orderinfo-small'>{translate("order-listing-screen.pleasechooselang")}</h1>
                    
                    {renderPage()}
                   
                </div>
                {isSetting && (
                    <Footer page={"setting"}></Footer> 
                  )}
        </div>
    );
}

export default SelectLanguage;