import React, { useState, useEffect, useCallback , useContext} from 'react';
import moment from 'moment';
import queryString from 'query-string';
import {useLocation,Link} from 'react-router-dom';
import {BsCheckCircle} from 'react-icons/bs';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '../../components/header/Header';
import {
    formatPrice,
  } from "../../utils/shareable-utils";
  import { useTranslate } from "react-polyglot";

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import {
    adminGetCustomerOrderDetailList,
} from "../../graphql/queries";
import { MerchantContext } from "../../context/MerchantContext";

function CompleteOrder(props) {
    const translate = useTranslate();
    const location = useLocation();
    const orderID = queryString.parse(location.search).orderID;
    const progress = queryString.parse(location.search).progress;
    const merchantInfoContext = useContext(MerchantContext);

    const [orderItems, setOrderItems] = useState([]);
    const [orderInfo, setOrderInfo] = useState({});
    const [fetchDataError, setFetchDataError] = useState(false);
    const [listTotal, setListTotal] = useState(null);
    const [orderItemIsLoading, setOrderItemIsLoading] = useState();
	
	 const fetchOrderDetailData = useCallback(
        async (id) => {
          let merchantId = "00c20727-ecdf-4799-b834-4b725f8e79df"
          try {
            setOrderItemIsLoading(true);
            let res = null;
            let params = {
              orderId: orderID,
              merchantId: merchantInfoContext.merchantId,
            };

            res = await API.graphql(graphqlOperation(adminGetCustomerOrderDetailList, params));

            if (res.data.adminGetCustomerOrderDetailList.status === "true") {
              let orderDetailInfo = res.data.adminGetCustomerOrderDetailList.order;

              setOrderInfo({
                ...orderDetailInfo,
                deliveryAddress:
                  orderDetailInfo.deliveryAddress + ", " + orderDetailInfo.deliveryCountry,
                billingAddress: orderDetailInfo.billingAddress + ", " + orderDetailInfo.billingCountry,
              });
              let orderDetailsList = res.data.adminGetCustomerOrderDetailList.orderDetails;
              var tmpList = [];
              if (orderDetailsList !== null) {
                setListTotal(orderDetailsList.length);
                for (const [key, value] of Object.entries(orderDetailsList)) {
                  tmpList.push(
                    Object.assign(value, {
                      fulfillmentQuantity: value.orderedQuantity,
                      availableRefundQuantity: value.orderedQuantity - value.refundQuantity,
                      refundQuantity: value.orderedQuantity - value.refundQuantity,
                      refundAmount: formatPrice(
                        (value.orderedQuantity - value.refundQuantity) *
                          (value.deliveryPriceWithTax !== 0
                            ? value.deliveryPriceWithTax
                            : value.deliveryPrice)
                      ),
                    })
                  );
                }
                setOrderItems(tmpList);
              } else {
                setListTotal(0);
              }
            }
            setOrderItemIsLoading(false);
          } catch (error) {
            console.log(error);
            setOrderItemIsLoading(false);
            setFetchDataError(true);
          }
        },
        []
    );


    useEffect(() => {
        if (orderID) {
          fetchOrderDetailData(orderID);
        }
      }, [orderID, fetchOrderDetailData]);

      const renderPage = () => {
        if(orderItemIsLoading) {
            return (
                <Box className='center-loader' sx={{ display: 'flex' }}>
                  <CircularProgress centered/>
                </Box>
              );
        } else {
            if (orderInfo.status === "Order Completed") {
                  return (
                    <div>
                        <div className='center-screen'>
                            <div>
                                <h1 className='ordercomplete-title'>{translate("order-listing-screen.order-completed-status")}</h1> 
                                <div className='ordercomplete-icon-holder'>
                                    <BsCheckCircle style={{color:"#7EC480",fontSize:"7rem"}}></BsCheckCircle>
                                </div>
                                <div>
                                    <h1 className='ordercomplete-text'>{translate("order-listing-screen.order-number")} : #{orderInfo.orderNumber}</h1>
                                </div>
                            </div>
                        </div>
                        <div className='orderview-button-bottom'>
                            <div className='ordercomplete-button-holder'>
                                <Link style={{textDecoration:"none"}} to="/kds/orders">
                                    <div className='orderview-single-button-box'>
                                        <button className='orderview-confirm-noload-button'>{translate("order-listing-screen.home")}</button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                  )      
              } else {
                  return (
                      <div>
                          <div className='center-screen'>
                              <div>
                                  <h1 className='ordercomplete-title'>{translate("order-listing-screen.order-refunded-status")}</h1> 
                                  <div className='ordercomplete-icon-holder'>
                                      <BsCheckCircle style={{color:"#7EC480",fontSize:"7rem"}}></BsCheckCircle>
                                  </div>
                                  <div>
                                      <h1 className='ordercomplete-text'>{translate("order-listing-screen.order-number")} :#{orderInfo.orderNumber}</h1>
                                  </div>
                              </div>
                          </div>
                          <div className='orderview-button-bottom'>
                              <div className='ordercomplete-button-holder'>
                                  <Link style={{textDecoration:"none"}} to="/kds/orders">
                                      <div className='orderview-single-button-box'>
                                          <button className='orderview-confirm-noload-button'>{translate("order-listing-screen.home")}</button>
                                      </div>
                                  </Link>
                              </div>
                          </div>
                      </div>
                  )
              }
          }
    }

    return (
        <div>
            <Header page={"order"}></Header>
            {renderPage()}
        </div>
    );
}

export default CompleteOrder;