import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Auth, API, graphqlOperation } from "aws-amplify";
import { Typography, Button, TextField, CircularProgress } from "@mui/material";
import { CustomizedSnackbars } from "../../../../components/shareable/Shareable";
import { validator } from "../../../../utils";
import { useTranslate } from "react-polyglot";
import { gatewayUrl } from "../../../../utils/shareable-utils";
import axios from "axios";

export default function ForceChangePassword(props) {
  let history = useHistory();
  const { user, newPassword, setNewPassword, setQrMFA, loginToken, setUser, isFirstTimeLogin = false } = props;
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [severity, setSeverity] = useState(false);
  const translate = useTranslate();

  const confirmChangePassword = async () => {
    if (
      !validator.isEmptyString(newPassword) &&
      !validator.isEmptyString(confirmPassword)
    ) {
      try {
        const loggedUser = await Auth.completeNewPassword(user, newPassword);
        setisLoading(true);
        history.push("/");
      } catch (e) {
        setError(e);
        console.log(e);
      }
    } else {
      setSnackbarMessage(translate("login-handler.field-cannot-be-blank"));
      setSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleFirstTimeLogin = async () => {
    setisLoading(true);
    if (
      !validator.isEmptyString(newPassword) &&
      !validator.isEmptyString(confirmPassword) &&
      validator.isValidCognitoPassword(confirmPassword) &&
      (newPassword === confirmPassword)
    ) {
      let params = {
        password: newPassword,
        loginToken: loginToken
      }
      axios
      .post(
        `${gatewayUrl}/merchant/adminresetpassword`,
        params
      )
      .then( async (res) => {
        let data = res.data
        //auto login
        const user = await Auth.signIn(data.username, newPassword);
        setUser(user);
        if (
          user.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes("AxrailAdmin")
        ) {
          history.push("/kds/select-merchant");
        } else {
          window.location.href = "/";
          setisLoading(false);
          props.setAlertMsgControl("show");
        }
      })
      .catch((err) => {
        if (
          err.response
        ) {
          // Request made and server responded
          setisLoading(false);
          setSeverity("error");
          setSnackbarMessage(err.response.data);
          setSnackbarOpen(true);

        } else if(err.request) {
          // The request was made but no response was received
          setisLoading(false);
          setSeverity("error");
          setSnackbarMessage(err.request);
          setSnackbarOpen(true);

        } else {
          // Something happened in setting up the request that triggered an Error
          setisLoading(false);
          setSnackbarMessage(err.message);
          setSeverity("error");
          setSnackbarOpen(true);

        }
      });

    } else {
      if (newPassword !== confirmPassword) {
        setError({message: translate("login-handler.password-no-match")})
      } else if (!validator.isValidCognitoPassword(confirmPassword)){
        setError({message: translate("login-handler.invalid-password-format")})
      } else {
        setSnackbarMessage(translate("login-handler.field-cannot-be-blank"));
        setSeverity("error");
        setSnackbarOpen(true);
      }
      setisLoading(false);
    }
  }

  const clearError = () => {
    setError(null);
  };

  const renderPage = () => {
    return (
      <div>
        <CustomizedSnackbars
          message={snackbarMessage}
          snackbarOpen={snackbarOpen}
          snackbarClose={setSnackbarOpen}
          severity={severity}
        />
        <div>
          <div className="sign-in-container">
            {newPassword.length > 7 &&
            confirmPassword.length > 7 &&
            newPassword !== confirmPassword ? (
              <div className="login-alert-body">
                <div className="login-alert-box">{translate("login-handler.password-no-match")}</div>
              </div>
            ) : null}
            <div className="password-requirement">
              <h4>{translate("login-handler.password-requirement")}</h4>
            </div>
            <TextField
              label={translate("login-handler.new-password-asterisk")}
              type="password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                if (error) {
                  clearError();
                }
              }}
              variant="outlined"
              className="sign-in-input-main-theme"
              name="password"
            />
            <TextField
              label={translate("login-handler.confirm-new-password")}
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                if (error) {
                  clearError();
                }
              }}
              variant="outlined"
              className="sign-in-input-main-theme"
              name="password"
            />
            <p style={{ color: "red", fontSize: "12px" }}>
              {!!error && error.message}
            </p>
            <Button
              variant="contained"
              className="orderview-confirm-button"
              color="primary"
              onClick={isFirstTimeLogin ? handleFirstTimeLogin : confirmChangePassword}
            >
              {!isLoading ? (
                translate("login-handler.confirm")
              ) : (
                <CircularProgress size={20} color={"inherit"} />
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return <>{renderPage()}</>;
}
