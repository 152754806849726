import React from "react";
import moment from "moment";

export const validateExpiryDate = (subscriptionExpiryDate) => {
    var x = moment(
      new moment.utc(subscriptionExpiryDate)
        .local()
        .format("YYYY-MM-DDTHH:mm:ssZ")
    );
    var y = new moment(Date.now());
    var duration = x.diff(y, "days");
    
    if(duration<0) {
       window.location.href = "/kds/expired-prompt"
    }
};
