import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import Autocomplete from "@material-ui/lab/Autocomplete";
import produce from "immer";
import { useTranslate } from "react-polyglot";
import {
  ContainedButton,
  OutlinedButton,
  CustomButton,
  CustomIconButton,
} from "../../components/buttons/Buttons";
import { CustomizedSnackbars } from "../../components/shareable/Shareable";
import "./ProductModifierWizard.scss";
import { IoArrowBackSharp } from "react-icons/io5";
import { IconButton, InputAdornment } from "@mui/material";
import { ArrowBack, Add, DeleteOutlined } from "@mui/icons-material";
import { CustomInputBase } from "../../components/custom-input-base/CustomInputBase";
import { v4 as uuid } from "uuid";
import { MerchantContext } from "../../context/MerchantContext";
import validator from "../../utils/validator";
import {
    inputCurrency,
  } from "../../utils/shareable-utils";
import { ConfirmDeleteDialog } from "../../components/confirm-delete-dialog/ConfirmDeleteDialog";
import _ from "lodash";
import { SHARED_ERROR_MESSAGE } from "../../constants";
// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import { kdsListModifierItems } from "../../graphql/queries";

export function ProductModifierWizard(props) {
  const {
    modifierGroupList,
    setModifierGroupList,
    setShowProductModifier,
    modifierGroupIdToEdit,
    setModifierGroupIdToEdit,
    isProductCreate = false,
    handleProductCreate,
    redirectToLastPage = false,
  } = props;
  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });
  const translate = useTranslate();
  const merchantInfoContext = useContext(MerchantContext);

  const [step, setStep] = useState("add-on-type");
  const [modifierGroupType, setModifierGroupType] = useState();
  const [modifierGroupName, setModifierGroupName] = useState("");
  const [options, setOptions] = useState([
    {
      availableStatus: "available",
      modifierId: "",
      modifierName: "",
      price: "",
    },
  ]);
  const [minSelection, setMinSelection] = useState(0);
  const [maxSelection, setMaxSelection] = useState(1);
  const [isItemsControlAvailable, setIsItemsControlAvailable] = useState(true);

  // confirm delete modifier option dialog usage
  const [openConfirmDeleteOptionDialog, setOpenConfirmDeleteOptionDialog] =
    useState(false);
  const [optionIndexToDelete, setOptionIndexToDelete] = useState();
  const handleConfirmDeleteOption = (optionIndex) => {
    setOpenConfirmDeleteOptionDialog(true);
    setOptionIndexToDelete(optionIndex);
  };

  const handleCloseConfirmDeleteOptionDialog = () => {
    setOpenConfirmDeleteOptionDialog(false);
    setOptionIndexToDelete();
  };

  const handleRemoveOption = (optionIndex) => {
    handleCloseConfirmDeleteOptionDialog();
    let updatedOptions = [...options];

    updatedOptions.splice(optionIndex, 1);
    setOptions(updatedOptions);
    if (minSelection > updatedOptions.length) {
      setMinSelection(updatedOptions.length);
    }
    if (maxSelection > updatedOptions.length) {
      setMaxSelection(updatedOptions.length);
    }
  };

  const handleInputBaseChange = (e) => {
    let value = null;
    if (e.target.value === " ") {
      value = "";
    } else {
      value = e.target.value;
    }

    if (e.target.name === "modifierGroupName") {
      setModifierGroupName(value);
    }
  };

  const handleModifierNameChange = (newValue, modifierIndex) => {
    let value = null;
    if (validator.isEmptyString(newValue)) {
      value = "";
    } else {
      value = newValue;
    }

    let updatedOptions = produce(options, (draft) => {
      draft[modifierIndex]["modifierName"] = value;
    });

    setOptions(updatedOptions);
  };

  const handleModifierNameOnBlur = (e, modifierIndex) => {
    let updatedOptions = produce(options, (draft) => {
      if (validator.isEmptyString(e.target.value)) {
        draft[modifierIndex]["modifierId"] = "";
      } else {
        let addOnListIndex = addOnList.findIndex(
          (o) =>
            o.label.localeCompare(e.target.value.trim(), undefined, {
              sensitivity: "accent",
            }) === 0
        );

        if (addOnListIndex === -1) {
          draft[modifierIndex]["modifierId"] = "";
        } else {
          draft[modifierIndex]["modifierId"] =
            addOnList[addOnListIndex].modifierId;
        }

        if (draft[modifierIndex]["modifierName"] !== e.target.value) {
          draft[modifierIndex]["modifierName"] = e.target.value;
        }
      }
    });

    setOptions(updatedOptions);
  };

  const handleModifierPriceChange = (e, modifierIndex) => {
    let value = null;
    if (e.target.value === " ") {
      value = "";
    } else {
      value = e.target.value;
    }

    let updatedOptions = produce(options, (draft) => {
      draft[modifierIndex][e.target.name] = value;
    });

    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    let updatedOptions = produce(options, (draft) => {
      draft.push({
        availableStatus: "available",
        modifierId: "",
        modifierName: "",
        price: "",
      });
    });

    setOptions(updatedOptions);
  };

  const handleItemsAvailCheckboxChange = (e) => {
    setIsItemsControlAvailable(e.target.checked);
    if (e.target.checked) {
      handleCleanState("add-on-options");
    }
  };

  const [addOnList, setAddOnList] = useState([]);
  const [addOnListIsLoading, setAddOnListIsLoading] = useState(false);
  const fetchModifierItems = async () => {
    try {
      setAddOnListIsLoading(true);
      let params = {
        limit: 10000,
        filter: {},
        merchantId: merchantInfoContext.merchantId,
        nextToken: 0,
        sort: {
          field: "updatedAt",
          direction: "desc",
        },
      };
      let res = null;
      res = await API.graphql(graphqlOperation(kdsListModifierItems, params));

      if (res.data.kdsListModifierItems.total > 0) {
        let tmpAddOnList = produce([], (draft) => {
          res.data.kdsListModifierItems.modifierItemList.map((obj, index) =>
            draft.push({ label: obj.modifierName, modifierId: obj.modifierId })
          );
        });

        setAddOnList(tmpAddOnList);
        setAddOnListIsLoading(false);
      }
    } catch (error) {
      setAddOnListIsLoading(false);
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  const getBack = (step) => {
    switch (step) {
      case "add-on-type":
        setShowProductModifier(false);
        handleCleanState(step);
        return;

      case "add-on-name":
        if (modifierGroupIdToEdit) {
          setShowProductModifier(false);
          handleCleanState("end");
        } else {
          setStep("add-on-type");
          handleCleanState(step);
        }

        return;

      case "add-on-options":
        setStep("add-on-name");
        handleCleanState(step);
        return;

      case "add-on-selection-range":
        setStep("add-on-options");
        handleCleanState(step);
        return;

      default:
        return;
    }
  };

  const getTitle = (step) => {
    switch (step) {
      case "add-on-type":
        return translate("product-modifier-wizard.add-on-type-title");

      case "add-on-name":
        return translate("product-modifier-wizard.add-on-name-title");

      case "add-on-options":
        return translate("product-modifier-wizard.add-on-options-title");

      case "add-on-selection-range":
        return translate(
          "product-modifier-wizard.add-on-selection-range-title"
        );

      default:
        return "";
    }
  };

  const getBody = (step) => {
    switch (step) {
      case "add-on-type":
        return (
          <>
            <label className="product-modifier-wizard-body-label">
              {translate("product-modifier-wizard.add-on-type-body-label")}
            </label>
            <div className="product-modifier-wizard-body-content add-on-type">
              <OutlinedButton
                title={translate("product-modifier-wizard.add-on-type-choice")}
                disableRipple={true}
                handleAction={() => handleSelectAddOnType("choice")}
                className="add-on-type-button"
              />
              <OutlinedButton
                title={translate("product-modifier-wizard.add-on-type-text")}
                disableRipple={true}
                handleAction={() => handleSelectAddOnType("text")}
                className="add-on-type-button"
              />
            </div>
          </>
        );

      case "add-on-name":
        return (
          <>
            <label className="product-modifier-wizard-body-label">
              {translate("product-modifier-wizard.add-on-name-body-label")}
            </label>
            <div className="product-modifier-wizard-body-content add-on-name">
              <CustomInputBase
                name="modifierGroupName"
                borderAttached={true}
                value={modifierGroupName}
                onChange={handleInputBaseChange}
                placeholder={
                  modifierGroupType === "choice"
                    ? translate(
                        "product-modifier-wizard.add-on-name-choice-input-placeholder"
                      )
                    : translate(
                        "product-modifier-wizard.add-on-name-text-input-placeholder"
                      )
                }
              />
              {modifierGroupType === "choice" && (
                <div className="items-avail-control-checkbox-section">
                  <Checkbox
                    checked={isItemsControlAvailable}
                    onChange={handleItemsAvailCheckboxChange}
                  />
                  <label>
                    {translate("product-modifier-wizard.items-avail-control")}
                  </label>
                </div>
              )}
            </div>
            <div className="footer-section">
              <ContainedButton
                startIcon={null}
                title={translate("product-modifier-wizard.next")}
                handleAction={() => handleNext(step)}
                className="product-modifier-wizard-footer-button"
              />
            </div>
          </>
        );

      case "add-on-options":
        return (
          <>
            <label className="product-modifier-wizard-body-label">
              {translate("product-modifier-wizard.add-on-options-body-label")}
            </label>
            <div className="product-modifier-wizard-body-content add-on-options">
              <div className="option-row">
                <div className="name-section">
                  <label>{translate("product-modifier-wizard.add-on")}</label>
                </div>
                <div className="price-section">
                  <label>{translate("product-modifier-wizard.price")}</label>
                </div>
              </div>
              {options.map((o, i) => (
                <div className="option-row" key={i}>
                  <div className="name-section">
                    <Autocomplete
                      id={`add-on-name-${i}`}
                      className="modifier-name-autocomplete"
                      freeSolo
                      options={addOnList.map((option) => option.label)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={translate(
                            "product-modifier-wizard.add-on-option-name-input-placeholder"
                          )}
                        />
                      )}
                      value={o.modifierName}
                      onChange={(e, value) =>
                        handleModifierNameChange(value, i)
                      }
                      onBlur={(e) => handleModifierNameOnBlur(e, i)}
                      filterSelectedOptions
                      filterOptions={(arr) =>
                        arr.filter(
                          (a) =>
                            options.findIndex(
                              (o) =>
                                o.modifierName.localeCompare(a, undefined, {
                                  sensitivity: "accent",
                                }) === 0
                            ) === -1
                        )
                      }
                      loading={addOnListIsLoading}
                    />
                  </div>
                  <div className="price-section">
                    <CustomInputBase
                      name="price"
                      borderAttached={true}
                      value={inputCurrency(o.price)}
                      onChange={(e) => handleModifierPriceChange(e, i)}
                      startAdornment={
                        <InputAdornment position="start">
                          {merchantInfoContext.currency}
                        </InputAdornment>
                      }
                      type="number"
                    />
                    {options.length > 1 && (
                      <IconButton
                        color="error"
                        onClick={() => handleConfirmDeleteOption(i)}
                      >
                        <DeleteOutlined />
                      </IconButton>
                    )}
                  </div>
                </div>
              ))}
              <CustomButton
                disableRipple
                onClick={() => handleAddOption()}
                className="more-add-ons-button"
                startIcon={<Add />}
                variant="outlined"
              >
                {translate("product-modifier-wizard.more-add-ons")}
              </CustomButton>
            </div>
            <div className="footer-section">
              <CustomButton
                disableRipple
                onClick={() => handleNext(step)}
                className="product-modifier-wizard-footer-button"
                variant="contained"
              >
                {translate("product-modifier-wizard.next")}
              </CustomButton>
            </div>
          </>
        );

      case "add-on-selection-range":
        return (
          <>
            <div className="product-modifier-wizard-body-content add-on-selection-range">
              <label className="product-modifier-wizard-body-label">
                {translate(
                  "product-modifier-wizard.add-on-selection-range-min-body-label"
                )}
              </label>
              <Select
                value={minSelection}
                onChange={handleModifierSelectionRangeChange}
                name="minSelection"
                className="selection-range-component"
              >
                {_.range(options.length + 1)
                  .map((value) => ({ value: value, label: value }))
                  .map((selection) => (
                    <MenuItem value={selection.value} key={selection.value}>
                      {selection.label}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <div className="product-modifier-wizard-body-content add-on-selection-range">
              <label className="product-modifier-wizard-body-label">
                {translate(
                  "product-modifier-wizard.add-on-selection-range-max-body-label"
                )}
              </label>
              <Select
                value={maxSelection}
                onChange={handleModifierSelectionRangeChange}
                name="maxSelection"
                className="selection-range-component"
              >
                {_.range(!!minSelection ? minSelection : 1, options.length + 1)
                  .map((value) => ({ value: value, label: value }))
                  .map((selection) => (
                    <MenuItem value={selection.value} key={selection.value}>
                      {selection.label}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <div className="footer-section">
              <ContainedButton
                startIcon={null}
                title={translate("product-modifier-wizard.next")}
                handleAction={() => handleNext(step)}
                className="product-modifier-wizard-footer-button"
              />
            </div>
          </>
        );

      default:
        return <></>;
    }
  };

  const handleModifierSelectionRangeChange = (e) => {
    if (e.target.name === "minSelection") {
      setMinSelection(e.target.value);
      if (!maxSelection || maxSelection < e.target.value) {
        setMaxSelection(e.target.value);
      }
    } else if (e.target.name === "maxSelection") {
      setMaxSelection(e.target.value);
    }
  };

  const handleSelectAddOnType = (type) => {
    setModifierGroupType(type);
    setStep("add-on-name");
  };

  const handleNext = (step) => {
    switch (step) {
      case "add-on-name":
        if (validator.isEmptyString(modifierGroupName)) {
          setSnackbar({
            snackbarMessage: translate(
              "product-modifier-wizard.add-on-name-required-error"
            ),
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
          return;
        }

        if (modifierGroupType === "choice") {
          setStep("add-on-options");
          if (isItemsControlAvailable) {
            fetchModifierItems();
          }
        } else if (modifierGroupType === "text") {
          updateModifierGroupList(modifierGroupType);
        }

        return;

      case "add-on-options":
        for (let option in options) {
          if (
            validator.isEmptyString(options[option].modifierName) ||
            validator.isEmptyString(options[option].price) ||
            options[option].price < 0
          ) {
            setSnackbar({
              snackbarMessage: translate(
                "product-modifier-wizard.add-on-option-required-error"
              ),
              snackbarOpen: true,
              snackbarSeverity: "error",
            });
            return;
          } else if (!validator.isPrice(inputCurrency(options[option].price))) {
            setSnackbar({
              snackbarMessage: translate("add-product.price-required-error"),
              snackbarOpen: true,
              snackbarSeverity: "error",
            });
            return;
          }
        }

        setStep("add-on-selection-range");
        return;

      case "add-on-selection-range":
        if (
          validator.isEmptyString(minSelection) ||
          validator.isEmptyString(maxSelection) ||
          minSelection < 0 ||
          maxSelection < 1 ||
          maxSelection < minSelection ||
          maxSelection > options.length
        ) {
          setSnackbar({
            snackbarMessage: getSelectionRangeError(),
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
          return;
        }

        updateModifierGroupList(modifierGroupType);
        return;

      default:
        return;
    }
  };

  const getSelectionRangeError = () => {
    if (validator.isEmptyString(minSelection)) {
      return translate(
        "product-modifier-wizard.add-on-min-selection-required-error"
      );
    } else if (validator.isEmptyString(maxSelection)) {
      return translate(
        "product-modifier-wizard.add-on-max-selection-required-error"
      );
    } else if (minSelection < 0) {
      return translate(
        "product-modifier-wizard.add-on-min-selection-less-than-0-error"
      );
    } else if (maxSelection < 1) {
      return translate(
        "product-modifier-wizard.add-on-max-selection-less-than-1-error"
      );
    } else if (maxSelection < minSelection) {
      return translate(
        "product-modifier-wizard.add-on-max-selection-less-than-min-selection-error"
      );
    } else if (maxSelection > options.length) {
      return translate(
        "product-modifier-wizard.add-on-max-selection-more-than-options-error"
      );
    } else {
      return translate(
        "product-modifier-wizard.add-on-selection-range-unknown-error"
      );
    }
  };

  const handleCleanState = (step) => {
    switch (step) {
      case "add-on-type":
        setModifierGroupType();
        return;

      case "add-on-name":
        setModifierGroupName("");
        return;

      case "add-on-options":
        setOptions([
          {
            availableStatus: "available",
            modifierId: "",
            modifierName: "",
            price: "",
          },
        ]);
        return;

      case "add-on-selection-range":
        setMinSelection();
        setMaxSelection();
        return;

      case "end":
        setModifierGroupType();
        setModifierGroupName("");
        setOptions([
          {
            availableStatus: "available",
            modifierId: "",
            modifierName: "",
            price: "",
          },
        ]);
        setMinSelection();
        setMaxSelection();
        setModifierGroupIdToEdit("");
        setIsItemsControlAvailable(true);
        return;

      default:
        return;
    }
  };

  const updateModifierGroupList = (type) => {
    let updatedModifierGroupList = [...modifierGroupList];
    let newModifierGroup = {
      modifierGroupName: modifierGroupName,
      modifierGroupType: type,
      requestRemark: null,
      isItemsControlAvailable: isItemsControlAvailable,
    };

    if (type === "text") {
      newModifierGroup["modifier"] = [];
      newModifierGroup["selectionRangeMax"] = maxSelection;
      newModifierGroup["selectionRangeMin"] = minSelection;
    } else if (type === "choice") {
      let tmpOptions = produce(options, (draft) => {
        if (!isItemsControlAvailable) {
          for (let i in draft) {
            if (!draft[i].modifierId) {
              draft[i].modifierId = uuid();
            }
          }
        }
      });
      newModifierGroup["modifier"] = tmpOptions;
      newModifierGroup["selectionRangeMax"] = maxSelection;
      newModifierGroup["selectionRangeMin"] = minSelection;
    }

    if (!modifierGroupIdToEdit) {
      newModifierGroup["modifierGroupId"] = uuid();
      updatedModifierGroupList.push(newModifierGroup);
    } else {
      newModifierGroup["modifierGroupId"] = modifierGroupIdToEdit;
      let index = updatedModifierGroupList.findIndex(
        (o) => o.modifierGroupId === modifierGroupIdToEdit
      );
      updatedModifierGroupList.splice(index, 1, newModifierGroup);
    }

    setModifierGroupList(updatedModifierGroupList);
    if (isProductCreate) {
      handleProductCreate();
    }
    setShowProductModifier(false);
    handleCleanState("end");
    setStep("add-on-name");
  };

  const checkMode = () => {
    if (modifierGroupIdToEdit) {
      let referenceModifierGroup = modifierGroupList.filter(
        (modifierGroup) =>
          modifierGroup.modifierGroupId === modifierGroupIdToEdit
      );

      setModifierGroupName(referenceModifierGroup[0].modifierGroupName);
      setOptions(referenceModifierGroup[0].modifier);
      setMinSelection(referenceModifierGroup[0].selectionRangeMin);
      setMaxSelection(referenceModifierGroup[0].selectionRangeMax);
      setModifierGroupType(referenceModifierGroup[0].modifierGroupType);
      setIsItemsControlAvailable(
        referenceModifierGroup[0].isItemsControlAvailable
      );
      setStep("add-on-name");
    }
  };

  useEffect(() => {
    checkMode();
  }, []);

  return (
    <div className="product-modifier-wizard-wrapper">
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      <div className="header-section">
        <IoArrowBackSharp
          className="backbutton-styling"
          onClick={() => getBack(step)}
        />
        <h1 className="product-modifier-wizard-title">{getTitle(step)}</h1>
      </div>
      <div className="body-section">{getBody(step)}</div>
      <ConfirmDeleteDialog
        openDialog={openConfirmDeleteOptionDialog}
        subject={translate("product-detail-page.add-on-singular")}
        handleCloseDialog={handleCloseConfirmDeleteOptionDialog}
        deleteFunction={() => handleRemoveOption(optionIndexToDelete)}
      />
    </div>
  );
}
