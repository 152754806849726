import React,{useEffect,useState,useContext} from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {IoArrowBackSharp} from 'react-icons/io5';
import { useTranslate } from "react-polyglot";
import { MerchantContext } from "../../../context/MerchantContext";
import { CustomizedSnackbars } from '../../../components/shareable/Shareable';
import { CustomLoadingButton } from "../../../components/buttons/Buttons";

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import { adminUpdateMerchantGeneralConfig } from '../../../graphql/mutations';
import {
    adminGetMerchantGeneralConfig,
} from "../../../graphql/queries";

function CurrentStoreStatus(props) {
    const translate = useTranslate();
    const [storeData, setStoreData] = useState("");
    const [status,setStatus] = useState(storeData.warungStoreStatus)
    const onStatusChange = (status) => {
        setStatus(status)
      };
    const merchantInfoContext = useContext(MerchantContext);
    const [storeIsLoading, setStoreIsLoading] = useState(false);
    const [saveIsLoading,setSaveIsLoading] = useState(false);

    const renderStyleOpen = (text) => {
        if(text===status) {
            return {
				backgroundColor: "#e8f1ed",
                border: "1px solid #b8ddba",
                color: "#73ae76"
			};
        } else {
            return {
				backgroundColor: "#ffffff",
                border: "1px solid #ffffff",
                color: "#929da9"
			};
        }
    }
    
    const renderStyleClose = (text) => {
        if(text===status) {
            return {
				backgroundColor: "#f3e6e6",
                border: "1px solid #f1c3bf",
                color: "#ed7669"
			};
        } else {
            return {
				backgroundColor: "#ffffff",
                border: "1px solid #ffffff",
                color: "#929da9"
			};
        }
    }
    
    
    const [snackbar, setSnackbar] = useState({
      snackbarMessage: "",
      snackbarOpen: false,
      snackbarSeverity: "info",
    });
    
    const fetchStoreData = async (
    ) => {
        setStoreIsLoading(true);
        try {
            let res = await API.graphql(graphqlOperation(adminGetMerchantGeneralConfig));

            setStoreData(res.data.adminGetMerchantGeneralConfig);
            setStoreIsLoading(false);
        } catch (error) {
            setStoreIsLoading(false);
        }
    };
    
    const checkForStoreData = () => {
        if(storeData) {
            setCurrentStatus()
        } else {
            setSnackbar({
                snackbarMessage: translate("error-handler.unknown-error"),
                snackbarOpen: true,
                snackbarSeverity: "error",
              });
        }
    }
    
    const setCurrentStatus = async () => {
      setSaveIsLoading(true);
      try {
        let params = {
              kycBusinessAddress: storeData.kycBusinessAddress,
              kycNRIC: storeData.kycNRIC,
              kycNRICName: storeData.kycNRICName,
              address: storeData.address,
              currency: storeData.currency,
              merchantRegistrationNumber: storeData.merchantRegistrationNumber,
              name: storeData.name,
              notificationEmail: storeData.notificationEmail,
              ownerEmail: storeData.ownerEmail,
              ownerPhone: storeData.ownerPhone,
              senderEmail: storeData.senderEmail,
              orderOption: storeData.orderOption,
              tax: parseInt(storeData.tax),
              warungStoreStatus: status,
              whatsappNo: storeData.whatsappNo
        };

        const respOrderFulfillment = await API.graphql(
          graphqlOperation(adminUpdateMerchantGeneralConfig, params)
        );
        
        if (respOrderFulfillment.data.adminUpdateMerchantGeneralConfig.status === "true") {
         setSnackbar({
            snackbarMessage: respOrderFulfillment.data.adminUpdateMerchantGeneralConfig.message,
            snackbarOpen: true,
            snackbarSeverity: "success",
          });
        } else {
          setStatus(storeData.warungStoreStatus)
          setSnackbar({
            snackbarMessage: respOrderFulfillment.data.adminUpdateMerchantGeneralConfig.message,
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
        }
        setSaveIsLoading(false);
      } catch (error) {
        console.log(error)
        setSaveIsLoading(false);
      }

    }
    
    useEffect(()=> {
        fetchStoreData()
    },[])
    
    useEffect(() => {
        setStatus(storeData.warungStoreStatus)
    }, [storeData])
    
    const renderStatusOptions = () => {
        if(storeData) {
            return (
                <div style={{marginRight:"16px"}}>
                    <div className="currentstatus-select-button" style={renderStyleOpen("open")} onClick={(e) => onStatusChange("open")}> 
                        <p> {translate("current-store-status.open")} </p>
                    </div>
                    <div className="currentstatus-select-button" style={renderStyleClose("closed")} onClick={(e) => onStatusChange("closed")}> 
                        <p>{translate("current-store-status.close")} </p>
                    </div>
                </div>
            )
        } else {
            return (
                 <Box className='center-loader' sx={{ display: 'flex' }}>
                    <CircularProgress centered/>
                </Box>
              ) 
        }
    }


    return (
        <div>
            <Header page={"setting"}></Header>
            <CustomizedSnackbars
              message={snackbar.snackbarMessage}
              snackbarOpen={snackbar.snackbarOpen}
              snackbarClose={() =>
                  setSnackbar({
                  ...snackbar,
                  snackbarOpen: false,
                  snackbarSeverity: snackbar.snackbarSeverity,
                  })
              }
              severity={snackbar.snackbarSeverity}
          />
                <div className='body body-bg body-padding'>
                    <div className="current-store-page-wrapper">
                        <Link to="/kds/settings">
                            <IoArrowBackSharp className='backbutton-styling'></IoArrowBackSharp>
                        </Link>
                        <h1 className='orderhome-orderinfo-extralarge'>{translate("current-store-status.title")}</h1> 
                        <h1 className='orderhome-orderinfo-small'>{translate("current-store-status.desc")}</h1>
                        {renderStatusOptions()}
                        
                    </div>
                    <div className='orderview-single-button-box' style={{marginTop:"20px"}}>
                    <CustomLoadingButton
                        className="orderview-confirm-button"
                        onClick={() => checkForStoreData()}
                        loading={saveIsLoading}
                        loadingPosition="start"
                        disabled={saveIsLoading}
                        variant={saveIsLoading ? "outlined" : "contained"}
                      >
                    {translate("settings-home-page.save")}
                  </CustomLoadingButton>
                </div>
                </div>
                <Footer page={"setting"}></Footer> 
        </div>
    );
}

export default CurrentStoreStatus;