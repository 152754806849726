import React from 'react';
import { useTranslate } from "react-polyglot";
import image from '../../assets/images/pagenotfound.png';
import "./PageNotFound.scss";

function PageNotFound() {
    const translate = useTranslate();
    return (
        <div className="pagenotfound-box">
            <h1 className="pagenotfound-header-title">404</h1>
            <h1 className="pagenotfound-header-text">{translate("pagenotfound.title")}</h1>
            <div className="pagenotfound-image-box">
               <img className="img-fluid" src={image}></img>
            </div>
            <h1 className="pagenotfound-text">{translate("pagenotfound.text")}</h1>
        </div>
    )
}

export default PageNotFound;