import { useState, useContext } from 'react';
import {useHistory,Link} from 'react-router-dom';
import Footer from '../../../../components/footer/Footer';
import Header from '../../../../components/header/Header';
import {VscError} from 'react-icons/vsc';
import { useTranslate } from "react-polyglot";

function TopUpPage(props) {
    const { pageType } = props
    const history = useHistory();
    const translate = useTranslate();

    return (
        <div className="top-up-page-wrapper">
            <Header/>
            <div>
                  <div className='center-screen'>
                      <div>
                        {pageType === "subscription" ? (
                          <h1 className='ordercomplete-title'>{translate("top-up-page.subscribe-error")}</h1> 
                          ): (
                            <h1 className='ordercomplete-title'>{translate("top-up-page.top-up-error")}</h1> 
                          )}
                          <div className='ordercomplete-icon-holder'>
                              <VscError style={{color:"#E74C3C",fontSize:"7rem"}}></VscError>
                          </div>
                          {pageType === "subscription" ? (
                            <Link style={{textDecoration:"none"}} to="/kds/settings/subscription-history">
                                <div className='orderview-single-button-box'>
                                    <button className='ordercomplete-link'>{translate("top-up-page.back-to-subscription-history")}</button>
                                </div>
                            </Link>
                          ) : (
                           <Link style={{textDecoration:"none"}} to="/kds/settings/topup">
                              <div className='orderview-single-button-box'>
                                  <button className='ordercomplete-link'>{translate("top-up-page.back-to-topup")}</button>
                              </div>
                          </Link>
                          )}
                      </div>
                  </div>
              </div>
        </div>
    );
}

export default TopUpPage;