import './App.scss';
import Path from './Path';
import { I18n } from "react-polyglot";
import { i18nTranslatedMessage } from "./localization/localization";
import { appState } from "./states/appState";
import { useGlobalState } from "./states/globalState";

function App() {
  const [lang] = useGlobalState(appState, (state) => state.locale);
  
  return (
    <I18n locale={lang} messages={i18nTranslatedMessage(lang)}>
      <div className="app-body">
        <Path></Path>
      </div>
    </I18n>
  );
}

export default App;
