import { Auth } from "aws-amplify";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslate } from "react-polyglot";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../../components/header/Header";
import { CustomizedSnackbars } from "../../../components/shareable/Shareable";
import { SHARED_ERROR_MESSAGE } from "../../../constants";
import { setLocale } from "../../../localization/localization";
import { ACTIONS, appState } from "../../../states/appState";
import { useGlobalState } from "../../../states/globalState";
import { validator } from "../../../utils";
import { gatewayUrl } from "../../../utils/shareable-utils";
import AuthFields from "./components/AuthFields";
import ForceChangePassword from "./components/ForceChangePassword";
import "./LoginScreen.scss";

function LoginScreen(props) {
  const history = useHistory();
  const location = useLocation();
  const [loginToken, setLoginToken] = useState(null);
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [userCountry, setUserCountry] = useState(0);
  const [prefix, setPrefix] = useState("+60");
  const [loginWithMobile, setLoginWithMobile] = useState(true);
  const [isForgot, setForgot] = useState(false);
  const [user, setUser] = useState({});
  const [mustChangePassword, setMustChangePassword] = useState(false);
  const [mustResetPassword, setMustResetPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMFARequired, setMFARequired] = useState(false);
  const [isMFASetup, setIsMFASetup] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [MFAType, setMFAType] = useState(null);
  const [MFACode, setMFACode] = useState("");
  const [verifyMFAError, setVerifyMFAError] = useState({});
  const [qrMFA, setQrMFA] = useState("");
  const [secretCode, setSecretCode] = useState("");
  const [isSend, setSend] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");
  const [loginValidation, setLoginValidation] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({ cognito: null, blankfield: false });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [severity, setSeverity] = useState(false);
  const translate = useTranslate();

  const [lang, dispatch] = useGlobalState(appState, (state) => state.locale);
  const onLanguageChange = (language, label) => {
    window.location.reload();
    setLocale(language);
    localStorage.setItem("languageLabel", label);
    dispatch(ACTIONS.onLanguageChange, language);
  };

  const countries = [
    {
      validation: "(1)[0-46-9]-*[0-9]{7,8}|[0-9]{8,10}",
      placeholder: "12345 678 or 321234567",
      code: "+60",
      emoji: "🇲🇾",
      id: 0,
    },
    {
      validation: "[0-9]{8,8}|[0-9]{8,8}",
      placeholder: "12345678",
      code: "+65",
      emoji: "🇸🇬",
      id: 1,
    },
  ];

  useEffect(() => {
    setPrefix(countries[userCountry].code);
  }, [userCountry]);

  function handleEmailChange(event) {
    setEmail(event);
  }
  function handlePasswordChange(event) {
    setPassword(event);
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin();
    }
  }

  async function handleLogin() {
    setLoginValidation(false);
    setMessage(false);

    if (loginWithMobile) {
      let validator = new RegExp(countries[userCountry].validation);
      if (!validator.test(mobileNo)) {
        setLoginValidation(true);
        setErrors({ ...errors, blankfield: false });
        setSnackbarMessage(
          translate("login-handler.invalid-mobile-number-format")
        );
        setSeverity("error");
        setSnackbarOpen(true);
        return;
      }
    }

    if (
      ((!loginWithMobile && !validator.isEmptyString(email)) ||
        (loginWithMobile && !validator.isEmptyString(mobileNo))) &&
      !validator.isEmpty(password)
    ) {
      try {
        const username = loginWithMobile
          ? prefix + mobileNo
          : email.toLowerCase();
        if (!isForgot) {
          setIsLoading(true);
          const user = await Auth.signIn(username, password);
          setUser(user);
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            // setShowGuide(true)
            setUser(user);
            setIsLoading(false);
            setMustChangePassword(true);
          } else {
            if (
              user.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes("AxrailAdmin")
            ) {
              history.push("/kds/select-merchant");
            } else {
              window.location.href = "/";
              setIsLoading(false);
              props.setAlertMsgControl("show");
            }
          }
        } else {
          if (confirmPassword === password) {
            if (password.length > 7) {
              // let username = email.toLowerCase();
              let new_password = password;
              let verification_code = code;
              // Collect confirmation code and new password, then
              Auth.forgotPasswordSubmit(
                username,
                verification_code,
                new_password
              )
                .then((data) => {
                  setForgot(false);
                  setPassword("");
                  setConfirmPassword("");
                  setTimeout(() => {
                    setLoginValidation(true);
                    setErrors({ ...errors, blankfield: true });
                    setSnackbarMessage(
                      translate("login-handler.reset-password-success")
                    );
                    setSeverity("success");
                    setSnackbarOpen(true);
                  }, 500);
                })
                .catch((err) => {
                  console.log(err);
                  setErrors({ ...errors, blankfield: false });
                  setLoginValidation(true);
                  if (err.code === "UserNotFoundException") {
                    setMessage(
                      translate("login-handler.invalid-verification-code")
                    );
                  } else {
                    setMessage(err.message);
                  }
                });
            } else {
              setErrors({ ...errors, blankfield: false });
              setLoginValidation(true);
              setMessage(translate("login-handler.password-must-be-less"));
            }
          } else {
            setErrors({ ...errors, blankfield: false });
            setLoginValidation(true);
            setMessage(
              translate("login-handler.confirmation-password-no-match")
            );
          }
        }
      } catch (error) {
        console.log(error);
        setLoginValidation(true);
        let err = null;
        if (error.code === "PasswordResetRequiredException") {
          setLoginValidation(true);
          setMustResetPassword(false);
          setSnackbarMessage(error.message);
          setSeverity("info");
          setSnackbarOpen(true);
          setForgot(true);
          // setCode(password)
          setCode("");
          setPassword("");
          setConfirmPassword("");
          setIsLoading(false);
        } else if (error.message === "Password attempts exceeded") {
          setLoginValidation(true);
          setSnackbarMessage(
            translate("login-handler.exceeded-password-attempts")
          );
          setSeverity("error");
          setSnackbarOpen(true);
          setErrors({ ...errors, blankfield: false });
          setIsLoading(false);
          // localStorage.removeItem(email)
        } else if (error.message === "User is disabled.") {
          // localStorage.removeItem(email)
          setLoginValidation(true);
          setSnackbarMessage(
            translate("login-handler.exceeded-password-attempt")
          );
          setSeverity("error");
          setSnackbarOpen(true);
          setIsLoading(false);
        } else if (
          error.message ===
          "Cannot read properties of undefined (reading 'includes')"
        ) {
          //At this error checkpoint, the user can continue login process as a merchant
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            setUser(user);
            setIsLoading(false);
            setMustChangePassword(true);
          } else {
            window.location.href = "/";
            setIsLoading(false);
            props.setAlertMsgControl("show");
          }
        } else if (error.code === "UserLambdaValidationException") {
          setLoginValidation(true);
          setSnackbarMessage(
            translate("login-handler.cannot-have-more-than-one-session")
          );
          setSeverity("error");
          setSnackbarOpen(true);
          setIsLoading(false);
        } else if (error.code === "UserNotFoundException") {
          setLoginValidation(true);
          setSnackbarMessage(
            translate("login-handler.incorrect-password-username")
          );
          setSeverity("error");
          setSnackbarOpen(true);
          setIsLoading(false);
        } else {
          setLoginValidation(true);
          setSnackbarMessage(error.message);
          setSeverity("error");
          setSnackbarOpen(true);
          setIsLoading(false);
        }
      }
    } else {
      setLoginValidation(true);
      setErrors({ ...errors, blankfield: false });
      setSnackbarMessage(translate("login-handler.all-fields-required"));
      setSeverity("error");
      setSnackbarOpen(true);
    }
  }

  async function handleForgetPassword() {
    setLoginValidation(false);
    setMessage(false);
    setPassword("");
    setConfirmPassword("");
    setCode("");

    if (
      loginWithMobile &&
      !validator.isValidCognitoPhoneNumber(prefix + mobileNo)
    ) {
      setLoginValidation(true);
      setErrors({ ...errors, blankfield: false });
      setSnackbarMessage(
        translate("login-handler.invalid-mobile-number-format")
      );
      setSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (!loginWithMobile && !validator.isEmptyString(email)) {
      try {
        setIsLoading(true);
        const username = loginWithMobile
          ? prefix + mobileNo
          : email.toLowerCase();

        await Auth.forgotPassword(username)
          .then((data) => {
            setLoginValidation(true);
            setSeverity("success");
            setSnackbarMessage(
              translate("login-handler.validation-code-has-been") +
                data.CodeDeliveryDetails.Destination +
                "." +
                translate("login-handler.check-your-spam")
            );
            setSnackbarOpen(true);
            setTimeout(() => {
              setForgot(true);
              setSend(false);
              setLoginValidation(false);
              setIsLoading(false);
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
            setLoginValidation(true);
            if (
              err.code !== "UserNotFoundException" &&
              err.code !== "LimitExceededException"
            ) {
              setErrors({ ...errors, blankfield: true });
              setSeverity("success");
              setSnackbarMessage(err.message);
              setSnackbarOpen(true);
              setTimeout(() => {
                setForgot(true);
                setSend(false);
                setLoginValidation(false);
                setIsLoading(false);
              }, 1500);
            } else {
              setErrors({ ...errors, blankfield: false });
              setIsLoading(false);
              if (err.code === "UserNotFoundException") {
                setSnackbarMessage(translate("login-handler.user-not-found"));
              } else {
                setSnackbarMessage(err.message);
              }
              setSeverity("error");
              setSnackbarOpen(true);
            }
          });
      } catch (err) {
        console.log(err);
        setLoginValidation(true);
        setErrors({ ...errors, blankfield: false });
        setSnackbarMessage(err.message);
        setSeverity("error");
        setSnackbarOpen(true);
      }
    } else if (loginWithMobile && !validator.isEmptyString(mobileNo)) {
      setIsLoading(true);
      const username = loginWithMobile
        ? prefix + mobileNo
        : email.toLowerCase();

      const params = {
        mobileNo: username,
      };

      axios
        .post(`${gatewayUrl}/merchant/adminforgotpassword`, params)
        .then((res) => {
          setLoginValidation(true);
          setSeverity("success");
          setSnackbarMessage(
            translate("login-handler.temporary-password-sent")
          );
          setSnackbarOpen(true);
          setTimeout(() => {
            setForgot(false);
            setSend(false);
            setLoginValidation(false);
            setIsLoading(false);
          }, 1500);
        })
        .catch((err) => {
          setLoginValidation(true);
          if (err.response) {
            // Request made and server responded
            setErrors({ ...errors, blankfield: false });
            setIsLoading(false);
            setSeverity("error");
            setSnackbarMessage(err.response.data);
            setSnackbarOpen(true);
          } else if (err.request) {
            // The request was made but no response was received
            setErrors({ ...errors, blankfield: false });
            setIsLoading(false);
            setSeverity("error");
            setSnackbarMessage(err.request);
            setSnackbarOpen(true);
          } else {
            // Something happened in setting up the request that triggered an Error
            setErrors({ ...errors, blankfield: false });
            setIsLoading(false);
            setSnackbarMessage(err.message);
            setSeverity("error");
            setSnackbarOpen(true);
          }
        });
    } else {
      setLoginValidation(true);
      setErrors({ ...errors, blankfield: false });
      setSnackbarMessage(translate("login-handler.field-cannot-be-blank"));
      setSeverity("error");
      setSnackbarOpen(true);
    }
  }

  const verifyLogin = async (token) => {
    setLoginToken(token);
    const params = {
      loginToken: token,
    };

    axios
      .post(`${gatewayUrl}/merchant/adminverifylogin`, params)
      .then((res) => {
        if (res.status === 200) {
          let tokenStatus = res.data.status;
          setMustChangePassword(tokenStatus);
          if (tokenStatus == false) {
            window.location.href = "/login";
          }
        } else {
          setSeverity("error");
          setSnackbarMessage(SHARED_ERROR_MESSAGE.exceptionError);
          setSnackbarOpen(true);
        }
      })
      .catch((err) => {
        if (err.response) {
          // Request made and server responded
          setErrors({ ...errors, blankfield: false });
          setIsLoading(false);
          setSeverity("error");
          setSnackbarMessage(err.response.data);
          setSnackbarOpen(true);
        } else if (err.request) {
          // The request was made but no response was received
          setErrors({ ...errors, blankfield: false });
          setIsLoading(false);
          setSeverity("error");
          setSnackbarMessage(err.request);
          setSnackbarOpen(true);
        } else {
          // Something happened in setting up the request that triggered an Error
          setErrors({ ...errors, blankfield: false });
          setIsLoading(false);
          setSnackbarMessage(err.message);
          setSeverity("error");
          setSnackbarOpen(true);
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("locale") === null) {
      setLocale("en");
      localStorage.setItem("languageLabel", "English");
    }
  });

  useEffect(() => {
    if (location.search && location.search !== "") {
      let searchParams = new URLSearchParams(location.search);
      let token = searchParams.get("s");
      verifyLogin(token);
    }

    if (props.initialMessage) {
      setSnackbarMessage(props.initialMessage);
      setSeverity("error");
      setSnackbarOpen(true);
    }
  }, []);

  const obscureEmail = (email) => {
    const [user, domain] = email.split("@");
    const item = domain.split(".");
    const domain_name = item[0];
    var top_level_domain;
    if (item.length > 2) {
      top_level_domain = item[1] + "." + item[2];
    } else {
      top_level_domain = item[1];
    }
    return `${user[0]}***@${domain_name[0]}***.${top_level_domain}`;
  };

  return (
    <>
      <Header page={"login"}></Header>
      <CustomizedSnackbars
        message={snackbarMessage}
        snackbarOpen={snackbarOpen}
        snackbarClose={setSnackbarOpen}
        severity={severity}
      />
      <div className="container-wrapper">
        <div className="sign-in-title">
          {translate("login-handler.welcome-title")}
        </div>
        <div className="sign-in-description">
          {translate("login-handler.welcome-subtitle")}
        </div>
        <div className="sign-in-fields">
          {!mustChangePassword ? (
            <>
              <AuthFields
                email={email}
                userCountry={userCountry}
                setUserCountry={setUserCountry}
                mobileNo={mobileNo}
                setMobileNo={setMobileNo}
                loginWithMobile={loginWithMobile}
                setLoginWithMobile={setLoginWithMobile}
                password={password}
                confirmPassword={confirmPassword}
                mustResetPassword={mustResetPassword}
                isForgot={isForgot}
                setForgot={setForgot}
                isLoading={isLoading}
                handleForgot={handleForgetPassword}
                setConfirmPassword={setConfirmPassword}
                setPassword={handlePasswordChange}
                setEmail={handleEmailChange}
                loginValidation={loginValidation}
                setLoginValidation={setLoginValidation}
                isSend={isSend}
                setSend={setSend}
                errors={errors}
                message={message}
                handleKeyPress={handleKeyPress}
                handleLogin={handleLogin}
                code={code}
                setCode={setCode}
              />
            </>
          ) : (
            <ForceChangePassword
              user={user}
              newPassword={newPassword}
              setNewPassword={setNewPassword}
              qrMFA={qrMFA}
              setQrMFA={setQrMFA}
              loginToken={loginToken}
              isFirstTimeLogin={loginToken !== null ? true : false}
              setUser={setUser}
              setAlertMsgControl={props.setAlertMsgControl}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default LoginScreen;
