import AppLocaleEN from "./json/app-en.json";
import AppLocaleZH from "./json/app-zh.json";
import AppLocaleBM from "./json/app-bm.json";
import OrderListingScreenENJSON from "./order-module/listing/order-listing-screen-en.json";
import OrderListingScreenZHJSON from "./order-module/listing/order-listing-screen-zh.json";
import OrderListingScreenBMJSON from "./order-module/listing/order-listing-screen-bm.json";
import OrderPendingPaymentScreenENJSON from "./order-module/listing/order-pending-payment-screen-en.json";
import OrderPendingPaymentScreenZHJSON from "./order-module/listing/order-pending-payment-screen-zh.json";
import OrderPendingPaymentScreenBMJSON from "./order-module/listing/order-pending-payment-screen-bm.json";
import OrderReturnRefundScreenENJSON from "./order-module/listing/order-return-refund-screen-en.json";
import OrderReturnRefundScreenZHJSON from "./order-module/listing/order-return-refund-screen-zh.json";
import OrderReturnRefundScreenBMJSON from "./order-module/listing/order-return-refund-screen-bm.json";
import OrderPendingCheckoutScreenENJSON from "./order-module/listing/order-pending-checkout-screen-en.json";
import OrderPendingCheckoutScreenZHJSON from "./order-module/listing/order-pending-checkout-screen-zh.json";
import OrderPendingCheckoutScreenBMJSON from "./order-module/listing/order-pending-checkout-screen-bm.json";
import OrderDetailScreenENJSON from "./order-module/detail/order-detail-screen-en.json";
import OrderDetailScreenZHJSON from "./order-module/detail/order-detail-screen-zh.json";
import OrderDetailScreenBMJSON from "./order-module/detail/order-detail-screen-bm.json";
import RefundOrderENJSON from "./json/refund-order-en.json";
import RefundOrderZHJSON from "./json/refund-order-zh.json";
import RefundOrderBMJSON from "./json/refund-order-bm.json";
import AllProductsENJSON from "./json/all-products-en.json";
import AllProductsZHJSON from "./json/all-products-zh.json";
import AllProductsBMJSON from "./json/all-products-bm.json";
import InventoryListingScreenENJSON from "./inventory-module/listing/inventory-listing-screen-en.json";
import InventoryListingScreenZHJSON from "./inventory-module/listing/inventory-listing-screen-zh.json";
import InventoryListingScreenBMJSON from "./inventory-module/listing/inventory-listing-screen-bm.json";
import CollectionListingScreenENJSON from "./collection-module/listing/collection-listing-screen-en.json";
import CollectionListingScreenZHJSON from "./collection-module/listing/collection-listing-screen-zh.json";
import CollectionListingScreenBMJSON from "./collection-module/listing/collection-listing-screen-bm.json";
import ProductBundleListingScreenENJSON from "./product-bundle-module/listing/product-bundle-listing-screen-en.json";
import ProductBundleListingScreenZHJSON from "./product-bundle-module/listing/product-bundle-listing-screen-zh.json";
import ProductBundleListingScreenBMJSON from "./product-bundle-module/listing/product-bundle-listing-screen-bm.json";

// stock movement listing screen
import TransferListingScreenENJSON from "./transfer-module/listing/transfer-listing-screen-en.json";
import TransferListingScreenZHJSON from "./transfer-module/listing/transfer-listing-screen-zh.json";
import TransferListingScreenBMJSON from "./transfer-module/listing/transfer-listing-screen-bm.json";

// fb live campaign listing screen
import FbLiveCampaignListingScreenENJSON from "./promotion-module/listing/fb-live-campaign-listing-screen-en.json";
import FbLiveCampaignListingScreenZHJSON from "./promotion-module/listing/fb-live-campaign-listing-screen-zh.json";
import FbLiveCampaignListingScreenBMJSON from "./promotion-module/listing/fb-live-campaign-listing-screen-bm.json";

//store listing screen
import StoreListingScreenENJSON from "./store-module/listing/store-listing-screen-en.json";
import StoreListingScreenZHJSON from "./store-module/listing/store-listing-screen-zh.json";
import StoreListingScreenBMJSON from "./store-module/listing/store-listing-screen-bm.json";

//price group listing screen
import PriceGroupListingScreenENJSON from "./price-group-module/listing/price-group-listing-screen-en.json";
import PriceGroupListingScreenZHJSON from "./price-group-module/listing/price-group-listing-screen-zh.json";
import PriceGroupListingScreenBMJSON from "./price-group-module/listing/price-group-listing-screen-bm.json";

import TransitionModalENJSON from "./json/transition-modal-en.json";
import TransitionModalZHJSON from "./json/transition-modal-zh.json";
import TransitionModalBMJSON from "./json/transition-modal-bm.json";
import SearchEngineModifiableENJSON from "./json/search-engine-modifiable-en.json";
import SearchEngineModifiableZHJSON from "./json/search-engine-modifiable-zh.json";
import SearchEngineModifiableBMJSON from "./json/search-engine-modifiable-bm.json";
import CustomerListingScreenEN from "./customer-module/listing/customer-listing-screen-en.json";
import CustomerListingScreenZH from "./customer-module/listing/customer-listing-screen-zh.json";
import CustomerListingScreenBM from "./customer-module/listing/customer-listing-screen-bm.json";
import CustomerDetailScreenEN from "./customer-module/detail/customer-detail-screen-en.json";
import CustomerDetailScreenZH from "./customer-module/detail/customer-detail-screen-zh.json";
import CustomerDetailScreenBM from "./customer-module/detail/customer-detail-screen-bm.json";
import PromocodeLocaleEN from "./json/promocode-en.json";
import PromocodeLocaleZH from "./json/promocode-zh.json";
import PromocodeLocaleBM from "./json/promocode-bm.json";
import ManagePromotionLocaleEN from "./json/manage-promotion-en.json";
import ManagePromotionLocaleZH from "./json/manage-promotion-zh.json";
import ManagePromotionLocaleBM from "./json/manage-promotion-bm.json";
import FacebookStoreEN from "./json/facebook-store-en.json";
import FacebookStoreZH from "./json/facebook-store-zh.json";
import FacebookStoreBM from "./json/facebook-store-bm.json";
import InstagramShoppingEN from "./json/instagram-shopping-en.json";
import InstagramShoppingZH from "./json/instagram-shopping-zh.json";
import InstagramShoppingBM from "./json/instagram-shopping-bm.json";
import LazadaShopeeEN from "./json/lazada-shopee-en.json";
import LazadaShopeeZH from "./json/lazada-shopee-zh.json";
import LazadaShopeeBM from "./json/lazada-shopee-bm.json";
import SideNavigationEN from "./json/side-navigation-bar-en.json";
import SideNavigationZH from "./json/side-navigation-bar-zh.json";
import SideNavigationBM from "./json/side-navigation-bar-bm.json";

// kds
import TopUpPageEN from "./setting-module/detail/top-up-page-en.json";
import TopUpPageBM from "./setting-module/detail/top-up-page-bm.json";
import TopUpPageZH from "./setting-module/detail/top-up-page-zh.json";
import CreditHistoryPageEN from "./setting-module/detail/credit-history-page-en.json";
import CreditHistoryPageBM from "./setting-module/detail/credit-history-page-bm.json";
import CreditHistoryPageZH from "./setting-module/detail/credit-history-page-zh.json";
import PaymentMethodPageEN from "./setting-module/detail/payment-method-page-en.json";
import PaymentMethodPageBM from "./setting-module/detail/payment-method-page-bm.json";
import PaymentMethodPageZH from "./setting-module/detail/payment-method-page-zh.json";
import SettingsHomePageEN from "./setting-module/settings-home-page-en.json";
import SettingsHomePageBM from "./setting-module/settings-home-page-bm.json";
import SettingsHomePageZH from "./setting-module/settings-home-page-zh.json";
import FooterEN from "./footer/footer-en.json";
import FooterBM from "./footer/footer-bm.json";
import FooterZH from "./footer/footer-zh.json";
import OrderHistoryPageEN from "./history-module/order-history-page-en.json";
import OrderHistoryPageBM from "./history-module/order-history-page-bm.json";
import OrderHistoryPageZH from "./history-module/order-history-page-zh.json";
import StoreTypePageEN from "./setting-module/detail/store-type-page-en.json";
import StoreTypePageBM from "./setting-module/detail/store-type-page-bm.json";
import StoreTypePageZH from "./setting-module/detail/store-type-page-zh.json";
import ErrorHandlerEN from "./error-handler-module/error-handler-en.json";
import ErrorHandlerBM from "./error-handler-module/error-handler-bm.json";
import ErrorHandlerZH from "./error-handler-module/error-handler-zh.json";
import CurrentStoreStatusEN from './setting-module/detail/current-status-en.json';
import CurrentStoreStatusBM from './setting-module/detail/current-status-bm.json';
import CurrentStoreStatusZH from './setting-module/detail/current-status-zh.json';

// kds product listing screen
import ProductListingScreenENJSON from "./product-module/listing/product-listing-screen-en.json";
import ProductListingScreenZHJSON from "./product-module/listing/product-listing-screen-zh.json";
import ProductListingScreenBMJSON from "./product-module/listing/product-listing-screen-bm.json";

// kds product detail screen
import ProductDetailPageENJSON from "./product-module/detail/product-detail-page-en.json";
import ProductDetailPageZHJSON from "./product-module/detail/product-detail-page-zh.json";
import ProductDetailPageBMJSON from "./product-module/detail/product-detail-page-bm.json";

import DropzoneENJSON from "./dropzone/dropzone-en.json";
import DropzoneZHJSON from "./dropzone/dropzone-zh.json";
import DropzoneBMJSON from "./dropzone/dropzone-bm.json";
import ProductModifierWizardENJSON from "./product-module/detail/product-modifier-wizard-en.json";
import ProductModifierWizardZHJSON from "./product-module/detail/product-modifier-wizard-zh.json";
import ProductModifierWizardBMJSON from "./product-module/detail/product-modifier-wizard-bm.json";
import OnboardingENJSON from "./onboarding-module/onboarding-page-en.json";
import OnboardingBMJSON from "./onboarding-module/onboarding-page-bm.json";
import OnboardingZHJSON from "./onboarding-module/onboarding-page-zh.json";
import ConfirmDeleteDialogENJSON from "./confirm-delete-dialog/confirm-delete-dialog-en.json";
import ConfirmDeleteDialogBMJSON from "./confirm-delete-dialog/confirm-delete-dialog-bm.json";
import ConfirmDeleteDialogZHJSON from "./confirm-delete-dialog/confirm-delete-dialog-zh.json";
import AddProductENJSON from "./product-module/detail/add-product-en.json";
import AddProductBMJSON from "./product-module/detail/add-product-bm.json";
import AddProductZHJSON from "./product-module/detail/add-product-zh.json";
import SubscriptionHistoryENJSON from "./setting-module/detail/subscription-history-page-en.json";
import SubscriptionHistoryBMJSON from "./setting-module/detail/subscription-history-page-bm.json";
import SubscriptionHistoryZHJSON from "./setting-module/detail/subscription-history-page-zh.json";

import LoginENJSON from "./authentication-module/login-handler-en.json";
import LoginBMJSON from "./authentication-module/login-handler-bm.json";
import LoginZHJSON from "./authentication-module/login-handler-zh.json";

import PageNotFoundENJSON from "./pagenotfound-module/page-not-found-en.json";
import PageNotFoundBMJSON from "./pagenotfound-module/page-not-found-bm.json";
import PageNotFoundZHJSON from "./pagenotfound-module/page-not-found-zh.json";
import StoreOperatingHoursENJSON from "./setting-module/detail/store-operating-hours-page-en.json";
import StoreOperatingHoursBMJSON from "./setting-module/detail/store-operating-hours-page-bm.json";
import StoreOperatingHoursZHJSON from "./setting-module/detail/store-operating-hours-page-zh.json";
import StoreInformationPageENJSON from "./setting-module/detail/store-information-page-en.json";
import StoreInformationPageBMJSON from "./setting-module/detail/store-information-page-bm.json";
import StoreInformationPageZHJSON from "./setting-module/detail/store-information-page-zh.json";

export const getLocale = () => {
  return localStorage.getItem("locale") || "en";
};

export const setLocale = (locale = "en") => {
  return localStorage.setItem("locale", locale);
};

export const i18nTranslatedMessage = (
  (lang = getLocale()) =>
  () => {
    switch (lang) {
      case "zh":
        return {
          ...AppLocaleZH,
          ...CustomerListingScreenZH,
          ...CustomerDetailScreenZH,
          ...PromocodeLocaleZH,
          ...ManagePromotionLocaleZH,
          ...FacebookStoreZH,
          ...InstagramShoppingZH,
          ...LazadaShopeeZH,
          ...OrderListingScreenZHJSON,
          ...OrderPendingPaymentScreenZHJSON,
          ...OrderReturnRefundScreenZHJSON,
          ...OrderPendingCheckoutScreenZHJSON,
          ...OrderDetailScreenZHJSON,
          ...InventoryListingScreenZHJSON,
          ...CollectionListingScreenZHJSON,
          ...ProductBundleListingScreenZHJSON,
          ...TransferListingScreenZHJSON,
          ...RefundOrderZHJSON,
          ...AllProductsZHJSON,
          ...TransitionModalZHJSON,
          ...SearchEngineModifiableZHJSON,
          ...SideNavigationZH,
          ...FbLiveCampaignListingScreenZHJSON,
          ...StoreListingScreenZHJSON,
          ...PriceGroupListingScreenZHJSON,
          // kds
          ...TopUpPageZH,
          ...CreditHistoryPageZH,
          ...PaymentMethodPageZH,
          ...SettingsHomePageZH,
          ...FooterZH,
          ...OrderHistoryPageZH,
          ...StoreTypePageZH,
          ...ErrorHandlerZH,
          ...ProductDetailPageZHJSON,
          ...ProductListingScreenZHJSON,
          ...DropzoneZHJSON,
          ...ProductModifierWizardZHJSON,
          ...CurrentStoreStatusZH,
          ...OnboardingZHJSON,
          ...ConfirmDeleteDialogZHJSON,
          ...AddProductZHJSON,
          ...SubscriptionHistoryZHJSON,
          ...LoginZHJSON,
          ...PageNotFoundZHJSON,
          ...StoreOperatingHoursZHJSON,
          ...StoreInformationPageZHJSON,
        };
      case "bm":
        return {
          ...AppLocaleBM,
          ...CustomerListingScreenBM,
          ...CustomerDetailScreenBM,
          ...PromocodeLocaleBM,
          ...ManagePromotionLocaleBM,
          ...FacebookStoreBM,
          ...InstagramShoppingBM,
          ...LazadaShopeeBM,
          ...OrderListingScreenBMJSON,
          ...OrderPendingPaymentScreenBMJSON,
          ...OrderReturnRefundScreenBMJSON,
          ...OrderPendingCheckoutScreenBMJSON,
          ...OrderDetailScreenBMJSON,
          ...InventoryListingScreenBMJSON,
          ...CollectionListingScreenBMJSON,
          ...ProductBundleListingScreenBMJSON,
          ...TransferListingScreenBMJSON,
          ...RefundOrderBMJSON,
          ...AllProductsBMJSON,
          ...TransitionModalBMJSON,
          ...SearchEngineModifiableBMJSON,
          ...SideNavigationBM,
          ...FbLiveCampaignListingScreenBMJSON,
          ...StoreListingScreenBMJSON,
          ...PriceGroupListingScreenBMJSON,
          // kds
          ...TopUpPageBM,
          ...CreditHistoryPageBM,
          ...PaymentMethodPageBM,
          ...SettingsHomePageBM,
          ...FooterBM,
          ...OrderHistoryPageBM,
          ...StoreTypePageBM,
          ...ErrorHandlerBM,
          ...ProductDetailPageBMJSON,
          ...ProductListingScreenBMJSON,
          ...DropzoneBMJSON,
          ...ProductModifierWizardBMJSON,
          ...CurrentStoreStatusBM,
          ...OnboardingBMJSON,
          ...ConfirmDeleteDialogBMJSON,
          ...AddProductBMJSON,
          ...SubscriptionHistoryBMJSON,
          ...LoginBMJSON,
          ...PageNotFoundBMJSON,
          ...StoreOperatingHoursBMJSON,
          ...StoreInformationPageBMJSON,
        };
      default:
        return {
          ...AppLocaleEN,
          ...CustomerListingScreenEN,
          ...CustomerDetailScreenEN,
          ...PromocodeLocaleEN,
          ...ManagePromotionLocaleEN,
          ...FacebookStoreEN,
          ...InstagramShoppingEN,
          ...LazadaShopeeEN,
          ...OrderListingScreenENJSON,
          ...OrderPendingPaymentScreenENJSON,
          ...OrderReturnRefundScreenENJSON,
          ...OrderPendingCheckoutScreenENJSON,
          ...OrderDetailScreenENJSON,
          ...InventoryListingScreenENJSON,
          ...CollectionListingScreenENJSON,
          ...ProductBundleListingScreenENJSON,
          ...TransferListingScreenENJSON,
          ...RefundOrderENJSON,
          ...AllProductsENJSON,
          ...TransitionModalENJSON,
          ...SearchEngineModifiableENJSON,
          ...SideNavigationEN,
          ...FbLiveCampaignListingScreenENJSON,
          ...StoreListingScreenENJSON,
          ...PriceGroupListingScreenENJSON,
          // kds
          ...TopUpPageEN,
          ...CreditHistoryPageEN,
          ...PaymentMethodPageEN,
          ...SettingsHomePageEN,
          ...FooterEN,
          ...OrderHistoryPageEN,
          ...StoreTypePageEN,
          ...ErrorHandlerEN,
          ...ProductDetailPageENJSON,
          ...ProductListingScreenENJSON,
          ...DropzoneENJSON,
          ...ProductModifierWizardENJSON,
          ...CurrentStoreStatusEN,
          ...OnboardingENJSON,
          ...ConfirmDeleteDialogENJSON,
          ...AddProductENJSON,
          ...SubscriptionHistoryENJSON,
          ...LoginENJSON,
          ...PageNotFoundENJSON,
          ...StoreOperatingHoursENJSON,
          ...StoreInformationPageENJSON,
        };
    }
  }
)();
