import CancelIcon from "@mui/icons-material/Cancel";
import { Skeleton, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useState } from "react";
import { useTranslate } from "react-polyglot";
import { getGeocode, getLatLng, getZipCode } from "use-places-autocomplete";
import { useDebounce } from "../../../../hooks/useDebounce";
import style from "./PlacesAutocompleteInput.module.scss";

const PlacesAutocompleteInput = ({
  inputLabel,
  funcSplitAddress,
  section,
  formikProps,
  resetForm,
  setIsLoadFromInput,
  getUserLocation,
  disabled = false,
  ...placesAutocompleteProps
}) => {
  const translate = useTranslate();
  const [focus, setFocus] = useState(false);
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = placesAutocompleteProps;

  const [filteredData, setFilteredData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const debounceFilterData = useDebounce(data, 1000);

  useEffect(() => {
    if (formikProps.values.address) {
      setValue(formikProps.values.address);
    }
  }, [formikProps.values.address]);

  const handleSelect = async (address) => {
    formikProps.setFieldValue("isLoadFromInput", true);
    clearSuggestions();

    const results = await getGeocode({
      address: address,
      componentRestrictions: { country: "my" },
    });
    const { lat, lng } = await getLatLng(results[0]);
    // formikProps.setFieldValue("selectedLatLng", { lat, lng });
    formikProps.setFieldValue("latitude", lat);
    formikProps.setFieldValue("longitude", lng);
    formikProps.setFieldValue("address", results[0].formatted_address);
    funcSplitAddress(
      results[0].address_components,
      results[0].formatted_address,
      formikProps.setFieldValue
    );
  };

  const handleOnBlurAutoSelectFirstOption = () => {
    formikProps.setFieldTouched("address", true, true);
    let objValue = formikProps.values;
    if (
      ["country", "postalCode", "city", "state", "latitude", "longitude"].some(
        (field) => objValue[field] === ""
      )
    ) {
      if (data.length > 0) {
        handleSelect(data[0].description, formikProps.setFieldValue);
      }
    }
    return;
  };

  const getErrorTranslation = (error) => {
    switch (error) {
      case "Store address is required.":
        return translate("store-information-page.store-address-required");

      default:
        return error;
    }
  };

  const getLocationPostcode = async (description) => {
    const param = {
      address: description,
    };

    const results = await getGeocode(param);
    const postcode = getZipCode(results[0], false);
    if (postcode) {
      return true;
    } else {
      return false;
    }
  };

  const getPostcodeStatus = async () => {
    try {
      const responses = await Promise.all(
        data.map((e) => getLocationPostcode(e.description))
      );

      setFilteredData(data.filter((_, i) => responses[i]));
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getPostcodeStatus();
  }, [debounceFilterData]);

  return (
    <div
      className={style["input-map-wrapper"]}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      <h1 className="orderhome-orderinfo-small">{inputLabel}</h1>
      <TextField
        onBlur={handleOnBlurAutoSelectFirstOption}
        type="text"
        value={value}
        variant="outlined"
        fullWidth
        disabled={disabled}
        error={
          true && formikProps.errors.address && formikProps.touched.address
        }
        helperText={
          formikProps.errors.address && formikProps.touched.address
            ? getErrorTranslation(formikProps.errors.address)
            : null
        }
        className="input-box"
        onChange={(e) => {
          if (!isFetching) {
            setIsFetching(true);
          }
          setValue(e.target.value);
          formikProps.resetForm({
            values: {
              ...formikProps.values,
              address: "",
              longitude: "",
              latitude: "",
              country: "",
              postalCode: "",
              state: "",
              city: "",
            },
          });
          formikProps.handleChange(e);
        }}
        placeholder={translate("settings-home-page.type-to-search")}
        name="address"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {value && (
                <IconButton
                  aria-label="clear"
                  disabled={disabled}
                  onClick={() => {
                    setValue("");
                    formikProps.resetForm({
                      values: {
                        ...formikProps.values,
                        address: "",
                        longitude: "",
                        latitude: "",
                        country: "",
                        postalCode: "",
                        state: "",
                        city: "",
                      },
                    });
                  }}
                  edge="end"
                  size="small"
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      {isFetching ? (
        <div
          className="skeleton-wrapper"
          style={{
            padding: "16px",
            borderRadius: "4px",
            backgroundColor: "#FFF",
            marginTop: "2px",
          }}
        >
          <Skeleton
            variant="rectangular"
            width={"30%"}
            height={18}
            sx={{ borderRadius: "4px" }}
          />
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={60}
            sx={{ marginTop: "6px", borderRadius: "4px" }}
          />
        </div>
      ) : (
        <div className={style["dropdown-selection-wrapper"]}>
          {status === "OK" &&
            focus &&
            filteredData.map(
              ({ place_id, description, structured_formatting }) => (
                <div
                  className={style["selection-list"]}
                  key={place_id}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    handleSelect(description, formikProps.setFieldValue);
                  }}
                  onFocus={() => setFocus(true)}
                >
                  <div className={style["selection-label"]}>
                    {structured_formatting.main_text}
                  </div>
                  <div>{description}</div>
                </div>
              )
            )}
        </div>
      )}
    </div>
  );
};

export default PlacesAutocompleteInput;
