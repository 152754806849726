import { Button, CircularProgress, IconButton } from "@mui/material";
import {
  CheckBox as IconCheckbox,
  CloudDownload as ExportIcon,
  CloudUpload as ImportIcon,
  AddBox,
} from "@mui/icons-material";
import SVGFrameAdd from "../../images/frame-add.svg";
import "./Buttons.scss";
import { LoadingButton } from "@mui/lab";

export const ImportExportIconButton = ({
  handleAction,
  title,
  type,
  disabled = false,
  isLoading = false,
}) => {
  return (
    <div className="button-wrapper">
      <Button
        className="import-export-icon-btn"
        variant="outlined"
        onClick={handleAction}
        disabled={disabled || isLoading}
        startIcon={
          isLoading ? (
            <CircularProgress size={27} />
          ) : type === "import" ? (
            <ImportIcon />
          ) : (
            <ExportIcon />
          )
        }
      >
        {title}
      </Button>
    </div>
  );
};

export const AddIconButton = ({ handleAction, title, disabled = false, isLoading = false }) => {
  return (
    <div className="button-wrapper">
      <Button
        className="add-icon-btn"
        variant="contained"
        onClick={handleAction}
        disabled={disabled || isLoading}
        startIcon={<img src={SVGFrameAdd} alt="add-icon" />}
      >
        {title}
      </Button>
    </div>
  );
};

export const ContainedButton = ({
  handleAction,
  title,
  disabled = false,
  isLoading = false,
  startIcon = "default",
  className,
}) => {
  return (
    <div className="button-wrapper">
      <Button
        className={["contained-btn", className].join(" ")}
        variant="contained"
        disabled={disabled || isLoading}
        onClick={handleAction}
        startIcon={
          startIcon === "default" ? (
            <IconCheckbox className="save-filter-icon" />
          ) : startIcon === "plus" ? (
            <AddBox />
          ) : (
            startIcon
          )
        }
      >
        {title}
      </Button>
      {isLoading && <CircularProgress size={24} className="buttonProgress contained" />}
    </div>
  );
};

export const OutlinedButton = ({
  handleAction,
  title,
  disabled = false,
  isLoading = false,
  className,
  noBorder = false,
  style,
  disableRipple = false,
  startIcon = null,
}) => {
  return (
    <div className="button-wrapper">
      <Button
        className={[noBorder ? "outlined-btn-without-border" : "outlined-btn", className].join(" ")}
        variant="outlined"
        disabled={disabled || isLoading}
        onClick={handleAction}
        style={style}
        disableRipple={disableRipple}
        startIcon={startIcon}
      >
        {title}
      </Button>
      {isLoading && <CircularProgress size={24} className="buttonProgress outlined" />}
    </div>
  );
};

export const CustomIconButton = ({ className, ...props }) => {
  return (
    <IconButton
      className={["custom-icon-button", className].join(" ")}
      {...props}
    />
  );
};

export const CustomButton = ({ children, className, ...props }) => {
  return (
    <Button
      className={["custom-button", className].join(" ")}
      {...props}
    >
      {children}
    </Button>
  );
};

export const CustomLoadingButton = ({ children, className, ...props }) => {
  return (
    <LoadingButton
      className={["custom-loading-button", className].join(" ")}
      variant={props.isLoading ? "outlined" : "contained"}
      disableRipple
      disableElevation
      loadingPosition="start"
      {...props}
    >
      {children}
    </LoadingButton>
  );
};
