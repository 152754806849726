import { useState, useEffect, useContext } from 'react';
import { CircularProgress } from "@mui/material";
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import { MerchantContext } from "../../context/MerchantContext";
import { useTranslate } from "react-polyglot";
import {
  CustomizedSnackbars
} from "../../components/shareable/Shareable";
import "./OrderHistoryPage.scss";
import { SHARED_ERROR_MESSAGE } from "../../constants";

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import {
    adminGetDownloadJobStatus,
    kdsOrderHistortList,
} from "../../graphql/queries";
import {
    adminTriggerExportCSV
} from "../../graphql/mutations";
import { CustomButton } from '../../components/buttons/Buttons';
import { IoCloudDownload } from 'react-icons/io5'
import { validateExpiryDate } from "../../validation/validateExpiryDate";

function OrderHistoryPage(props) {
    const history = useHistory();
    
    const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });
    const merchantInfoContext = useContext(MerchantContext);
    const translate = useTranslate();

    // dashboard usage state
    const [dateRangeList, setDateRangeList] = useState([
        "1", "7", "30", "90", "180"
    ]);
    const [orderTypeList, setOrderTypeList] = useState([
        {type:"revenue", value: 0},
        {type:"pendingRefund", value: 0},
        {type:"refundAmount", value: 0}
    ]);
    const [orderTotalList, setOrderTotalList] = useState([
        {type:"completed", value: 0},
        {type:"cancelled", value: 0},
        {type:"refunded", value: 0}
    ]);
    
     // listing table usage state
    const [orderHistoryList, setOrderHistoryList] = useState([]);
    const [orderHistoryListingIsLoading, setOrderHistoryListingIsLoading] = useState(false);
    // orderType parameter passed into API to get order with different status e.g. order completed, refunded, waiting refund
    const [orderType, setOrderType] = useState("revenue");
    const [secondaryOrderType, setSecondaryOrderType] = useState("completed");
    // totalDayOfRecord parameter passed into API to get orders from different date range
    const [totalDayOfRecord, setTotalDayOfRecord] = useState("1");
    const [isDownloadingOrders, setDownloadingOrders] = useState(false);
    const [downloadId, setDownloadId] = useState("");
    const [downloadLink, setDownloadLink] = useState("");
    
    const changeOrderType = (selectedOption) => {
        if(selectedOption==="pendingRefund") {
            setOrderType(selectedOption)
            setSecondaryOrderType("cancelled")
        } else if(selectedOption==="refundAmount") {
            setOrderType(selectedOption)
            setSecondaryOrderType("refunded")
        } else if(selectedOption==="completed") {
            setOrderType("revenue")
            setSecondaryOrderType(selectedOption)
        } else if(selectedOption==="refunded") {
            setOrderType("refundAmount")
            setSecondaryOrderType(selectedOption)
        } else if(selectedOption==="cancelled") {
            setOrderType("pendingRefund")
            setSecondaryOrderType(selectedOption)
        } else {
            setOrderType(selectedOption)
            setSecondaryOrderType("completed")
        }
    }
    
    // fetching orderhistorylisting from elasticsearch
    const fetchOrderHistoryListingData = async () => {
        setOrderHistoryListingIsLoading(true);
        try {
            let params = {
                orderType: !!orderType ? orderType : "revenue",
                totalDayOfRecord: totalDayOfRecord,
            };

            let res = await API.graphql(graphqlOperation(kdsOrderHistortList, params));
            if(res.data.kdsOrderHistortList.status) {
                setOrderTotalList([
                    {type: "completed", value: res.data.kdsOrderHistortList.noOfOrderCompleted},
                    {type: "cancelled", value: res.data.kdsOrderHistortList.noOfCancelledOrder},
                    {type: "refunded", value: res.data.kdsOrderHistortList.noOfRefundedOrder},
                ]);
                setOrderTypeList([
                    {type: "revenue", value: res.data.kdsOrderHistortList.totalAmountOfRevenue},
                    {type: "pendingRefund", value: res.data.kdsOrderHistortList.totalAmountOfPendingRefundAmount},
                    {type: "refundAmount", value: res.data.kdsOrderHistortList.totalAmountOfRefundedAmount},
                ]);
                setOrderHistoryList(res.data.kdsOrderHistortList.orderList);
            }

            setOrderHistoryListingIsLoading(false);
        } catch (error) {
            setSnackbar({
              snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
              snackbarOpen: true,
              snackbarSeverity: "error",
            });
            setOrderHistoryListingIsLoading(false);
        }
    };
    
    const renderDate = (date) => {
        if(localStorage.getItem("locale")==="zh") {
            moment.updateLocale(localStorage.getItem("locale"), {
                calendar : {
                    lastDay : '[昨天]',
                    sameDay : 'LT',
                    nextDay : '[明天 at] LT',
                    lastWeek: 'DD/MM/YYYY',
                    nextWeek : 'dddd [at] LT',
                    sameElse : 'DD/MM/YYYY'
                }
            });
        } else if (localStorage.getItem("locale")==="bm") {
            moment.updateLocale(localStorage.getItem("locale"), {
                calendar : {
                    lastDay : '[Semalam]',
                    sameDay : 'LT',
                    nextDay : '[Esok at] LT',
                    lastWeek: 'DD/MM/YYYY',
                    nextWeek : 'dddd [at] LT',
                    sameElse : 'DD/MM/YYYY'
                }
            });
        } else  {
            moment.updateLocale("en", {
                calendar : {
                    lastDay : '[Yesterday]',
                    sameDay : 'LT',
                    nextDay : '[Tomorrow at] LT',
                    lastWeek: 'DD/MM/YYYY',
                    nextWeek : 'dddd [at] LT',
                    sameElse : 'DD/MM/YYYY'
                }
            });
        }
        return moment(date).calendar();
    }
  
     useEffect(() =>
        validateExpiryDate(merchantInfoContext.subscriptionExpiryDate)
    )


    const OrderHistoryRow = (props) => {
        const {row} = props;

        return (
            <div className='order-history-listing-box' onClick={() => history.push(`/kds/vieworder?orderID=${row.orderId}&from=history`)}>
                <div className="order-history-entry-left-section">
                    <h1 className='order-history-entry-large'>{row.orderNumber}</h1>
                </div>
                <div className="order-history-entry-right-section">
                    <h1 className={`order-history-entry-large ${!orderType || orderType === "revenue" ? "green" : "red"}`}>{`${row.amount < 0 ? "-" : ""}${merchantInfoContext.currency} ${(row.amount < 0 ? row.amount * -1 : row.amount).toFixed(2)}`}</h1>
                    <h1 className='order-history-entry-small'>{renderDate(row.orderDateTime)}</h1>
                </div>
            </div>
        );
    };
    
    const getDateRangeOption = (value) => {
        switch(value) {
            case "1":
                return translate("order-history-page.1-days");
            case "7":
                return translate("order-history-page.7-days");
            case "30":
                return translate("order-history-page.30-days");
            case "90":
                return translate("order-history-page.90-days");
            case "180":
                return translate("order-history-page.180-days");
        }
    }

    const downloadOrders = async () => {

        setDownloadingOrders(true);
        setSnackbar({
            snackbarMessage: "Downloading report",
            snackbarOpen: true,
            snackbarSeverity: "info"
        });

        try {
            const params = {
                module: "WarungOrder",
                sort: {
                    field: "updatedAt",
                    direction: "desc"
                }
            }
    
            let res = await API.graphql(graphqlOperation(adminTriggerExportCSV, params));

            if (res.data.adminTriggerExportCSV.status) {
                setDownloadId(res.data.adminTriggerExportCSV.downloadJobId);
            } else {
                setDownloadingOrders(false);
                setSnackbar({
                    snackbarMessage: "Download failed! Please try again",
                    snackbarOpen: true,
                    snackbarSeverity: "error"
                });
            }
        } catch (err) {}
    }

    useEffect(() => {
        fetchOrderHistoryListingData();
    }, [orderType, totalDayOfRecord]);

    useEffect(() => {

        if (downloadId !== "") {
            const params = {
                downloadJobId: downloadId,
            }

            const interval = setInterval(async () => {
    
                let res = await API.graphql(graphqlOperation(adminGetDownloadJobStatus, params));

                if (res.data.adminGetDownloadJobStatus.status) {
                    if (res.data.adminGetDownloadJobStatus.message === "Success") {
                        setDownloadingOrders(false);
                        setSnackbar({
                            snackbarMessage: "Download successful!",
                            snackbarOpen: true,
                            snackbarSeverity: "success"
                        });
                        setDownloadLink(res.data.adminGetDownloadJobStatus.url)
                        clearInterval(interval)
                    }
                } else {
                    setSnackbar({
                        snackbarMessage: "Download failed! Please try again",
                        snackbarOpen: true,
                        snackbarSeverity: "error"
                    });
                }
            }, 2000)
        }

    }, [downloadId])

    useEffect(() => {
        if (downloadLink !== "") {
            window.open(downloadLink, "_self")
        }
    }, [downloadLink])
    
    return (
        <div className="order-history-page-wrapper">
            <CustomizedSnackbars
              message={snackbar.snackbarMessage}
              snackbarOpen={snackbar.snackbarOpen}
              snackbarClose={() =>
                setSnackbar({
                  ...snackbar,
                  snackbarOpen: false,
                  snackbarSeverity: snackbar.snackbarSeverity,
                })
              }
              severity={snackbar.snackbarSeverity}
              isDownload={isDownloadingOrders}
            />
            <Header />
            <div className='body body-bg new-body-padding-with-footer'>
                
                <div className='main-title-row'>
                    <h1 className='main-title'>{translate("order-history-page.title")}</h1>
                    <CustomButton 
                          variant="contained"
                          disableRipple
                          disableElevation
                          startIcon={<IoCloudDownload />}
                          onClick={downloadOrders}
                          className="header-button"
                          disabled={isDownloadingOrders}
                          style={{
                            lineHeight: 1.2,
                            paddingTop: "8px",
                            paddingBottom: "8px",
                          }}
                        >
                            {translate("order-history-page.download-orders")}
                        </CustomButton>
                </div>

                <div className="date-range-section">
                    {
                        dateRangeList.map((o) => (
                            <div className={`each-date-range${o === totalDayOfRecord ? " selected" : ""}`} onClick={() => setTotalDayOfRecord(o)}>
                                {
                                    getDateRangeOption(o)
                                }
                            </div>
                        ))
                    }
                </div>
                    <div className="dashboard-section">
                        <div className="dashboard-row">
                            {orderTypeList.map((o) => (
                                <div className={`dashboard-box${o.type === orderType ? " selected" : ""}`} onClick={() => changeOrderType(o.type)}>
                                    <div className='dashboard-flex-box'>
                                        <h1 className='dashboard-text-small'>
                                            {o.type === "revenue" ? 
                                            translate("order-history-page.revenue")
                                            : o.type === "refundAmount" ? 
                                            translate("order-history-page.refund-amount")
                                            : o.type ==="pendingRefund" ?
                                            translate("order-history-page.pending-refund")
                                            : ""
                                            }
                                        </h1>
                                        <h1 className='dashboard-text-large'>{merchantInfoContext.currency} {(!!o.value ? o.value : 0).toFixed(2)}</h1>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="dashboard-row">
                            {orderTotalList.map((o) => (
                                <div className={`dashboard-box${o.type === secondaryOrderType ? " selected" : ""}`}  onClick={() => changeOrderType(o.type)}>
                                    <div className='dashboard-flex-box'>
                                        <h1 className='dashboard-text-small'>
                                            {o.type === "completed" ? 
                                            translate("order-history-page.order-completed")
                                            : o.type === "cancelled" ? 
                                            translate("order-history-page.order-cancelled")
                                            : o.type ==="refunded" ?
                                            translate("order-history-page.order-refunded")
                                            : ""
                                            }
                                        </h1>
                                        <h1 className='dashboard-text-large'>{!!o.value ? o.value : 0}</h1>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="body-section">
                        {
                            orderHistoryListingIsLoading ? (
                                <div className="loading-symbol-wrapper">
                                  <CircularProgress />
                                </div>
                            )
                            : orderHistoryList.length > 0 ?
                            orderHistoryList.map((row) => (
                                <OrderHistoryRow
                                    row={row}
                                />
                            ))
                            : 
                            <label>{translate("order-history-page.empty-list")}</label>
                        }
                    </div>
            </div>
            <Footer page={"history"} />
        </div>
    );
}

export default OrderHistoryPage;