import { useState, useContext } from 'react';
import {useHistory,Link} from 'react-router-dom';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import { MerchantContext } from "../../../context/MerchantContext";
import { useTranslate } from "react-polyglot";
import { OutlinedButton } from "../../../components/buttons/Buttons";
import {
  CustomizedSnackbars
} from "../../../components/shareable/Shareable";
import "./TopUpPage.scss";
import { IconButton, InputAdornment } from "@mui/material";
import {IoArrowBackSharp,IoClose} from 'react-icons/io5';
import { LoadingButton } from "@mui/lab";
import {
  ArrowBack
} from "@mui/icons-material";
import { CustomInputBase } from "../../../components/custom-input-base/CustomInputBase";

// AWS apis
import { API, graphqlOperation } from "aws-amplify";
//import {} from "../../../graphql/queries";
import { adminTopUpShippingCredits } from "../../../graphql/mutations";
  

function TopUpPage(props) {
    const history = useHistory();
    
    const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });
    const merchantInfoContext = useContext(MerchantContext);
    const translate = useTranslate();
    const [isApiProcessing, setIsApiProcessing] = useState(false);
    const [topUpValue, setTopUpValue] = useState();
    const [status,setStatus] = useState("Topup Completed");

    const handleTopUpValueChange = (e) => {
        if(e.target.value === " ") {
            setTopUpValue();
        } else {
            let value = e.target.value;
            let processedValue = value.indexOf(".") >= 0 ? value.substr(0, value.indexOf(".")) + value.substr(value.indexOf("."), 3) : value;
            setTopUpValue(parseFloat(processedValue));
        }
    };
    
    const handleTopUpCredit = async () => {
    try {
      setIsApiProcessing(true);
      if (!topUpValue) {
        setSnackbar({
          snackbarMessage: "Topup value cannot be 0 or empty",
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
        setIsApiProcessing(false);
        return false;
      } else {
        let params = {
          topUpAmount: parseFloat(topUpValue),
        };
        const res = await API.graphql(graphqlOperation(adminTopUpShippingCredits, params));
        if (res.data.adminTopUpShippingCredits.status === "true") {
          let paymentUrl = ""
          if (merchantInfoContext.currency !== "MYR"){
            paymentUrl = res.data.adminTopUpShippingCredits.gatewayPaymentUrl
          } else {
            paymentUrl = res.data.adminTopUpShippingCredits.gatewayPaymentUrl +
              "?" +
                res.data.adminTopUpShippingCredits.gatewayPaymentParams;
          }
          setSnackbar({
            snackbarMessage: "Redirecting to payment gateway...",
            snackbarOpen: true,
            snackbarSeverity: "success",
          });
          window.location.href = paymentUrl
          setIsApiProcessing(false);
          return true;
        } else {
          setIsApiProcessing(false);
          return false;
        }
      }
    } catch (error) {
      setIsApiProcessing(false);
      setSnackbar({
        snackbarMessage: "Something went wrong. Please try again later.",
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };
  
  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

    return (
        <div className="top-up-page-wrapper">
            <CustomizedSnackbars
              message={snackbar.snackbarMessage}
              snackbarOpen={snackbar.snackbarOpen}
              snackbarClose={() =>
                setSnackbar({
                  ...snackbar,
                  snackbarOpen: false,
                  snackbarSeverity: snackbar.snackbarSeverity,
                })
              }
              severity={snackbar.snackbarSeverity}
            />
            <Header />
            <div className='body body-bg body-padding'>
                <div>
                    <div className="header-section">
                        <IoArrowBackSharp className='backbutton-styling' onClick={()=>history.push("/kds/settings/credit-history")}></IoArrowBackSharp>
                        <label>{translate("top-up-page.top-up-del-cred")}</label>
                    </div>
                    <div className="body-section">
                        <label className="top-up-input-label">{translate("top-up-page.top-up-input-label")}</label>
                        <CustomInputBase
                            borderAttached={true}
                            startAdornment={<InputAdornment position="start"><label>{merchantInfoContext.currency}</label></InputAdornment>}
                            className="top-up-input"
                            type="number"
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                            }
                            value={topUpValue}
                            onChange={handleTopUpValueChange}
                        />
                        <div className="predefined-input-button-section">
                          {
                            [50, 100, 200, 500].map((o) => (
                              <OutlinedButton
                                title={`${merchantInfoContext.currency} ${o}`}
                                handleAction={() => setTopUpValue(parseFloat(o))}
                                className="predefined-input-button"
                                disableRipple={true}
                              />
                            ))
                          }
                        </div>
                        <LoadingButton
                          className="pay-button"
                          onClick={handleTopUpCredit}
                          loading={isApiProcessing}
                          loadingPosition="start"
                          variant={isApiProcessing ? "outlined" : "contained"}
                        >
                          {translate("top-up-page.pay")}
                        </LoadingButton>
                    </div>
                </div>
            </div>
            <Footer page={"setting"} />
        </div>
    );
}

export default TopUpPage;