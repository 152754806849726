import { useState,useContext } from "react";
import {Link, useHistory} from 'react-router-dom';
import styles from "./PackageOffer.module.scss";
import Header from '../../components/header/Header';
import { IoArrowBackSharp } from "react-icons/io5";
import { MerchantContext } from "../../context/MerchantContext";
import { useTranslate } from "react-polyglot";
import {
  Typography,
  Button,
  CircularProgress
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import { 
  buyMerchantAddon
} from '../../graphql/mutations';
import { CustomizedSnackbars } from '../../components/shareable/Shareable';
import { useQuery } from "../../hooks";

function PackageOffer(props) {
    const translate = useTranslate();
    let history = useHistory();
    let query = useQuery();
    const merchantInfoContext = useContext(MerchantContext);
    const [fulfillmentIsLoading, setFulfillmentIsLoading] = useState(false);
    
    const [snackbar, setSnackbar] = useState({
      snackbarMessage: "",
      snackbarOpen: false,
      snackbarSeverity: "info",
    });
    
    const purchaseAddOn = async () => {
      if(merchantInfoContext.packageAddon) {
          setFulfillmentIsLoading(true);
            let params = {
              addOnPackage: [merchantInfoContext.packageAddon[0].packageId]
            };

          try {
            const respOrderFulfillment = await API.graphql(
              graphqlOperation(buyMerchantAddon,params)
            );
            if (respOrderFulfillment.data.buyMerchantAddon.status === "true") {
              let paymentUrl = ""
              if (merchantInfoContext.currency !== "MYR"){
                paymentUrl = respOrderFulfillment.data.buyMerchantAddon.paymentUrl
              } else {
                paymentUrl = respOrderFulfillment.data.buyMerchantAddon.paymentUrl +
                  "?" +
                  respOrderFulfillment.data.buyMerchantAddon.gatewayPaymentParams;
              }
              window.location.href=paymentUrl
            }
            setFulfillmentIsLoading(false);
          } catch (error) {
            console.log(error)
            setFulfillmentIsLoading(false);
          }
      } else {
          setSnackbar({
            snackbarMessage: translate("error-handler.server-error"),
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
      }
    }
    
    return (
        <div>
            <Header></Header>
            <CustomizedSnackbars
              message={snackbar.snackbarMessage}
              snackbarOpen={snackbar.snackbarOpen}
              snackbarClose={() =>
                  setSnackbar({
                  ...snackbar,
                  snackbarOpen: false,
                  snackbarSeverity: snackbar.snackbarSeverity,
                  })
              }
              severity={snackbar.snackbarSeverity}
          />
            <div className={styles.buttonrow}>
              <Link to={"/kds/settings/selectlanguage?merchantId=" + query.get("merchantId")}>
                <IoArrowBackSharp className="backbutton-styling"></IoArrowBackSharp>
              </Link>
            </div>
            <div className={styles.centeronboardscreen}>
                <div className={styles.containerwrapper}>
                    <h1 className={styles.packageoffertitle}>{translate("onboarding-screen.need-help")}</h1>
                    <Typography style={{textAlign:"center"}} color="textSecondary" gutterBottom>
                        <small className={styles.packageoffertext}>
                          {translate("onboarding-screen.be-hassle-free")} {merchantInfoContext.currency}300{translate("onboarding-screen.period")}
                        </small>
                    </Typography>
                    
                    <div className={styles.packageofferdetailbox}>
                        <h6 className={styles.packageofferdetailtitle}>{translate("onboarding-screen.package-include")}</h6>
                        <ul className={styles.packageofferdetaillistbox}>
                            <li className={styles.packageoffedetaillisttext}>{translate("onboarding-screen.menu-set-up")}</li>
                            <li className={styles.packageoffedetaillisttext}>{translate("onboarding-screen.online-store-set-up")}</li>
                            <li className={styles.packageoffedetaillisttext}>{translate("onboarding-screen.on-site-training")}</li>
                            <li className={styles.packageoffedetaillisttext}>{translate("onboarding-screen.qr-standee")}</li>
                        </ul>
                    </div>
                    
                    <div className={styles.packageoffersinglebuttonbox} style={{marginTop:"20px"}}>
                        <Button
                          variant="contained"
                          className="orderview-confirm-button"
                          color="primary"
                          onClick={()=>purchaseAddOn()}
                        >
                          {!fulfillmentIsLoading ? (
                            translate("login-handler.confirm")
                          ) : (
                            <CircularProgress size={20} color={"inherit"} />
                          )}
                        </Button>
                    </div>
                    <div className={styles.packageoffersinglebuttonbox} style={{marginTop:"0px"}}>
                        <button
                          className={styles.packageofferrejectbutton}
                          onClick={() => history.push(
                            "/kds/settings/update-business-detail"
                          )}
                        >
                        {translate("onboarding-screen.will-do-it-myself")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PackageOffer;
